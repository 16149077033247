:root {
  --flix-brand-primary-color: #97d700;
  --flix-ui-primary-color: #31a100;
  --flix-ui-primary-light-color: #5cc500;
  --flix-ui-primary-dark-color: #187d00;
  --flix-grayscale-0-color: #ffffff;
  --flix-grayscale-10-color: #f7f7f7;
  --flix-grayscale-30-color: #e1e1e1;
  --flix-grayscale-50-color: #c8c8c8;
  --flix-grayscale-70-color: #646464;
  --flix-grayscale-90-color: #444444;
  --flix-grayscale-100-color: #353535;
  --flix-neutral-color: #016ae7;
  --flix-success-color: #228f00;
  --flix-success-dark-color: #136b00;
  --flix-warning-color: #ff5704;
  --flix-warning-dark-color: #c42d00;
  --flix-danger-color: #dd2828;
  --flix-danger-dark-color: #b31414;
  --flix-bg-primary-color: #ffffff;
  --flix-bg-primary-transparent-color: rgba(255, 255, 255, 0.88);
  --flix-bg-secondary-color: #f7f7f7;
  --flix-box-bg-color: #ffffff;
  --flix-highlight-color: #e5f9c0;
  --flix-overlay-bg-color: rgba(0, 0, 0, 0.5);
  --flix-focus-outline-color: #016ae7;
  --flix-content-primary-color: #353535;
  --flix-content-secondary-color: #646464;
  --flix-heading-font-color: #353535;
  --flix-link-color: #0047de;
  --flix-line-primary-color: #c8c8c8;
  --flix-icon-primary-color: #8b8b8b;
  --flix-icon-secondary-color: #97d700;
  --flix-box-shadow-color: rgba(0, 0, 0, 0.18);
  --flix-box-shadow-subtle-color: rgba(0, 0, 0, 0.06);
  --flix-button-primary-color: #97d700;
  --flix-button-label-color: #353535;
  --flix-header-bg-color: #97d700;
  --flix-header-color: #353535;
  --flix-header-nav-bg-color-tablet: #5cc500;
  --flix-input-border-color: #8b8b8b;
  --flix-input-height-mobile: 44px;
  --flix-input-height-desktop: 36px;
  --flix-spacing-1: 6px;
  --flix-spacing-2: 12px;
  --flix-spacing-3: 18px;
  --flix-spacing-4: 24px;
  --flix-spacing-5: 30px;
  --flix-spacing-6: 36px;
  --flix-spacing-7: 42px;
  --flix-spacing-8: 48px;
  --flix-spacing-9: 54px;
  --flix-spacing-10: 60px;
  --flix-spacing-11: 66px;
  --flix-spacing-12: 72px;
  --flix-spacing-half: 3px;
  --flix-grid-gutter-2: 12px;
  --flix-grid-gutter-4: 24px;
  --flix-page-min-width: 320px;
  --flix-page-max-width: 1200px;
  --flix-font-size-base: 1rem;
  --flix-font-size-primary: 1rem;
  --flix-font-size-small: 0.875rem;
  --flix-font-size-fineprint: 0.75rem;
  --flix-font-size-h1: 1.7rem;
  --flix-font-size-h2: 1.3rem;
  --flix-font-size-h3: 1.1rem;
  --flix-font-size-h4: 1rem;
  --flix-line-height-primary: 1.5rem;
  --flix-line-height-small: 1.125rem;
  --flix-line-height-fineprint: 0.938rem;
  --flix-line-height-h1: 2rem;
  --flix-line-height-h2: 1.7rem;
  --flix-line-height-h3: 1.5rem;
  --flix-line-height-h4: 1.5rem;
  --flix-hover-layer-color: linear-gradient(rgba(0, 0, 0, 0.06), rgba(0, 0, 0, 0.06));
  --flix-pressed-layer-color: linear-gradient(rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.12));
  --flix-disabled-element-opacity: 0.5;
  --flix-primary-border-radius: 6px;
  --flix-primary-box-shadow: 0px 6px 12px rgba(0, 0, 0, .06), 0px 3px 18px rgba(0, 0, 0, .06), 0px 3px 6px rgba(0, 0, 0, .18);
  --flix-color-scheme: normal;
  --flix-font-family-primary: Roboto, Arial, sans-serif;
  --flix-font-weight-normal: 400;
  --flix-font-weight-semibold: 500;
  --flix-font-weight-bold: 700;
}

:root {
  color: var(--flix-content-primary-color);
  font-family: var(--flix-font-family-primary);
  font-size: var(--flix-font-size-base);
  font-weight: var(--flix-font-weight-normal);
  line-height: var(--flix-line-height-primary);
  color-scheme: var(--flix-color-scheme);
  /* stylelint-disable-next-line media-feature-name-no-vendor-prefix */
}
@media only screen and (-webkit-min-device-pixel-ratio: 2) and (pointer: coarse) {
  :root {
    /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
    font: -apple-system-body;
    font-family: var(--flix-font-family-primary);
  }
}

.flix-theme-flix-dark {
  --flix-brand-primary-color: #97d700;
  --flix-ui-primary-color: #97d700;
  --flix-ui-primary-light-color: #44b300;
  --flix-ui-primary-dark-color: #cef38d;
  --flix-grayscale-0-color: #151515;
  --flix-grayscale-10-color: #252525;
  --flix-grayscale-30-color: #444444;
  --flix-grayscale-50-color: #646464;
  --flix-grayscale-70-color: #8b8b8b;
  --flix-grayscale-90-color: #c8c8c8;
  --flix-grayscale-100-color: #ffffff;
  --flix-neutral-color: #039fd5;
  --flix-success-color: #44b300;
  --flix-success-dark-color: #cef38d;
  --flix-warning-color: #ff8206;
  --flix-warning-dark-color: #ffe393;
  --flix-danger-color: #fd5a5a;
  --flix-danger-dark-color: #ffc9ce;
  --flix-bg-primary-color: #151515;
  --flix-bg-primary-transparent-color: rgba(53, 53, 53, 0.88);
  --flix-bg-secondary-color: #121212;
  --flix-box-bg-color: #252525;
  --flix-highlight-color: #0a3c00;
  --flix-overlay-bg-color: rgba(0, 0, 0, 0.5);
  --flix-focus-outline-color: #016ae7;
  --flix-content-primary-color: #ffffff;
  --flix-content-secondary-color: #c8c8c8;
  --flix-heading-font-color: #ffffff;
  --flix-link-color: #3bd0de;
  --flix-line-primary-color: #646464;
  --flix-icon-primary-color: #ffffff;
  --flix-icon-secondary-color: #97d700;
  --flix-box-shadow-color: rgba(0, 0, 0, 0.18);
  --flix-box-shadow-subtle-color: rgba(0, 0, 0, 0.06);
  --flix-button-primary-color: #97d700;
  --flix-button-label-color: #252525;
  --flix-header-bg-color: #252525;
  --flix-header-color: #ffffff;
  --flix-header-nav-bg-color-tablet: #202020;
  --flix-input-border-color: #c8c8c8;
  --flix-input-height-mobile: 44px;
  --flix-input-height-desktop: 36px;
  --flix-spacing-1: 6px;
  --flix-spacing-2: 12px;
  --flix-spacing-3: 18px;
  --flix-spacing-4: 24px;
  --flix-spacing-5: 30px;
  --flix-spacing-6: 36px;
  --flix-spacing-7: 42px;
  --flix-spacing-8: 48px;
  --flix-spacing-9: 54px;
  --flix-spacing-10: 60px;
  --flix-spacing-11: 66px;
  --flix-spacing-12: 72px;
  --flix-spacing-half: 3px;
  --flix-grid-gutter-2: 12px;
  --flix-grid-gutter-4: 24px;
  --flix-page-min-width: 320px;
  --flix-page-max-width: 1200px;
  --flix-font-size-base: 1rem;
  --flix-font-size-primary: 1rem;
  --flix-font-size-small: 0.875rem;
  --flix-font-size-fineprint: 0.75rem;
  --flix-font-size-h1: 1.7rem;
  --flix-font-size-h2: 1.3rem;
  --flix-font-size-h3: 1.1rem;
  --flix-font-size-h4: 1rem;
  --flix-line-height-primary: 1.5rem;
  --flix-line-height-small: 1.125rem;
  --flix-line-height-fineprint: 0.938rem;
  --flix-line-height-h1: 2rem;
  --flix-line-height-h2: 1.7rem;
  --flix-line-height-h3: 1.5rem;
  --flix-line-height-h4: 1.5rem;
  --flix-hover-layer-color: linear-gradient(rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.12));
  --flix-pressed-layer-color: linear-gradient(rgba(0, 0, 0, 0.24), rgba(0, 0, 0, 0.24));
  --flix-disabled-element-opacity: 0.5;
  --flix-primary-border-radius: 6px;
  --flix-primary-box-shadow: 0px 6px 12px rgba(0, 0, 0, .06), 0px 3px 18px rgba(0, 0, 0, .06), 0px 3px 6px rgba(0, 0, 0, .18);
  --flix-color-scheme: dark;
  --flix-font-family-primary: Roboto, Arial, sans-serif;
  --flix-font-weight-normal: 400;
  --flix-font-weight-semibold: 500;
  --flix-font-weight-bold: 700;
}

.flix-theme-flix-dark {
  color: var(--flix-content-primary-color);
  font-family: var(--flix-font-family-primary);
  font-size: var(--flix-font-size-base);
  font-weight: var(--flix-font-weight-normal);
  line-height: var(--flix-line-height-primary);
  color-scheme: var(--flix-color-scheme);
  /* stylelint-disable-next-line media-feature-name-no-vendor-prefix */
}
@media only screen and (-webkit-min-device-pixel-ratio: 2) and (pointer: coarse) {
  .flix-theme-flix-dark {
    /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
    font: -apple-system-body;
    font-family: var(--flix-font-family-primary);
  }
}

.flix-theme-high-contrast {
  --flix-brand-primary-color: #ffff00;
  --flix-ui-primary-color: #ffffff;
  --flix-ui-primary-light-color: #ffffff;
  --flix-ui-primary-dark-color: #000000;
  --flix-grayscale-0-color: #000000;
  --flix-grayscale-10-color: #000000;
  --flix-grayscale-30-color: #000000;
  --flix-grayscale-50-color: #000000;
  --flix-grayscale-70-color: #ffffff;
  --flix-grayscale-90-color: #ffffff;
  --flix-grayscale-100-color: #ffffff;
  --flix-neutral-color: #ffff00;
  --flix-success-color: #ffff00;
  --flix-success-dark-color: #ffff00;
  --flix-warning-color: #ffff00;
  --flix-warning-dark-color: #ffff00;
  --flix-danger-color: #ffff00;
  --flix-danger-dark-color: #ffff00;
  --flix-bg-primary-color: #000000;
  --flix-bg-primary-transparent-color: rgba(0, 0, 0, 0.88);
  --flix-bg-secondary-color: #000000;
  --flix-box-bg-color: #000000;
  --flix-highlight-color: #000000;
  --flix-overlay-bg-color: rgba(0, 0, 0, 0.5);
  --flix-focus-outline-color: #ffff00;
  --flix-content-primary-color: #ffffff;
  --flix-content-secondary-color: #ffffff;
  --flix-heading-font-color: #ffffff;
  --flix-link-color: #ffff00;
  --flix-line-primary-color: #ffffff;
  --flix-icon-primary-color: #ffffff;
  --flix-icon-secondary-color: #ffffff;
  --flix-box-shadow-color: rgba(255, 255, 255, 0.18);
  --flix-box-shadow-subtle-color: rgba(255, 255, 255, 0.06);
  --flix-button-primary-color: #ffffff;
  --flix-button-label-color: #000000;
  --flix-header-bg-color: #000000;
  --flix-header-color: #ffffff;
  --flix-header-nav-bg-color-tablet: #000000;
  --flix-input-border-color: #ffffff;
  --flix-input-height-mobile: 44px;
  --flix-input-height-desktop: 36px;
  --flix-spacing-1: 6px;
  --flix-spacing-2: 12px;
  --flix-spacing-3: 18px;
  --flix-spacing-4: 24px;
  --flix-spacing-5: 30px;
  --flix-spacing-6: 36px;
  --flix-spacing-7: 42px;
  --flix-spacing-8: 48px;
  --flix-spacing-9: 54px;
  --flix-spacing-10: 60px;
  --flix-spacing-11: 66px;
  --flix-spacing-12: 72px;
  --flix-spacing-half: 3px;
  --flix-grid-gutter-2: 12px;
  --flix-grid-gutter-4: 24px;
  --flix-page-min-width: 320px;
  --flix-page-max-width: 1200px;
  --flix-font-size-base: 1rem;
  --flix-font-size-primary: 1rem;
  --flix-font-size-small: 0.875rem;
  --flix-font-size-fineprint: 0.75rem;
  --flix-font-size-h1: 1.7rem;
  --flix-font-size-h2: 1.3rem;
  --flix-font-size-h3: 1.1rem;
  --flix-font-size-h4: 1rem;
  --flix-line-height-primary: 1.5rem;
  --flix-line-height-small: 1.125rem;
  --flix-line-height-fineprint: 0.938rem;
  --flix-line-height-h1: 2rem;
  --flix-line-height-h2: 1.7rem;
  --flix-line-height-h3: 1.5rem;
  --flix-line-height-h4: 1.5rem;
  --flix-hover-layer-color: linear-gradient(rgba(0, 0, 0, 0.06), rgba(0, 0, 0, 0.06));
  --flix-pressed-layer-color: linear-gradient(rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.12));
  --flix-disabled-element-opacity: 0.5;
  --flix-primary-border-radius: 6px;
  --flix-primary-box-shadow: inset -2px -2px 0 #fff, inset 2px 2px 0 #fff;
  --flix-color-scheme: normal;
  --flix-font-family-primary: Roboto, Arial, sans-serif;
  --flix-font-weight-normal: 400;
  --flix-font-weight-semibold: 500;
  --flix-font-weight-bold: 700;
}

.flix-theme-high-contrast {
  color: var(--flix-content-primary-color);
  font-family: var(--flix-font-family-primary);
  font-size: var(--flix-font-size-base);
  font-weight: var(--flix-font-weight-normal);
  line-height: var(--flix-line-height-primary);
  color-scheme: var(--flix-color-scheme);
  /* stylelint-disable-next-line media-feature-name-no-vendor-prefix */
}
@media only screen and (-webkit-min-device-pixel-ratio: 2) and (pointer: coarse) {
  .flix-theme-high-contrast {
    /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
    font: -apple-system-body;
    font-family: var(--flix-font-family-primary);
  }
}

.flix-theme-kamil {
  --flix-brand-primary-color: #004b8d;
  --flix-ui-primary-color: #082967;
  --flix-ui-primary-light-color: #3eb5f1;
  --flix-ui-primary-dark-color: #00003c;
  --flix-grayscale-0-color: #ffffff;
  --flix-grayscale-10-color: #f7f7f7;
  --flix-grayscale-30-color: #e1e1e1;
  --flix-grayscale-50-color: #c8c8c8;
  --flix-grayscale-70-color: #646464;
  --flix-grayscale-90-color: #444444;
  --flix-grayscale-100-color: #353535;
  --flix-neutral-color: #016ae7;
  --flix-success-color: #228f00;
  --flix-success-dark-color: #136b00;
  --flix-warning-color: #ff5704;
  --flix-warning-dark-color: #c42d00;
  --flix-danger-color: #dd2828;
  --flix-danger-dark-color: #b31414;
  --flix-bg-primary-color: #ffffff;
  --flix-bg-primary-transparent-color: rgba(255, 255, 255, 0.88);
  --flix-bg-secondary-color: #f7f7f7;
  --flix-box-bg-color: #ffffff;
  --flix-highlight-color: #e3ebfa;
  --flix-overlay-bg-color: rgba(0, 0, 0, 0.5);
  --flix-focus-outline-color: #016ae7;
  --flix-content-primary-color: #353535;
  --flix-content-secondary-color: #646464;
  --flix-heading-font-color: #353535;
  --flix-link-color: #0047de;
  --flix-line-primary-color: #c8c8c8;
  --flix-icon-primary-color: #8b8b8b;
  --flix-icon-secondary-color: #082967;
  --flix-box-shadow-color: rgba(0, 0, 0, 0.18);
  --flix-box-shadow-subtle-color: rgba(0, 0, 0, 0.06);
  --flix-button-primary-color: #082967;
  --flix-button-label-color: #ffffff;
  --flix-header-bg-color: #082967;
  --flix-header-color: #ffffff;
  --flix-header-nav-bg-color-tablet: #00003c;
  --flix-input-border-color: #8b8b8b;
  --flix-input-height-mobile: 44px;
  --flix-input-height-desktop: 36px;
  --flix-spacing-1: 6px;
  --flix-spacing-2: 12px;
  --flix-spacing-3: 18px;
  --flix-spacing-4: 24px;
  --flix-spacing-5: 30px;
  --flix-spacing-6: 36px;
  --flix-spacing-7: 42px;
  --flix-spacing-8: 48px;
  --flix-spacing-9: 54px;
  --flix-spacing-10: 60px;
  --flix-spacing-11: 66px;
  --flix-spacing-12: 72px;
  --flix-spacing-half: 3px;
  --flix-grid-gutter-2: 12px;
  --flix-grid-gutter-4: 24px;
  --flix-page-min-width: 320px;
  --flix-page-max-width: 1200px;
  --flix-font-size-base: 1rem;
  --flix-font-size-primary: 1rem;
  --flix-font-size-small: 0.875rem;
  --flix-font-size-fineprint: 0.75rem;
  --flix-font-size-h1: 1.7rem;
  --flix-font-size-h2: 1.3rem;
  --flix-font-size-h3: 1.1rem;
  --flix-font-size-h4: 1rem;
  --flix-line-height-primary: 1.5rem;
  --flix-line-height-small: 1.125rem;
  --flix-line-height-fineprint: 0.938rem;
  --flix-line-height-h1: 2rem;
  --flix-line-height-h2: 1.7rem;
  --flix-line-height-h3: 1.5rem;
  --flix-line-height-h4: 1.5rem;
  --flix-hover-layer-color: linear-gradient(rgba(0, 0, 0, 0.06), rgba(0, 0, 0, 0.06));
  --flix-pressed-layer-color: linear-gradient(rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.12));
  --flix-disabled-element-opacity: 0.5;
  --flix-primary-border-radius: 6px;
  --flix-primary-box-shadow: 0px 6px 12px rgba(0, 0, 0, .06), 0px 3px 18px rgba(0, 0, 0, .06), 0px 3px 6px rgba(0, 0, 0, .18);
  --flix-color-scheme: normal;
  --flix-font-family-primary: Roboto, Arial, sans-serif;
  --flix-font-weight-normal: 400;
  --flix-font-weight-semibold: 500;
  --flix-font-weight-bold: 700;
}

.flix-theme-kamil {
  color: var(--flix-content-primary-color);
  font-family: var(--flix-font-family-primary);
  font-size: var(--flix-font-size-base);
  font-weight: var(--flix-font-weight-normal);
  line-height: var(--flix-line-height-primary);
  color-scheme: var(--flix-color-scheme);
  /* stylelint-disable-next-line media-feature-name-no-vendor-prefix */
}
@media only screen and (-webkit-min-device-pixel-ratio: 2) and (pointer: coarse) {
  .flix-theme-kamil {
    /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
    font: -apple-system-body;
    font-family: var(--flix-font-family-primary);
  }
}

.flix-theme-kamil-dark {
  --flix-brand-primary-color: #004b8d;
  --flix-ui-primary-color: #3eb5f1;
  --flix-ui-primary-light-color: #0597dd;
  --flix-ui-primary-dark-color: #95d8f8;
  --flix-grayscale-0-color: #151515;
  --flix-grayscale-10-color: #252525;
  --flix-grayscale-30-color: #444444;
  --flix-grayscale-50-color: #646464;
  --flix-grayscale-70-color: #8b8b8b;
  --flix-grayscale-90-color: #c8c8c8;
  --flix-grayscale-100-color: #ffffff;
  --flix-neutral-color: #039fd5;
  --flix-success-color: #44b300;
  --flix-success-dark-color: #cef38d;
  --flix-warning-color: #ff8206;
  --flix-warning-dark-color: #ffe393;
  --flix-danger-color: #fd5a5a;
  --flix-danger-dark-color: #ffc9ce;
  --flix-bg-primary-color: #151515;
  --flix-bg-primary-transparent-color: rgba(53, 53, 53, 0.88);
  --flix-bg-secondary-color: #121212;
  --flix-box-bg-color: #252525;
  --flix-highlight-color: #00003c;
  --flix-overlay-bg-color: rgba(0, 0, 0, 0.5);
  --flix-focus-outline-color: #016ae7;
  --flix-content-primary-color: #ffffff;
  --flix-content-secondary-color: #c8c8c8;
  --flix-heading-font-color: #ffffff;
  --flix-link-color: #3bd0de;
  --flix-line-primary-color: #646464;
  --flix-icon-primary-color: #ffffff;
  --flix-icon-secondary-color: #0597dd;
  --flix-box-shadow-color: rgba(0, 0, 0, 0.18);
  --flix-box-shadow-subtle-color: rgba(0, 0, 0, 0.06);
  --flix-button-primary-color: #3eb5f1;
  --flix-button-label-color: #252525;
  --flix-header-bg-color: #252525;
  --flix-header-color: #ffffff;
  --flix-header-nav-bg-color-tablet: #202020;
  --flix-input-border-color: #c8c8c8;
  --flix-input-height-mobile: 44px;
  --flix-input-height-desktop: 36px;
  --flix-spacing-1: 6px;
  --flix-spacing-2: 12px;
  --flix-spacing-3: 18px;
  --flix-spacing-4: 24px;
  --flix-spacing-5: 30px;
  --flix-spacing-6: 36px;
  --flix-spacing-7: 42px;
  --flix-spacing-8: 48px;
  --flix-spacing-9: 54px;
  --flix-spacing-10: 60px;
  --flix-spacing-11: 66px;
  --flix-spacing-12: 72px;
  --flix-spacing-half: 3px;
  --flix-grid-gutter-2: 12px;
  --flix-grid-gutter-4: 24px;
  --flix-page-min-width: 320px;
  --flix-page-max-width: 1200px;
  --flix-font-size-base: 1rem;
  --flix-font-size-primary: 1rem;
  --flix-font-size-small: 0.875rem;
  --flix-font-size-fineprint: 0.75rem;
  --flix-font-size-h1: 1.7rem;
  --flix-font-size-h2: 1.3rem;
  --flix-font-size-h3: 1.1rem;
  --flix-font-size-h4: 1rem;
  --flix-line-height-primary: 1.5rem;
  --flix-line-height-small: 1.125rem;
  --flix-line-height-fineprint: 0.938rem;
  --flix-line-height-h1: 2rem;
  --flix-line-height-h2: 1.7rem;
  --flix-line-height-h3: 1.5rem;
  --flix-line-height-h4: 1.5rem;
  --flix-hover-layer-color: linear-gradient(rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.12));
  --flix-pressed-layer-color: linear-gradient(rgba(0, 0, 0, 0.24), rgba(0, 0, 0, 0.24));
  --flix-disabled-element-opacity: 0.5;
  --flix-primary-border-radius: 6px;
  --flix-primary-box-shadow: 0px 6px 12px rgba(0, 0, 0, .06), 0px 3px 18px rgba(0, 0, 0, .06), 0px 3px 6px rgba(0, 0, 0, .18);
  --flix-color-scheme: dark;
  --flix-font-family-primary: Roboto, Arial, sans-serif;
  --flix-font-weight-normal: 400;
  --flix-font-weight-semibold: 500;
  --flix-font-weight-bold: 700;
}

.flix-theme-kamil-dark {
  color: var(--flix-content-primary-color);
  font-family: var(--flix-font-family-primary);
  font-size: var(--flix-font-size-base);
  font-weight: var(--flix-font-weight-normal);
  line-height: var(--flix-line-height-primary);
  color-scheme: var(--flix-color-scheme);
  /* stylelint-disable-next-line media-feature-name-no-vendor-prefix */
}
@media only screen and (-webkit-min-device-pixel-ratio: 2) and (pointer: coarse) {
  .flix-theme-kamil-dark {
    /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
    font: -apple-system-body;
    font-family: var(--flix-font-family-primary);
  }
}

.flix-theme-neptune {
  --flix-brand-primary-color: #004963;
  --flix-ui-primary-color: #004963;
  --flix-ui-primary-light-color: #00759c;
  --flix-ui-primary-dark-color: #003547;
  --flix-grayscale-0-color: #ffffff;
  --flix-grayscale-10-color: #f7f7f7;
  --flix-grayscale-30-color: #e1e1e1;
  --flix-grayscale-50-color: #c8c8c8;
  --flix-grayscale-70-color: #646464;
  --flix-grayscale-90-color: #444444;
  --flix-grayscale-100-color: #353535;
  --flix-neutral-color: #016ae7;
  --flix-success-color: #228f00;
  --flix-success-dark-color: #136b00;
  --flix-warning-color: #ff5704;
  --flix-warning-dark-color: #c42d00;
  --flix-danger-color: #dd2828;
  --flix-danger-dark-color: #b31414;
  --flix-bg-primary-color: #ffffff;
  --flix-bg-primary-transparent-color: rgba(255, 255, 255, 0.88);
  --flix-bg-secondary-color: #f7f7f7;
  --flix-box-bg-color: #ffffff;
  --flix-highlight-color: #aee9fc;
  --flix-overlay-bg-color: rgba(0, 0, 0, 0.5);
  --flix-focus-outline-color: #016ae7;
  --flix-content-primary-color: #353535;
  --flix-content-secondary-color: #646464;
  --flix-heading-font-color: #004963;
  --flix-link-color: #bd5604;
  --flix-line-primary-color: #c8c8c8;
  --flix-icon-primary-color: #8b8b8b;
  --flix-icon-secondary-color: #ef782c;
  --flix-box-shadow-color: rgba(0, 0, 0, 0.18);
  --flix-box-shadow-subtle-color: rgba(0, 0, 0, 0.06);
  --flix-button-primary-color: #004963;
  --flix-button-label-color: #ffffff;
  --flix-header-bg-color: #004963;
  --flix-header-color: #ffffff;
  --flix-header-nav-bg-color-tablet: #003547;
  --flix-input-border-color: #8b8b8b;
  --flix-input-height-mobile: 44px;
  --flix-input-height-desktop: 36px;
  --flix-spacing-1: 6px;
  --flix-spacing-2: 12px;
  --flix-spacing-3: 18px;
  --flix-spacing-4: 24px;
  --flix-spacing-5: 30px;
  --flix-spacing-6: 36px;
  --flix-spacing-7: 42px;
  --flix-spacing-8: 48px;
  --flix-spacing-9: 54px;
  --flix-spacing-10: 60px;
  --flix-spacing-11: 66px;
  --flix-spacing-12: 72px;
  --flix-spacing-half: 3px;
  --flix-grid-gutter-2: 12px;
  --flix-grid-gutter-4: 24px;
  --flix-page-min-width: 320px;
  --flix-page-max-width: 1200px;
  --flix-font-size-base: 1rem;
  --flix-font-size-primary: 1rem;
  --flix-font-size-small: 0.875rem;
  --flix-font-size-fineprint: 0.75rem;
  --flix-font-size-h1: 1.7rem;
  --flix-font-size-h2: 1.3rem;
  --flix-font-size-h3: 1.1rem;
  --flix-font-size-h4: 1rem;
  --flix-line-height-primary: 1.5rem;
  --flix-line-height-small: 1.125rem;
  --flix-line-height-fineprint: 0.938rem;
  --flix-line-height-h1: 2rem;
  --flix-line-height-h2: 1.7rem;
  --flix-line-height-h3: 1.5rem;
  --flix-line-height-h4: 1.5rem;
  --flix-hover-layer-color: linear-gradient(rgba(0, 0, 0, 0.06), rgba(0, 0, 0, 0.06));
  --flix-pressed-layer-color: linear-gradient(rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.12));
  --flix-disabled-element-opacity: 0.5;
  --flix-primary-border-radius: 6px;
  --flix-primary-box-shadow: 0px 6px 12px rgba(0, 0, 0, .06), 0px 3px 18px rgba(0, 0, 0, .06), 0px 3px 6px rgba(0, 0, 0, .18);
  --flix-color-scheme: normal;
  --flix-font-family-primary: Roboto, Arial, sans-serif;
  --flix-font-weight-normal: 400;
  --flix-font-weight-semibold: 500;
  --flix-font-weight-bold: 700;
}

.flix-theme-neptune {
  color: var(--flix-content-primary-color);
  font-family: var(--flix-font-family-primary);
  font-size: var(--flix-font-size-base);
  font-weight: var(--flix-font-weight-normal);
  line-height: var(--flix-line-height-primary);
  color-scheme: var(--flix-color-scheme);
  /* stylelint-disable-next-line media-feature-name-no-vendor-prefix */
}
@media only screen and (-webkit-min-device-pixel-ratio: 2) and (pointer: coarse) {
  .flix-theme-neptune {
    /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
    font: -apple-system-body;
    font-family: var(--flix-font-family-primary);
  }
}

.flix-theme-neptune-dark {
  --flix-brand-primary-color: #004963;
  --flix-ui-primary-color: #00a6de;
  --flix-ui-primary-light-color: #005c7b;
  --flix-ui-primary-dark-color: #21c5fc;
  --flix-grayscale-0-color: #151515;
  --flix-grayscale-10-color: #252525;
  --flix-grayscale-30-color: #444444;
  --flix-grayscale-50-color: #646464;
  --flix-grayscale-70-color: #8b8b8b;
  --flix-grayscale-90-color: #c8c8c8;
  --flix-grayscale-100-color: #ffffff;
  --flix-neutral-color: #039fd5;
  --flix-success-color: #44b300;
  --flix-success-dark-color: #cef38d;
  --flix-warning-color: #ff8206;
  --flix-warning-dark-color: #ffe393;
  --flix-danger-color: #fd5a5a;
  --flix-danger-dark-color: #ffc9ce;
  --flix-bg-primary-color: #151515;
  --flix-bg-primary-transparent-color: rgba(53, 53, 53, 0.88);
  --flix-bg-secondary-color: #121212;
  --flix-box-bg-color: #252525;
  --flix-highlight-color: #003547;
  --flix-overlay-bg-color: rgba(0, 0, 0, 0.5);
  --flix-focus-outline-color: #016ae7;
  --flix-content-primary-color: #ffffff;
  --flix-content-secondary-color: #c8c8c8;
  --flix-heading-font-color: #ffffff;
  --flix-link-color: #ffaf6e;
  --flix-line-primary-color: #646464;
  --flix-icon-primary-color: #ffffff;
  --flix-icon-secondary-color: #ef782c;
  --flix-box-shadow-color: rgba(0, 0, 0, 0.18);
  --flix-box-shadow-subtle-color: rgba(0, 0, 0, 0.06);
  --flix-button-primary-color: #004963;
  --flix-button-label-color: #ffffff;
  --flix-header-bg-color: #252525;
  --flix-header-color: #ffffff;
  --flix-header-nav-bg-color-tablet: #202020;
  --flix-input-border-color: #c8c8c8;
  --flix-input-height-mobile: 44px;
  --flix-input-height-desktop: 36px;
  --flix-spacing-1: 6px;
  --flix-spacing-2: 12px;
  --flix-spacing-3: 18px;
  --flix-spacing-4: 24px;
  --flix-spacing-5: 30px;
  --flix-spacing-6: 36px;
  --flix-spacing-7: 42px;
  --flix-spacing-8: 48px;
  --flix-spacing-9: 54px;
  --flix-spacing-10: 60px;
  --flix-spacing-11: 66px;
  --flix-spacing-12: 72px;
  --flix-spacing-half: 3px;
  --flix-grid-gutter-2: 12px;
  --flix-grid-gutter-4: 24px;
  --flix-page-min-width: 320px;
  --flix-page-max-width: 1200px;
  --flix-font-size-base: 1rem;
  --flix-font-size-primary: 1rem;
  --flix-font-size-small: 0.875rem;
  --flix-font-size-fineprint: 0.75rem;
  --flix-font-size-h1: 1.7rem;
  --flix-font-size-h2: 1.3rem;
  --flix-font-size-h3: 1.1rem;
  --flix-font-size-h4: 1rem;
  --flix-line-height-primary: 1.5rem;
  --flix-line-height-small: 1.125rem;
  --flix-line-height-fineprint: 0.938rem;
  --flix-line-height-h1: 2rem;
  --flix-line-height-h2: 1.7rem;
  --flix-line-height-h3: 1.5rem;
  --flix-line-height-h4: 1.5rem;
  --flix-hover-layer-color: linear-gradient(rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.12));
  --flix-pressed-layer-color: linear-gradient(rgba(0, 0, 0, 0.24), rgba(0, 0, 0, 0.24));
  --flix-disabled-element-opacity: 0.5;
  --flix-primary-border-radius: 6px;
  --flix-primary-box-shadow: 0px 6px 12px rgba(0, 0, 0, .06), 0px 3px 18px rgba(0, 0, 0, .06), 0px 3px 6px rgba(0, 0, 0, .18);
  --flix-color-scheme: dark;
  --flix-font-family-primary: Roboto, Arial, sans-serif;
  --flix-font-weight-normal: 400;
  --flix-font-weight-semibold: 500;
  --flix-font-weight-bold: 700;
}

.flix-theme-neptune-dark {
  color: var(--flix-content-primary-color);
  font-family: var(--flix-font-family-primary);
  font-size: var(--flix-font-size-base);
  font-weight: var(--flix-font-weight-normal);
  line-height: var(--flix-line-height-primary);
  color-scheme: var(--flix-color-scheme);
  /* stylelint-disable-next-line media-feature-name-no-vendor-prefix */
}
@media only screen and (-webkit-min-device-pixel-ratio: 2) and (pointer: coarse) {
  .flix-theme-neptune-dark {
    /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
    font: -apple-system-body;
    font-family: var(--flix-font-family-primary);
  }
}

* {
  box-sizing: border-box;
}

[class^=flix-] {
  margin: 0;
  padding: 0;
}
[class^=flix-]::before, [class^=flix-]::after {
  box-sizing: border-box;
}

.flix-tooltip, .flix-header-nav-subnav, .flix-dropdown__items, .flix-balloon {
  --flix-balloon-width: 15.75rem;
  --flix-balloon-height: 44px;
  --flix-balloon-background: var(--flix-box-bg-color);
  --flix-balloon-color: var(--flix-content-primary-color);
  --flix-balloon-close-color: var(--flix-icon-primary-color);
  --flix-balloon-content-margin: 0;
  --flix-balloon-close-margin: calc(-1 * var(--flix-spacing-1)) calc(-1 * var(--flix-spacing-1)) calc(-1 * var(--flix-spacing-1)) var(--flix-spacing-2);
  position: relative;
  z-index: 10;
  justify-content: space-between;
  width: -moz-fit-content;
  width: fit-content;
  min-width: var(--flix-balloon-width);
  min-height: var(--flix-balloon-height);
  color: var(--flix-balloon-color);
  font-family: inherit;
  font-size: var(--flix-font-size-primary);
  text-decoration: none;
}

.flix-tooltip--active:not([hidden]), .flix-dropdown--active .flix-dropdown__items:not([hidden]), .flix-balloon:not([hidden]) {
  display: flex;
}

.flix-tooltip::before, .flix-header-nav-subnav::before, .flix-dropdown__items::before, .flix-balloon::before {
  content: "";
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--flix-primary-border-radius);
  background: var(--flix-balloon-background);
  box-shadow: var(--flix-primary-box-shadow);
}

.flix-tooltip__content, .flix-balloon__content {
  position: relative;
  z-index: 1;
  flex: auto;
  margin: var(--flix-balloon-content-margin);
  text-align: left;
}

.flix-tooltip__close, .flix-balloon__close {
  position: relative;
  z-index: 1;
  align-self: flex-start;
  margin: var(--flix-balloon-close-margin);
}
.flix-tooltip__close::before, .flix-balloon__close::before {
  content: "";
  -webkit-mask: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M13.41 12l6.37-6.36a1 1 0 10-1.42-1.42L12 10.6 5.64 4.22a1 1 0 10-1.42 1.42L10.6 12l-6.37 6.36a1 1 0 101.42 1.42L12 13.4l6.36 6.37a1 1 0 101.42-1.42L13.4 12z' /%3E%3C/svg%3E") no-repeat center/var(--flix-spacing-3);
          mask: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M13.41 12l6.37-6.36a1 1 0 10-1.42-1.42L12 10.6 5.64 4.22a1 1 0 10-1.42 1.42L10.6 12l-6.37 6.36a1 1 0 101.42 1.42L12 13.4l6.36 6.37a1 1 0 101.42-1.42L13.4 12z' /%3E%3C/svg%3E") no-repeat center/var(--flix-spacing-3);
  width: var(--flix-spacing-3);
  height: var(--flix-spacing-3);
  background-color: var(--flix-balloon-close-color);
}

.flix-tooltip--danger, .flix-balloon--danger {
  --flix-balloon-background: var(--flix-danger-color);
  --flix-balloon-color: var(--flix-grayscale-0-color);
  --flix-balloon-close-color: var(--flix-grayscale-0-color);
}

/** Size rules **/
.flix-tooltip--content-fit, .flix-balloon--content-fit {
  --flix-balloon-width: auto;
  --flix-balloon-height: auto;
  --flix-balloon-content-margin: calc(-1 * var(--flix-spacing-1));
  --flix-balloon-close-margin: calc(-1 * var(--flix-spacing-2)) calc(-1 * var(--flix-spacing-2)) calc(-1 * var(--flix-spacing-2)) var(--flix-spacing-2);
  white-space: nowrap;
}

.flix-tooltip--medium, .flix-balloon--medium {
  --flix-balloon-width: 23.25rem;
}

.flix-tooltip--large, .flix-balloon--large {
  --flix-balloon-width: 30.75rem;
}

/** Poisition rules **/
.flix-tooltip, .flix-balloon {
  padding: var(--flix-spacing-2) var(--flix-spacing-2) var(--flix-spacing-2) var(--flix-spacing-4);
}
.flix-tooltip::before, .flix-balloon::before {
  top: 0;
  right: 0;
  bottom: 0;
  left: var(--flix-spacing-2);
}

.flix-tooltip--left, .flix-balloon--left {
  padding: var(--flix-spacing-2) var(--flix-spacing-4) var(--flix-spacing-2) var(--flix-spacing-2);
}
.flix-tooltip--left::before, .flix-balloon--left::before {
  top: 0;
  right: var(--flix-spacing-2);
  bottom: 0;
  left: 0;
}

.flix-tooltip--top, .flix-dropdown--top .flix-dropdown__items, .flix-balloon--top {
  padding: var(--flix-spacing-2) var(--flix-spacing-2) var(--flix-spacing-4);
}
.flix-tooltip--top::before, .flix-dropdown--top .flix-dropdown__items::before, .flix-balloon--top::before {
  top: 0;
  right: 0;
  bottom: var(--flix-spacing-2);
  left: 0;
}

.flix-tooltip--bottom, .flix-header-nav-subnav, .flix-dropdown__items, .flix-balloon--bottom {
  padding: var(--flix-spacing-4) var(--flix-spacing-2) var(--flix-spacing-2);
}
.flix-tooltip--bottom::before, .flix-header-nav-subnav::before, .flix-dropdown__items::before, .flix-balloon--bottom::before {
  top: var(--flix-spacing-2);
  right: 0;
  bottom: 0;
  left: 0;
}

/** Arrow rules **/
.flix-tooltip::after, .flix-header-nav-subnav::after, .flix-dropdown__items::after, .flix-balloon::after {
  content: "";
  position: absolute;
  z-index: 0;
  width: 0;
  height: 0;
}

.flix-tooltip::after, .flix-balloon::after {
  inset: auto auto auto var(--flix-spacing-1);
  border-top: calc(var(--flix-spacing-1) + 1px) solid transparent;
  border-right: calc(var(--flix-spacing-1) + 1px) solid var(--flix-balloon-background);
  border-bottom: calc(var(--flix-spacing-1) + 1px) solid transparent;
  border-left: 0;
}

.flix-tooltip--left::after, .flix-balloon--left::after {
  inset: auto var(--flix-spacing-1) auto auto;
  border-top: calc(var(--flix-spacing-1) + 1px) solid transparent;
  border-right: 0;
  border-bottom: calc(var(--flix-spacing-1) + 1px) solid transparent;
  border-left: calc(var(--flix-spacing-1) + 1px) solid var(--flix-balloon-background);
}

.flix-tooltip::after, .flix-balloon::after {
  top: 50%;
  transform: translateY(-50%);
}

.flix-tooltip--start::after, .flix-balloon--start::after {
  top: min(25%, var(--flix-spacing-3));
  transform: none;
}

.flix-tooltip--end::after, .flix-balloon--end::after {
  top: auto;
  bottom: min(25%, var(--flix-spacing-3));
  transform: none;
}

.flix-tooltip--bottom::after, .flix-header-nav-subnav::after, .flix-dropdown__items::after, .flix-balloon--bottom::after {
  inset: var(--flix-spacing-1) auto auto;
  border-top: 0;
  border-right: calc(var(--flix-spacing-1) + 1px) solid transparent;
  border-bottom: calc(var(--flix-spacing-1) + 1px) solid var(--flix-balloon-background);
  border-left: calc(var(--flix-spacing-1) + 1px) solid transparent;
}

.flix-tooltip--top::after, .flix-dropdown--top .flix-dropdown__items::after, .flix-balloon--top::after {
  inset: auto auto var(--flix-spacing-1);
  border-top: calc(var(--flix-spacing-1) + 1px) solid var(--flix-balloon-background);
  border-right: calc(var(--flix-spacing-1) + 1px) solid transparent;
  border-bottom: 0;
  border-left: calc(var(--flix-spacing-1) + 1px) solid transparent;
}

.flix-tooltip--bottom::after, .flix-tooltip--top::after, .flix-header-nav-subnav::after, .flix-dropdown--top .flix-dropdown__items::after, .flix-dropdown__items::after, .flix-balloon--top::after, .flix-balloon--bottom::after {
  right: auto;
  left: 50%;
  transform: translateX(-50%);
}

.flix-tooltip--bottom.flix-tooltip--start::after, .flix-tooltip--top.flix-tooltip--start::after, .flix-header-nav-subnav--right::after, .flix-dropdown--right .flix-dropdown__items::after, .flix-balloon--start.flix-balloon--top::after, .flix-balloon--start.flix-balloon--bottom::after {
  left: min(25%, var(--flix-spacing-3));
  transform: none;
}

.flix-tooltip--bottom.flix-tooltip--end::after, .flix-tooltip--top.flix-tooltip--end::after, .flix-header-nav-subnav--left::after, .flix-dropdown--left .flix-dropdown__items::after, .flix-balloon--end.flix-balloon--top::after, .flix-balloon--end.flix-balloon--bottom::after {
  right: min(25%, var(--flix-spacing-3));
  left: auto;
  transform: none;
}

.flix-infobox, .flix-box {
  padding: var(--flix-spacing-4);
  border: 1px solid var(--flix-line-primary-color);
  border-radius: var(--flix-primary-border-radius);
  background-color: var(--flix-box-bg-color);
}

.flix-infobox--small, .flix-box--small {
  padding: var(--flix-spacing-2);
}

.flix-infobox, .flix-box--highlighted {
  border: 0;
  box-shadow: var(--flix-primary-box-shadow);
}

.flix-infobox--strong, .flix-box--dimmed, .flix-box--neutral, .flix-box--warning, .flix-box--success, .flix-box--danger {
  border-color: var(--flix-box-state-color);
  background-image: linear-gradient(var(--flix-bg-primary-transparent-color), var(--flix-bg-primary-transparent-color)), linear-gradient(var(--flix-box-state-color), var(--flix-box-state-color));
}

.flix-box--dimmed {
  --flix-box-state-color: var(--flix-grayscale-50-color);
}

.flix-infobox--strong, .flix-box--neutral {
  --flix-box-state-color: var(--flix-neutral-color);
}

.flix-infobox--warning, .flix-box--warning {
  --flix-box-state-color: var(--flix-warning-color);
}

.flix-infobox--success, .flix-box--success {
  --flix-box-state-color: var(--flix-success-color);
}

.flix-infobox--danger, .flix-box--danger {
  --flix-box-state-color: var(--flix-danger-color);
}

.flix-accordion {
  display: block;
  border: 1px solid var(--flix-line-primary-color);
  font-family: inherit;
}
.flix-accordion:first-of-type {
  border-radius: var(--flix-primary-border-radius) var(--flix-primary-border-radius) 0 0;
}
.flix-accordion:last-of-type {
  border-radius: 0 0 var(--flix-primary-border-radius) var(--flix-primary-border-radius);
}
.flix-accordion:only-of-type {
  border-radius: var(--flix-primary-border-radius);
}
.flix-accordion__title {
  color: var(--flix-container-content-color, var(--flix-heading-font-color));
  font-size: var(--flix-font-size-h4);
  font-weight: var(--flix-font-weight-bold);
  line-height: var(--flix-line-height-h4);
  display: block;
  position: relative;
  padding: var(--flix-spacing-2) var(--flix-spacing-6) var(--flix-spacing-2) var(--flix-spacing-2);
  outline: 0;
  cursor: pointer;
}
.flix-accordion__title .flix-icon {
  margin-right: var(--flix-spacing-half);
}
.flix-accordion__title .flix-icon::before {
  margin: 0;
}
.flix-accordion__title::-webkit-details-marker {
  display: none;
}
.flix-accordion__title::after {
  -webkit-mask: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M18.68 8.3a1 1 0 00-1.41 0l-5.28 5.28-5.26-5.27a1 1 0 00-1.4 0 1 1 0 000 1.41l5.96 5.99a1 1 0 001.41 0l5.98-6a1 1 0 000-1.42z' /%3E%3C/svg%3E") no-repeat center/var(--flix-spacing-4);
          mask: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M18.68 8.3a1 1 0 00-1.41 0l-5.28 5.28-5.26-5.27a1 1 0 00-1.4 0 1 1 0 000 1.41l5.96 5.99a1 1 0 001.41 0l5.98-6a1 1 0 000-1.42z' /%3E%3C/svg%3E") no-repeat center/var(--flix-spacing-4);
  width: var(--flix-spacing-4);
  height: var(--flix-spacing-4);
  background-color: var(--flix-icon-primary-color);
  content: "";
  position: absolute;
  top: 50%;
  right: var(--flix-spacing-2);
  transform: translateY(-50%);
}
.flix-accordion__title:hover {
  background-image: var(--flix-hover-layer-color);
  text-decoration: none;
}
.flix-accordion__title:enabled:active {
  background-image: var(--flix-pressed-layer-color);
  text-decoration: none;
}
.flix-accordion__title:focus {
  outline: 2px solid var(--flix-focus-outline-color);
  outline-offset: 1px;
  box-shadow: 0 0 0 calc(var(--flix-spacing-half) + 1px) var(--flix-grayscale-0-color);
  border-radius: var(--flix-primary-border-radius);
}
.flix-accordion[open] > .flix-accordion__title::after, .flix-accordion--open > .flix-accordion__title::after {
  transform: translateY(-50%) rotate(180deg);
}
.flix-accordion[open] > .flix-accordion__content, .flix-accordion--open > .flix-accordion__content {
  display: block;
}
.flix-accordion__content {
  display: none;
  padding: var(--flix-spacing-1) var(--flix-spacing-2) var(--flix-spacing-2);
}
.flix-accordion + .flix-accordion {
  border-top: 0;
}

.flix-avatar {
  display: block;
  flex-shrink: 0;
  width: var(--flix-spacing-6);
  height: var(--flix-spacing-6);
}
.flix-avatar__image {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 100%;
}
.flix-avatar__image svg {
  width: 100%;
  height: 100%;
}
a.flix-avatar:focus {
  outline: 2px solid var(--flix-focus-outline-color);
  outline-offset: 1px;
  box-shadow: 0 0 0 calc(var(--flix-spacing-half) + 1px) var(--flix-grayscale-0-color);
  border-radius: var(--flix-primary-border-radius);
}

.flix-avatar--sm {
  width: var(--flix-spacing-4);
  height: var(--flix-spacing-4);
}
.flix-avatar--lg {
  width: var(--flix-spacing-8);
  height: var(--flix-spacing-8);
}
.flix-avatar--xl {
  width: calc(var(--flix-spacing-8) * 2);
  height: calc(var(--flix-spacing-8) * 2);
}

.flix-balloon--top::after {
  top: auto;
}
.flix-blockquote, .flix-text blockquote {
  position: relative;
  margin: 0 0 var(--flix-spacing-2);
  padding: var(--flix-spacing-2) 0 0 calc(var(--flix-spacing-4) + var(--flix-spacing-2));
  color: var(--flix-content-primary-color);
  font-family: inherit;
}
@media (min-width: 600px) {
  .flix-blockquote, .flix-text blockquote {
    padding: var(--flix-spacing-2) 0 0 calc(var(--flix-spacing-8) + var(--flix-spacing-2));
  }
}
.flix-blockquote::before, .flix-text blockquote::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: var(--flix-spacing-4);
  height: var(--flix-spacing-2);
  /* stylelint-disable-next-line scss/operator-no-unspaced */
  background: url("data:image/svg+xml,%3Csvg id='Ebene_1' data-name='Ebene 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 159.8 100'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%238c8c8c;%7D%3C/style%3E%3C/defs%3E%3Ctitle%3Equote1%3C/title%3E%3Cpath class='cls-1' d='M159.4,62.9A32.2,32.2,0,0,0,129,35.7c3.7-13.8,12.8-19.4,23.8-22.5a1.3,1.3,0,0,0,.9-1.4l-1.8-11s-0.2-.9-1.6-0.7c-38,4.3-63.7,33.2-58.9,68.3,4.4,24.3,23.4,33.7,41,31.2a32.4,32.4,0,0,0,27-36.7h0ZM38.1,35.7c3.6-13.8,13-19.4,23.8-22.5a1.3,1.3,0,0,0,.9-1.4l-1.6-11S61-.2,59.6,0C21.6,4.1-3.9,33,.5,68.2c4.6,24.5,23.8,33.9,41.2,31.4A32.1,32.1,0,0,0,68.5,62.9,31.7,31.7,0,0,0,38.1,35.7Z'/%3E%3C/svg%3E") no-repeat;
}
@media (min-width: 600px) {
  .flix-blockquote::before, .flix-text blockquote::before {
    width: var(--flix-spacing-8);
    height: var(--flix-spacing-4);
  }
}

.flix-box:where(:not(:last-child)) {
  margin-bottom: var(--flix-spacing-2);
}
.flix-breadcrumbs {
  display: flex;
  align-items: center;
}
.flix-breadcrumbs__item {
  display: flex;
  align-items: center;
}
.flix-breadcrumbs__item + .flix-breadcrumbs__item::before {
  content: "";
  -webkit-mask: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M15.7 11.3L9.72 5.32a1 1 0 00-1.42 0 1 1 0 000 1.42L13.58 12 8.3 17.27a1 1 0 000 1.41 1 1 0 001.41 0l5.99-5.97a1 1 0 000-1.41z' /%3E%3C/svg%3E") no-repeat center/1.5rem;
          mask: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M15.7 11.3L9.72 5.32a1 1 0 00-1.42 0 1 1 0 000 1.42L13.58 12 8.3 17.27a1 1 0 000 1.41 1 1 0 001.41 0l5.99-5.97a1 1 0 000-1.41z' /%3E%3C/svg%3E") no-repeat center/1.5rem;
  width: 1.5rem;
  height: 1.5rem;
  background-color: var(--flix-icon-primary-color);
  margin: 0 var(--flix-spacing-half);
}
.flix-breadcrumbs__ellipsis::before {
  content: "...";
}
.flix-breadcrumbs__link:is(button) {
  border: 0;
  background: transparent;
  font-family: var(--flix-font-family-primary);
  cursor: pointer;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}
.flix-breadcrumbs__link {
  color: var(--flix-content-secondary-color);
  font-size: var(--flix-font-size-small);
  font-weight: var(--flix-font-weight-normal);
  line-height: var(--flix-line-height-small);
  position: relative;
  max-width: 20ch;
  padding: var(--flix-spacing-half);
  overflow: hidden;
  border-radius: var(--flix-primary-border-radius);
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.flix-breadcrumbs__link:focus {
  outline: 2px solid var(--flix-focus-outline-color);
  outline-offset: 1px;
  box-shadow: 0 0 0 calc(var(--flix-spacing-half) + 1px) var(--flix-grayscale-0-color);
}
.flix-breadcrumbs__link:link:hover, .flix-breadcrumbs__link:visited:hover, .flix-breadcrumbs__link:enabled:hover {
  background-image: var(--flix-hover-layer-color);
  text-decoration: none;
}
.flix-breadcrumbs__link:active {
  background-image: var(--flix-pressed-layer-color);
  text-decoration: none;
}
.flix-breadcrumbs__link[aria-current] {
  border-radius: var(--flix-primary-border-radius) var(--flix-primary-border-radius) 0 0;
  color: var(--flix-content-primary-color);
}
.flix-breadcrumbs__link[aria-current]::before {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 0;
  border-bottom: var(--flix-spacing-half) solid var(--flix-ui-primary-color);
}
.flix-breadcrumbs__link--no-clamp {
  max-width: -moz-fit-content;
  max-width: fit-content;
  overflow: unset;
}

.flix-btn, .flix-skip-link {
  --flix-button-bg-color: var(--flix-box-bg-color);
  --flix-button-border-color: var(--flix-grayscale-50-color);
  --flix-button-text-color: var(--flix-content-primary-color);
  display: inline-flex;
  position: relative;
  align-items: center;
  justify-content: center;
  min-width: 142px;
  max-width: 100%;
  min-height: var(--flix-input-height-mobile);
  padding-top: calc(var(--flix-spacing-2) - var(--flix-spacing-half) - 1px);
  padding-right: var(--flix-spacing-2);
  padding-bottom: calc(var(--flix-spacing-2) - var(--flix-spacing-half) - 1px);
  padding-left: var(--flix-spacing-2);
  gap: var(--flix-spacing-half);
  border: 1px solid var(--flix-button-border-color);
  border-radius: var(--flix-primary-border-radius);
  background: var(--flix-button-bg-color);
  font-family: inherit;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  overflow-wrap: anywhere;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  color: var(--flix-button-text-color);
  font-size: var(--flix-font-size-primary);
  font-weight: var(--flix-font-weight-semibold);
  line-height: var(--flix-line-height-primary);
  transition: background 250ms ease;
}
.flix-btn:enabled:hover, .flix-skip-link:enabled:hover, .flix-btn[href]:hover, [href].flix-skip-link:hover {
  background-image: var(--flix-hover-layer-color);
  text-decoration: none;
}
.flix-btn:enabled:active, .flix-skip-link:enabled:active {
  background-image: var(--flix-pressed-layer-color);
  text-decoration: none;
}
.flix-btn:focus, .flix-skip-link:focus {
  outline: 2px solid var(--flix-focus-outline-color);
  outline-offset: 1px;
  box-shadow: 0 0 0 calc(var(--flix-spacing-half) + 1px) var(--flix-grayscale-0-color);
}
.flix-btn:disabled, .flix-skip-link:disabled, .flix-btn[aria-disabled=true], [aria-disabled=true].flix-skip-link, .flix-btn--disabled {
  opacity: var(--flix-disabled-element-opacity);
  cursor: not-allowed;
}
@media (min-width: 0) and (max-width: 599px) {
  .flix-btn, .flix-skip-link {
    display: flex;
    width: -webkit-fill-available;
    width: -moz-available;
    width: stretch;
  }
  .flix-btn--sm, .flix-btn--md, .flix-btn--lg, .flix-btn--link, .flix-btn--square, .flix-btn--stacked {
    display: inline-flex;
    width: auto;
  }
}
@media (min-width: 1200px) {
  .flix-btn, .flix-skip-link {
    min-height: var(--flix-input-height-desktop);
    padding-top: calc(var(--flix-spacing-1) - 1px);
    padding-bottom: calc(var(--flix-spacing-1) - 1px);
  }
}
.flix-btn.flix-btn--loading, .flix-btn--loading.flix-skip-link {
  background-image: var(--flix-hover-layer-color);
  text-decoration: none;
}
.flix-btn--primary {
  --flix-button-bg-color: var(--flix-button-primary-color);
  --flix-button-border-color: var(--flix-button-primary-color);
  --flix-button-text-color: var(--flix-button-label-color);
}
.flix-btn--secondary {
  --flix-button-border-color: var(--flix-button-primary-color);
}
.flix-btn--danger {
  --flix-button-bg-color: var(--flix-danger-color);
  --flix-button-border-color: var(--flix-danger-color);
  --flix-button-text-color: var(--flix-grayscale-0-color);
}
.flix-btn--link {
  --flix-button-bg-color: transparent;
  --flix-button-border-color: transparent;
  --flix-button-text-color: var(--flix-container-content-color, var(--flix-content-primary-color));
  min-width: auto;
  padding: 0 var(--flix-spacing-half);
}
.flix-btn--link.flix-btn--loading::before {
  margin-left: 0;
}
.flix-btn--sm {
  min-width: auto;
  min-height: var(--flix-spacing-4);
  padding-top: 0;
  padding-bottom: 0;
  font-size: var(--flix-font-size-small);
  line-height: var(--flix-line-height-small);
}
.flix-btn--md {
  min-height: var(--flix-input-height-desktop);
  padding-top: calc(var(--flix-spacing-1) - 1px);
  padding-bottom: calc(var(--flix-spacing-1) - 1px);
}
.flix-btn--lg {
  min-height: var(--flix-spacing-8);
  padding-top: calc(var(--flix-spacing-2) - var(--flix-spacing-half));
  padding-right: var(--flix-spacing-4);
  padding-bottom: calc(var(--flix-spacing-2) - var(--flix-spacing-half));
  padding-left: var(--flix-spacing-4);
}
.flix-btn--loading::before {
  content: "";
  display: inline-block;
  flex-shrink: 0;
  width: var(--flix-spacing-4);
  height: var(--flix-spacing-4);
  animation: btn-spinner-rotate 1s infinite linear, btn-spinner-grow 250ms;
  border: var(--flix-spacing-half) solid rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  border-left-color: var(--flix-button-text-color);
}
.flix-btn--loading:has(.flix-btn__icon)::before, .flix-btn--loading:has([class^=hci-icon])::before {
  animation: btn-spinner-rotate 1s infinite linear;
}
@keyframes btn-spinner-grow {
  0% {
    width: 0;
    height: 0;
    opacity: 0;
  }
  100% {
    width: var(--flix-spacing-4);
    height: var(--flix-spacing-4);
    opacity: 1;
  }
}
@keyframes btn-spinner-rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.flix-btn--loading .flix-btn__icon, .flix-btn--loading [class^=hci-icon] {
  /* stylelint-disable-next-line declaration-no-important */
  display: none !important;
}
.flix-btn--square {
  min-width: var(--flix-input-height-mobile);
  padding-right: 0;
  padding-left: 0;
  aspect-ratio: 1;
}
@media (min-width: 1200px) {
  .flix-btn--square {
    min-width: var(--flix-input-height-desktop);
  }
}
.flix-btn--square.flix-btn--sm {
  min-width: var(--flix-spacing-4);
}
.flix-btn--square.flix-btn--md {
  min-width: var(--flix-spacing-6);
}
.flix-btn--square.flix-btn--lg {
  min-width: var(--flix-spacing-8);
}
.flix-btn--stacked {
  flex-direction: column;
  min-width: auto;
  padding-right: var(--flix-spacing-1);
  padding-left: var(--flix-spacing-1);
  font-size: var(--flix-font-size-small);
  line-height: var(--flix-line-height-small);
}
.flix-btn--stacked.flix-btn--lg {
  padding-right: calc(var(--flix-spacing-2) - var(--flix-spacing-half));
  padding-left: calc(var(--flix-spacing-2) - var(--flix-spacing-half));
  font-size: var(--flix-font-size-normal);
  line-height: var(--flix-line-height-normal);
}
.flix-btn--block {
  display: flex;
  width: -webkit-fill-available;
  width: -moz-available;
  width: stretch;
}

.flix-btn-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--flix-spacing-2);
}
.flix-btn-group:where(:not(:last-child)) {
  margin-bottom: var(--flix-spacing-2);
}
.flix-btn-group--half-gap {
  gap: var(--flix-spacing-half);
}
.flix-btn-group--1-gap {
  gap: var(--flix-spacing-1);
}
.flix-btn-group--wrap {
  flex-wrap: wrap;
}
.flix-btn-group--horizontal {
  flex-direction: row;
}
.flix-btn-group--vertical {
  flex-direction: column;
}
.flix-btn-group--align-center {
  align-items: center;
  justify-content: center;
}
.flix-btn-group--align-end {
  align-items: flex-end;
  justify-content: flex-end;
}
@media (min-width: 600px) {
  .flix-btn-group:not(.flix-btn-group--vertical, .flix-btn-group--horizontal) {
    flex-direction: row;
  }
}

.flix-calendar {
  z-index: 10;
  min-width: calc(var(--flix-spacing-1) * 46);
  border-radius: var(--flix-primary-border-radius);
  background-color: var(--flix-box-bg-color);
  box-shadow: var(--flix-primary-box-shadow);
}
.flix-calendar--flat {
  border-radius: 0;
  box-shadow: none;
}
.flix-calendar--horizontal {
  display: grid;
  grid-auto-flow: column;
}
.flix-calendar__month {
  padding: var(--flix-spacing-2);
}
.flix-calendar__table {
  width: 100%;
  border-spacing: 0 var(--flix-spacing-xxxs);
}
.flix-calendar__caption {
  color: var(--flix-container-content-color, var(--flix-heading-font-color));
  font-size: var(--flix-font-size-h3);
  font-weight: var(--flix-font-weight-bold);
  line-height: var(--flix-line-height-h3);
}
.flix-calendar__btn, .flix-calendar__cell {
  position: relative;
  font-family: var(--flix-font-family-primary);
  line-height: 2.25rem;
  text-align: center;
}
.flix-calendar__cell--range:not(:empty) {
  background-color: var(--flix-highlight-color);
}
.flix-calendar__cell--start .flix-calendar__btn, .flix-calendar__cell--end .flix-calendar__btn, .flix-calendar__cell--selected .flix-calendar__btn {
  background-color: var(--flix-button-primary-color);
  color: var(--flix-button-label-color);
}
.flix-calendar__cell--end .flix-calendar__btn::before, .flix-calendar__cell--start .flix-calendar__btn::before {
  content: " ";
  position: absolute;
  top: 50%;
  width: var(--flix-spacing-2);
  height: var(--flix-spacing-2);
  margin-top: calc(-1 * var(--flix-spacing-1));
  transform: rotate(45deg);
  background-color: var(--flix-button-primary-color);
  pointer-events: none;
}
.flix-calendar__cell--start:not(:empty) {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, var(--flix-highlight-color) 50%);
}
.flix-calendar__cell--start .flix-calendar__btn::before {
  left: 100%;
  margin-left: calc(-1 * var(--flix-spacing-1));
}
.flix-calendar__cell--end:not(:empty) {
  background-image: linear-gradient(to right, var(--flix-highlight-color) 50%, rgba(255, 255, 255, 0) 50%);
}
.flix-calendar__cell--end .flix-calendar__btn::before {
  right: 100%;
  margin-right: calc(-1 * var(--flix-spacing-1));
}
.flix-calendar__cell--start.flix-calendar__cell--end {
  background-image: none;
}
.flix-calendar__cell--start.flix-calendar__cell--end .flix-calendar__btn::before {
  display: none;
}
.flix-calendar__cell--start .flix-calendar__btn {
  z-index: 1;
}
.flix-calendar__cell--current .flix-calendar__btn::after {
  content: "";
  display: block;
  position: absolute;
  bottom: var(--flix-spacing-half);
  left: 50%;
  width: 1.125rem;
  height: 0.1875rem;
  transform: translateX(calc(-1 * 1.125rem / 2));
  border-radius: var(--flix-primary-border-radius);
  background-color: var(--flix-content-primary-color);
}
.flix-calendar__cell--selected.flix-calendar__cell--current .flix-calendar__btn::after, .flix-calendar__cell--start.flix-calendar__cell--current .flix-calendar__btn::after, .flix-calendar__cell--end.flix-calendar__cell--current .flix-calendar__btn::after {
  background-color: var(--flix-button-label-color);
}
.flix-calendar__btn {
  padding: var(--flix-spacing-1) 0;
  font-size: var(--flix-font-size-primary);
  border: 0;
  background: transparent;
  font-family: var(--flix-font-family-primary);
  cursor: pointer;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  color: var(--flix-content-primary-color);
  font-size: var(--flix-font-size-primary);
  font-weight: var(--flix-font-weight-semibold);
  line-height: var(--flix-line-height-primary);
  display: block;
  width: -webkit-fill-available;
  width: -moz-available;
  width: stretch;
  min-width: var(--flix-spacing-6);
  min-height: var(--flix-spacing-6);
  border-radius: var(--flix-primary-border-radius);
}
.flix-calendar__btn:focus {
  outline: 2px solid var(--flix-focus-outline-color);
  outline-offset: 1px;
  box-shadow: 0 0 0 calc(var(--flix-spacing-half) + 1px) var(--flix-grayscale-0-color);
  z-index: 1;
}
.flix-calendar__btn:hover, .flix-calendar__btn:hover::before {
  background-image: var(--flix-hover-layer-color);
  text-decoration: none;
}
.flix-calendar__btn:active, .flix-calendar__btn:active::before {
  background-image: var(--flix-pressed-layer-color);
  text-decoration: none;
}
.flix-calendar__btn:disabled, .flix-calendar__btn[aria-disabled=true], .flix-calendar__btn--disabled, .flix-calendar__btn:disabled::before, .flix-calendar__btn[aria-disabled=true]::before, .flix-calendar__btn--disabled::before {
  opacity: var(--flix-disabled-element-opacity);
  cursor: not-allowed;
}
.flix-calendar-header {
  display: grid;
  grid-template-columns: min-content auto min-content;
  margin-bottom: var(--flix-spacing-2);
  gap: var(--flix-spacing-2);
}
.flix-calendar-header--birthday {
  grid-template-columns: auto auto;
}

.flix-checkbox {
  position: relative;
}
.flix-checkbox--sm .flix-checkbox__label {
  min-height: var(--flix-spacing-3);
  border-radius: var(--flix-primary-border-radius);
}
.flix-checkbox--sm .flix-checkbox__label:hover {
  background-image: var(--flix-hover-layer-color);
  text-decoration: none;
}
.flix-checkbox--sm .flix-checkbox__label::after {
  -webkit-mask-size: 78%;
          mask-size: 78%;
}
.flix-checkbox--sm .flix-checkbox__input, .flix-checkbox--sm .flix-checkbox__label::before, .flix-checkbox--sm .flix-checkbox__label::after {
  width: var(--flix-spacing-3);
  height: var(--flix-spacing-3);
  margin-top: calc((var(--flix-line-height-primary) - var(--flix-spacing-3)) / 2);
}
@supports (margin-top: max(0px, calc((var(--flix-line-height-primary) - var(--flix-spacing-3)) / 2))) {
  .flix-checkbox--sm .flix-checkbox__input, .flix-checkbox--sm .flix-checkbox__label::before, .flix-checkbox--sm .flix-checkbox__label::after {
    margin-top: max(0px, (var(--flix-line-height-primary) - var(--flix-spacing-3)) / 2);
  }
}
.flix-checkbox__label {
  display: inline-flex;
  position: relative;
  align-items: center;
  min-height: var(--flix-spacing-5);
  margin: calc(-1 * var(--flix-spacing-half));
  padding: var(--flix-spacing-half);
  border-radius: var(--flix-primary-border-radius);
  color: var(--flix-content-primary-color);
  font-family: inherit;
  vertical-align: middle;
  cursor: pointer;
  gap: var(--flix-spacing-2);
}
.flix-checkbox__label::before, .flix-checkbox__label::after {
  content: "";
  display: block;
  flex-shrink: 0;
  align-self: flex-start;
}
.flix-checkbox__label::before {
  width: var(--flix-spacing-5);
  height: var(--flix-spacing-5);
  border: 2px solid var(--flix-input-border-color);
  border-radius: var(--flix-primary-border-radius);
  transition: border-color 250ms ease;
  margin-top: calc((var(--flix-line-height-primary) - var(--flix-spacing-5)) / 2);
}
@supports (margin-top: max(0px, calc((var(--flix-line-height-primary) - var(--flix-spacing-5)) / 2))) {
  .flix-checkbox__label::before {
    margin-top: max(0px, (var(--flix-line-height-primary) - var(--flix-spacing-5)) / 2);
  }
}
.flix-checkbox__label::after {
  -webkit-mask: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M21.7 6.2l-13 13a1 1 0 01-1.4 0l-5-5a1 1 0 111.4-1.4L8 17.08l12.3-12.3a1 1 0 111.4 1.42z' /%3E%3C/svg%3E") no-repeat center/var(--flix-spacing-5);
          mask: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M21.7 6.2l-13 13a1 1 0 01-1.4 0l-5-5a1 1 0 111.4-1.4L8 17.08l12.3-12.3a1 1 0 111.4 1.42z' /%3E%3C/svg%3E") no-repeat center/var(--flix-spacing-5);
  width: var(--flix-spacing-5);
  height: var(--flix-spacing-5);
  background-color: var(--flix-input-border-color);
  position: absolute;
  top: var(--flix-spacing-half);
  left: var(--flix-spacing-half);
  -webkit-mask-size: 80%;
          mask-size: 80%;
  transform: scale(0);
  pointer-events: none;
  transition: transform 250ms ease, background-color 250ms ease;
  margin-top: calc((var(--flix-line-height-primary) - var(--flix-spacing-5)) / 2);
}
@supports (margin-top: max(0px, calc((var(--flix-line-height-primary) - var(--flix-spacing-5)) / 2))) {
  .flix-checkbox__label::after {
    margin-top: max(0px, (var(--flix-line-height-primary) - var(--flix-spacing-5)) / 2);
  }
}
.flix-checkbox__input {
  width: var(--flix-spacing-5);
  height: var(--flix-spacing-5);
  margin-top: calc((var(--flix-line-height-primary) - var(--flix-spacing-5)) / 2);
}
@supports (margin-top: max(0px, calc((var(--flix-line-height-primary) - var(--flix-spacing-5)) / 2))) {
  .flix-checkbox__input {
    margin-top: max(0px, (var(--flix-line-height-primary) - var(--flix-spacing-5)) / 2);
  }
}
.flix-checkbox__input:checked + .flix-checkbox__label::after {
  transform: scale(1);
}
.flix-checkbox__input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.flix-checkbox__input:focus + .flix-checkbox__label::before {
  outline: 2px solid var(--flix-focus-outline-color);
  outline-offset: 1px;
  box-shadow: 0 0 0 calc(var(--flix-spacing-half) + 1px) var(--flix-grayscale-0-color);
}
.flix-checkbox__input:enabled + .flix-checkbox__label:hover, .flix-checkbox__input:enabled:hover + .flix-checkbox__label {
  background-image: var(--flix-hover-layer-color);
  text-decoration: none;
}
.flix-checkbox__input:checked + .flix-checkbox__label::before {
  border-color: var(--flix-ui-primary-color);
}
.flix-checkbox__input:checked + .flix-checkbox__label::after {
  background-color: var(--flix-ui-primary-color);
}
.flix-checkbox--disabled .flix-checkbox__label {
  opacity: var(--flix-disabled-element-opacity);
  cursor: not-allowed;
}
.flix-checkbox--error .flix-checkbox__label::before {
  border-color: var(--flix-danger-color);
}
.flix-checkbox--error .flix-checkbox__input:not(:checked) + .flix-checkbox__label::before {
  box-shadow: inset 0 0 0 100vh var(--flix-bg-primary-transparent-color), inset 0 0 0 100vh var(--flix-danger-color);
  transition: box-shadow 250ms ease;
}
.flix-checkbox--error .flix-checkbox__input:focus:not(:checked) + .flix-checkbox__label::before {
  box-shadow: inset 0 0 0 100vh var(--flix-bg-primary-transparent-color), inset 0 0 0 100vh var(--flix-danger-color), 0 0 0 4px var(--flix-grayscale-0-color);
}
.flix-checkbox__info:not(:empty) {
  color: var(--flix-container-content-color, var(--flix-content-primary-color));
  font-size: var(--flix-font-size-small);
  font-weight: var(--flix-font-weight-normal);
  line-height: var(--flix-line-height-small);
  display: block;
  margin-top: var(--flix-spacing-1);
}
.flix-checkbox__info:not(:empty) ~ .flix-checkbox__info {
  margin-top: var(--flix-spacing-half);
}
.flix-checkbox--error .flix-checkbox__info {
  color: var(--flix-danger-dark-color);
}
.flix-checkbox__info ~ .flix-checkbox__info {
  color: var(--flix-content-primary-color);
}
.flix-checkbox__input:indeterminate + .flix-checkbox__label::before, .flix-checkbox--indeterminate > .flix-checkbox__label::before {
  border-color: var(--flix-ui-primary-color);
}
.flix-checkbox__input:indeterminate + .flix-checkbox__label::after, .flix-checkbox--indeterminate > .flix-checkbox__label::after {
  -webkit-mask-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M21 11H3a1 1 0 000 2h18a1 1 0 000-2z' /%3E%3C/svg%3E");
          mask-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M21 11H3a1 1 0 000 2h18a1 1 0 000-2z' /%3E%3C/svg%3E");
  transform: scale(1);
  background-color: var(--flix-ui-primary-color);
}
.flix-checkbox--sm .flix-checkbox__label::before {
  border-radius: 3px;
}
.flix-checkbox--no-label .flix-checkbox__label::before, .flix-checkbox--no-label .flix-checkbox__label::after {
  margin-top: 0;
}

.flix-choice-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 var(--flix-spacing-4);
  border: 0;
}
.flix-choice-wrapper__info:not(:empty) {
  color: var(--flix-container-content-color, var(--flix-content-primary-color));
  font-size: var(--flix-font-size-small);
  font-weight: var(--flix-font-weight-normal);
  line-height: var(--flix-line-height-small);
  display: block;
  margin-top: var(--flix-spacing-1);
}
.flix-choice-wrapper__info:not(:empty) ~ .flix-choice-wrapper__info {
  margin-top: var(--flix-spacing-half);
}
.flix-choice-wrapper--error .flix-choice-wrapper__info {
  color: var(--flix-danger-dark-color);
}
.flix-choice-wrapper__info ~ .flix-choice-wrapper__info {
  color: var(--flix-content-primary-color);
}
.flix-choice-wrapper__fieldset {
  border: 0;
}
.flix-choice-wrapper__item {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  border: solid var(--flix-line-primary-color);
  border-radius: var(--flix-primary-border-radius);
}
.flix-choice-wrapper__item:first-child:nth-last-of-type(2), .flix-choice-wrapper__item:first-child:nth-last-of-type(2) ~ .flix-choice-wrapper__item {
  min-width: 50%;
}
.flix-choice-wrapper__item:first-child:nth-last-of-type(3), .flix-choice-wrapper__item:first-child:nth-last-of-type(3) ~ .flix-choice-wrapper__item {
  min-width: 33.3333%;
}
.flix-choice-wrapper__item:first-child:nth-last-of-type(4), .flix-choice-wrapper__item:first-child:nth-last-of-type(4) ~ .flix-choice-wrapper__item {
  min-width: 25%;
}
.flix-choice-wrapper--error .flix-choice-wrapper__item {
  border-color: var(--flix-danger-color);
  background-image: linear-gradient(var(--flix-bg-primary-transparent-color), var(--flix-bg-primary-transparent-color)), linear-gradient(var(--flix-danger-color), var(--flix-danger-color));
}
.flix-choice-wrapper--error .flix-choice-wrapper__info:first-of-type {
  color: var(--flix-danger-dark-color);
}
.flix-choice-wrapper .flix-radio,
.flix-choice-wrapper .flix-checkbox {
  flex-grow: 1;
}
.flix-choice-wrapper .flix-radio__label,
.flix-choice-wrapper .flix-checkbox__label {
  display: block;
  height: 100%;
  min-height: calc(var(--flix-spacing-5) + var(--flix-spacing-2) * 2);
  margin: 0;
  padding: var(--flix-spacing-2);
  padding-left: calc(var(--flix-spacing-5) + var(--flix-spacing-2) * 2);
}
.flix-choice-wrapper .flix-radio__label::before,
.flix-choice-wrapper .flix-checkbox__label::before {
  margin-left: calc(-1 * var(--flix-spacing-5) - var(--flix-spacing-2));
  float: left;
}
.flix-choice-wrapper .flix-radio__label::after,
.flix-choice-wrapper .flix-checkbox__label::after {
  top: var(--flix-spacing-2);
  left: var(--flix-spacing-2);
}
.flix-choice-wrapper:not(.flix-choice-wrapper--column) .flix-choice-wrapper__item {
  border-width: 1px 1px 1px 0;
}
.flix-choice-wrapper:not(.flix-choice-wrapper--column) .flix-choice-wrapper__item:first-of-type {
  border-left-width: 1px;
}
.flix-choice-wrapper:not(.flix-choice-wrapper--column) .flix-choice-wrapper__item:first-of-type:not(:only-of-type) {
  border-radius: var(--flix-primary-border-radius) 0 0 var(--flix-primary-border-radius);
  border-right-color: var(--flix-line-primary-color);
}
.flix-choice-wrapper:not(.flix-choice-wrapper--column) .flix-choice-wrapper__item:first-of-type:not(:only-of-type) .flix-radio__label,
.flix-choice-wrapper:not(.flix-choice-wrapper--column) .flix-choice-wrapper__item:first-of-type:not(:only-of-type) .flix-checkbox__label {
  border-radius: var(--flix-primary-border-radius) 0 0 var(--flix-primary-border-radius);
}
.flix-choice-wrapper:not(.flix-choice-wrapper--column) .flix-choice-wrapper__item:last-of-type:not(:only-of-type) {
  border-radius: 0 var(--flix-primary-border-radius) var(--flix-primary-border-radius) 0;
  border-left-color: var(--flix-line-primary-color);
}
.flix-choice-wrapper:not(.flix-choice-wrapper--column) .flix-choice-wrapper__item:last-of-type:not(:only-of-type) .flix-radio__label,
.flix-choice-wrapper:not(.flix-choice-wrapper--column) .flix-choice-wrapper__item:last-of-type:not(:only-of-type) .flix-checkbox__label {
  border-radius: 0 var(--flix-primary-border-radius) var(--flix-primary-border-radius) 0;
}
.flix-choice-wrapper:not(.flix-choice-wrapper--column) .flix-choice-wrapper__item:not(:first-of-type):not(:last-of-type) {
  border-radius: 0;
  border-right-color: var(--flix-line-primary-color);
  border-left-color: var(--flix-line-primary-color);
}
.flix-choice-wrapper:not(.flix-choice-wrapper--column) .flix-choice-wrapper__item:not(:first-of-type):not(:last-of-type) .flix-radio__label,
.flix-choice-wrapper:not(.flix-choice-wrapper--column) .flix-choice-wrapper__item:not(:first-of-type):not(:last-of-type) .flix-checkbox__label {
  border-radius: 0;
}
.flix-choice-wrapper--column {
  flex-direction: column;
}
.flix-choice-wrapper--column .flix-choice-wrapper__item {
  flex-basis: auto;
  min-width: 100%;
  border-width: 0 1px 1px;
}
.flix-choice-wrapper--column .flix-choice-wrapper__item:first-of-type {
  border-top-width: 1px;
}
.flix-choice-wrapper--column .flix-choice-wrapper__item:first-of-type:not(:only-of-type) {
  border-radius: var(--flix-primary-border-radius) var(--flix-primary-border-radius) 0 0;
  border-bottom-color: var(--flix-line-primary-color);
}
.flix-choice-wrapper--column .flix-choice-wrapper__item:first-of-type:not(:only-of-type) .flix-radio__label,
.flix-choice-wrapper--column .flix-choice-wrapper__item:first-of-type:not(:only-of-type) .flix-checkbox__label {
  border-radius: var(--flix-primary-border-radius) var(--flix-primary-border-radius) 0 0;
}
.flix-choice-wrapper--column .flix-choice-wrapper__item:last-of-type:not(:only-of-type) {
  border-radius: 0 0 var(--flix-primary-border-radius) var(--flix-primary-border-radius);
  border-top-color: var(--flix-line-primary-color);
}
.flix-choice-wrapper--column .flix-choice-wrapper__item:last-of-type:not(:only-of-type) .flix-radio__label,
.flix-choice-wrapper--column .flix-choice-wrapper__item:last-of-type:not(:only-of-type) .flix-checkbox__label {
  border-radius: 0 0 var(--flix-primary-border-radius) var(--flix-primary-border-radius);
}
.flix-choice-wrapper--column .flix-choice-wrapper__item:not(:first-of-type):not(:last-of-type) {
  border-radius: 0;
  border-top-color: var(--flix-line-primary-color);
  border-bottom-color: var(--flix-line-primary-color);
}
.flix-choice-wrapper--column .flix-choice-wrapper__item:not(:first-of-type):not(:last-of-type) .flix-radio__label,
.flix-choice-wrapper--column .flix-choice-wrapper__item:not(:first-of-type):not(:last-of-type) .flix-checkbox__label {
  border-radius: 0;
}
.flix-choice-wrapper--column .flix-choice-wrapper__item:not(:first-of-type) {
  margin-top: 0;
}
.flix-choice-wrapper__content {
  display: none;
  margin: 0 var(--flix-spacing-2) var(--flix-spacing-2);
}
.flix-choice-wrapper__content--expanded {
  display: block;
}
.flix-choice-wrapper__info {
  flex-basis: 100%;
  flex-shrink: 0;
}

.flix-connection {
  --flix-connection-status-color: var(--flix-icon-primary-color);
}
.flix-connection__title {
  color: var(--flix-container-content-color, var(--flix-heading-font-color));
  font-size: var(--flix-font-size-h4);
  font-weight: var(--flix-font-weight-bold);
  line-height: var(--flix-line-height-h4);
  margin-bottom: var(--flix-spacing-1);
  text-align: left;
}
.flix-connection__title-arrival {
  font-weight: var(--flix-font-weight-normal);
}
.flix-connection__day {
  font-size: 0.65em;
  font-weight: var(--flix-font-weight-normal);
  line-height: 1;
  vertical-align: text-top;
}
.flix-connection__icon {
  display: none;
  position: absolute;
  z-index: 2;
  top: calc((var(--flix-line-height-primary) - var(--flix-spacing-5)) / 2);
  left: calc(-1 * (var(--flix-spacing-7) - var(--flix-spacing-2) - var(--flix-spacing-2) / 2) - var(--flix-spacing-5) / 2);
  width: var(--flix-spacing-5);
  height: var(--flix-spacing-5);
  padding: var(--flix-spacing-half);
  animation-name: connection-marker-pulsing-outline;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  border: 3px solid var(--flix-grayscale-0-color);
  border-radius: 100%;
  background-color: var(--flix-icon-primary-color);
  background-repeat: no-repeat;
  background-position: center;
  background-size: var(--flix-spacing-3);
  box-shadow: 0 0 0 1px var(--flix-icon-primary-color);
  color: var(--flix-grayscale-0-color);
}
@keyframes connection-marker-pulsing-outline {
  100% {
    box-shadow: 0 0 0 var(--flix-spacing-1) rgba(255, 255, 255, 0);
  }
}
.flix-connection__icon:only-child {
  top: calc(-1 * var(--flix-spacing-2));
}
.flix-connection__item {
  position: relative;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: var(--flix-spacing-2) 0 0 var(--flix-spacing-7);
}
.flix-connection__item:not([hidden]) {
  display: flex;
}
.flix-connection__item:first-child {
  margin: 0 0 0 var(--flix-spacing-7);
}
.flix-connection__item:first-child + .flix-connection__item:last-child {
  margin: 0 0 0 var(--flix-spacing-7);
}
.flix-connection__item:first-child::after {
  top: 0.75rem;
}
.flix-connection__item:last-child::after {
  bottom: calc(100% - 0.75rem);
}
.flix-connection__item::before {
  content: "";
  position: absolute;
  z-index: 2;
  top: calc(var(--flix-line-height-primary) / 2);
  left: calc(-1 * (var(--flix-spacing-7) - var(--flix-spacing-2) - var(--flix-spacing-2) / 2));
  width: var(--flix-spacing-1);
  height: 2px;
  background-color: var(--flix-icon-primary-color);
  vertical-align: middle;
}
.flix-connection__item:not(.flix-connection__item--current):first-child::before, .flix-connection__item:not(.flix-connection__item--current):last-child::before {
  display: inline-block;
  top: 0.75rem;
  left: calc(-1 * (var(--flix-spacing-7) - var(--flix-spacing-2)));
  width: var(--flix-spacing-2);
  height: var(--flix-spacing-2);
  margin-top: calc(-1 * var(--flix-spacing-1));
  border: 2px solid var(--flix-icon-primary-color);
  border-radius: 50%;
  background: var(--flix-bg-primary-color);
}
.flix-connection__item::after {
  content: "";
  display: block;
  position: absolute;
  z-index: 1;
  top: calc(-1 * var(--flix-line-height-primary) / 2);
  bottom: calc(-1 * var(--flix-line-height-primary) / 2);
  left: calc(-1 * (var(--flix-spacing-7) - var(--flix-spacing-2) - var(--flix-spacing-2) / 2) - 1px);
  width: 2px;
  background-color: var(--flix-icon-primary-color);
}
.flix-connection__item--slot:not(.flix-connection__item--current)::before {
  display: none;
}
.flix-connection__item--current .flix-connection__icon {
  display: initial;
  box-shadow: 0 0 0 1px var(--flix-connection-status-color);
}
@keyframes connection-marker-pulsing-outline {
  100% {
    box-shadow: 0 0 0 var(--flix-spacing-1) rgba(255, 255, 255, 0);
  }
}
.flix-connection__item--current::after {
  border-top: var(--flix-spacing-2) solid var(--flix-connection-status-color);
  background: linear-gradient(var(--flix-connection-status-color) 0 var(--flix-line-height-primary), var(--flix-icon-primary-color) var(--flix-line-height-primary) 100%);
}
.flix-connection__item::before, .flix-connection__item::after, .flix-connection__item--current:first-child::before, .flix-connection__item--current:last-child::before,
.flix-connection__item .flix-connection__icon {
  background-color: var(--flix-connection-status-color);
}
.flix-connection__item:not(.flix-connection__item--current):first-child::before, .flix-connection__item:not(.flix-connection__item--current):last-child::before {
  border-color: var(--flix-connection-status-color);
  background-color: var(--flix-bg-primary-color);
}
.flix-connection--success .flix-connection__item {
  --flix-connection-status-color: var(--flix-success-color);
}
.flix-connection--warning .flix-connection__item {
  --flix-connection-status-color: var(--flix-warning-color);
}
.flix-connection--danger .flix-connection__item {
  --flix-connection-status-color: var(--flix-danger-color);
}
.flix-connection .flix-connection__item--current ~ .flix-connection__item::before, .flix-connection .flix-connection__item--current ~ .flix-connection__item::after {
  background-color: var(--flix-icon-primary-color);
}
.flix-connection .flix-connection__item--current ~ .flix-connection__item:first-child::before, .flix-connection .flix-connection__item--current ~ .flix-connection__item:last-child::before {
  border-color: var(--flix-icon-primary-color);
  background-color: var(--flix-bg-primary-color);
}
.flix-connection__station {
  color: var(--flix-container-content-color, var(--flix-content-primary-color));
  font-size: var(--flix-font-size-primary);
  font-weight: var(--flix-font-weight-normal);
  line-height: var(--flix-line-height-primary);
  flex: 1;
}
.flix-connection__time {
  margin-left: var(--flix-spacing-2);
  text-align: right;
}
.flix-connection__time ins {
  text-decoration: none;
}
.flix-connection__time > time,
.flix-connection__time > ins,
.flix-connection__time > del {
  display: block;
}
.flix-connection__time del + ins {
  margin-left: var(--flix-spacing-1);
}
.flix-connection__content {
  width: 100%;
}

.flix-data-table {
  position: relative;
  width: 100%;
  border-spacing: 0;
  border-collapse: separate;
}
.flix-data-table caption {
  padding: var(--flix-spacing-2);
  caption-side: bottom;
  border-top: 1px solid var(--flix-line-primary-color);
  color: var(--flix-secondary-content-color);
  text-align: center;
}
.flix-data-table-controls {
  text-align: center;
}
.flix-data-table-controls .flix-data-table-wrapper {
  margin-bottom: var(--flix-spacing-3);
}
.flix-data-table-wrapper {
  max-width: 100%;
  height: 100%;
  border: 1px solid var(--flix-line-primary-color);
  border-radius: var(--flix-primary-border-radius);
  background-color: var(--flix-box-bg-color);
}
.flix-data-table-wrapper--responsive {
  overflow-x: auto;
}
@media (min-width: 600px) {
  .flix-data-table-wrapper--responsive {
    overflow-x: visible;
  }
}
.flix-data-table-wrapper--collapsed {
  display: inline-block;
}
.flix-data-table-wrapper--scrollable {
  overflow-y: auto;
}
.flix-data-table__header td,
.flix-data-table__header th {
  padding: var(--flix-spacing-2) var(--flix-spacing-2);
  border: solid var(--flix-line-primary-color);
  border-width: 0 0 1px;
  text-align: left;
  color: var(--flix-container-content-color, var(--flix-heading-font-color));
  font-size: var(--flix-font-size-h4);
  font-weight: var(--flix-font-weight-bold);
  line-height: var(--flix-line-height-h4);
  white-space: nowrap;
}
.flix-data-table__header-btn {
  padding: 0;
  font-size: var(--flix-font-size-primary);
  border: 0;
  background: transparent;
  font-family: var(--flix-font-family-primary);
  cursor: pointer;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  color: inherit;
}
.flix-data-table__footer td,
.flix-data-table__footer th {
  padding: var(--flix-spacing-2) var(--flix-spacing-2);
  border: solid var(--flix-line-primary-color);
  border-width: 0 0 1px;
  text-align: left;
  color: var(--flix-container-content-color, var(--flix-heading-font-color));
  font-size: var(--flix-font-size-h4);
  font-weight: var(--flix-font-weight-bold);
  line-height: var(--flix-line-height-h4);
  border-width: 1px 0 0;
}
.flix-data-table > tbody > tr {
  background-color: var(--flix-bg-primary-color);
}
.flix-data-table > tbody > tr:nth-of-type(odd) {
  background-color: var(--flix-grayscale-10-color);
}
.flix-data-table > tbody > tr td,
.flix-data-table > tbody > tr th {
  padding: var(--flix-spacing-2) var(--flix-spacing-2);
  border: solid var(--flix-line-primary-color);
  border-width: 0 0 1px;
  text-align: left;
  color: var(--flix-container-content-color, var(--flix-content-primary-color));
  font-size: var(--flix-font-size-primary);
  font-weight: var(--flix-font-weight-normal);
  line-height: var(--flix-line-height-primary);
}
.flix-data-table > tbody > tr:last-child > td,
.flix-data-table > tbody > tr:last-child > th {
  border-width: 0;
}
.flix-data-table > tbody > tr:last-child > td:first-child,
.flix-data-table > tbody > tr:last-child > th:first-child {
  border-bottom-left-radius: var(--flix-primary-border-radius);
}
.flix-data-table > tbody > tr:last-child > td:last-child,
.flix-data-table > tbody > tr:last-child > th:last-child {
  border-bottom-right-radius: var(--flix-primary-border-radius);
}
.flix-data-table > tbody > tr:hover {
  background-image: var(--flix-hover-layer-color);
  text-decoration: none;
}
.flix-data-table > tbody > tr.flix-data-table__row--selected {
  background-color: var(--flix-highlight-color);
}
.flix-data-table--plain > tbody > tr:nth-of-type(odd):not(.flix-data-table__row--selected) {
  background-color: var(--flix-bg-primary-color);
}
.flix-data-table--sticky-header .flix-data-table__header {
  position: -webkit-sticky;
  position: sticky;
  z-index: 3;
  top: 0;
  background-color: var(--flix-box-bg-color);
}
.flix-data-table--sticky-header .flix-data-table__header > tr > th, .flix-data-table--sticky-header .flix-data-table__header > tr > td {
  border-bottom: var(--flix-spacing-half) solid var(--flix-line-primary-color);
}
.flix-data-table--small caption {
  padding: var(--flix-spacing-1);
}
.flix-data-table--small .flix-data-table__header td,
.flix-data-table--small .flix-data-table__header th, .flix-data-table--small .flix-data-table__row td,
.flix-data-table--small .flix-data-table__row th, .flix-data-table--small .flix-data-table__footer td,
.flix-data-table--small .flix-data-table__footer th {
  padding: var(--flix-spacing-1);
}

.flix-divider {
  position: relative;
  height: 1px;
  border-style: none;
  background-color: var(--flix-line-primary-color);
}

.flix-dropdown {
  display: inline-block;
  position: relative;
}
.flix-dropdown__items {
  top: 100%;
  bottom: auto;
  left: 50%;
  transform: translateX(-50%);
  display: none;
  position: absolute;
  flex-direction: column;
  margin: calc(-1 * var(--flix-spacing-1)) 0 0;
  padding: var(--flix-spacing-4) 0 var(--flix-spacing-2);
}
.flix-dropdown-item, .flix-dropdown__item {
  list-style: none;
  text-align: left;
}
.flix-dropdown-item:not(:last-of-type), .flix-dropdown__item:not(:last-of-type) {
  margin-bottom: var(--flix-spacing-1);
}
.flix-dropdown-item__link, .flix-dropdown__link {
  color: var(--flix-content-secondary-color);
  font-size: var(--flix-font-size-primary);
  font-weight: var(--flix-font-weight-normal);
  line-height: var(--flix-line-height-primary);
  position: relative;
  width: -webkit-fill-available;
  width: -moz-available;
  width: stretch;
  padding: var(--flix-spacing-half) var(--flix-spacing-4);
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: var(--flix-spacing-1);
}
.flix-dropdown-item__link .flix-icon,
.flix-dropdown-item__link [class^=hci-icon], .flix-dropdown__link .flix-icon,
.flix-dropdown__link [class^=hci-icon] {
  line-height: 1;
}
.flix-dropdown-item__link .flix-icon::before,
.flix-dropdown-item__link [class^=hci-icon]::before, .flix-dropdown__link .flix-icon::before,
.flix-dropdown__link [class^=hci-icon]::before {
  margin: 0;
}
.flix-dropdown-item__link[aria-current], .flix-dropdown__link[aria-current] {
  position: relative;
  background-color: var(--flix-highlight-color);
  color: var(--flix-content-primary-color);
}
.flix-dropdown-item__link[aria-current]::before, .flix-dropdown__link[aria-current]::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  border-left: var(--flix-spacing-half) solid var(--flix-ui-primary-color);
}
.flix-dropdown-item__link:is(a, button), .flix-dropdown__link:is(a, button) {
  border: 0;
  background: transparent;
  font-family: var(--flix-font-family-primary);
  cursor: pointer;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  font-weight: var(--flix-font-weight-semibold);
}
.flix-dropdown-item__link:is(a, button):enabled:hover, .flix-dropdown-item__link:is(a, button)[href]:hover, .flix-dropdown__link:is(a, button):enabled:hover, .flix-dropdown__link:is(a, button)[href]:hover {
  background-image: var(--flix-hover-layer-color);
  text-decoration: none;
}
.flix-dropdown-item__link:is(a, button):enabled:active, .flix-dropdown__link:is(a, button):enabled:active {
  background-image: var(--flix-pressed-layer-color);
  text-decoration: none;
}
.flix-dropdown-item__link:is(a, button):focus, .flix-dropdown__link:is(a, button):focus {
  outline: 2px solid var(--flix-focus-outline-color);
  outline-offset: -2px;
  box-shadow: 0 0 0 calc(var(--flix-spacing-half) - 2px) var(--flix-grayscale-0-color);
  border-radius: var(--flix-primary-border-radius);
}
.flix-dropdown-item__link:is(a, button):focus::before, .flix-dropdown__link:is(a, button):focus::before {
  opacity: 0;
}
.flix-dropdown--top .flix-dropdown__items {
  top: auto;
  bottom: 100%;
  margin: 0 0 calc(-1 * var(--flix-spacing-1));
  padding: var(--flix-spacing-2) 0 var(--flix-spacing-4);
}
.flix-dropdown--left .flix-dropdown__items {
  right: calc(-1 * var(--flix-spacing-1));
  left: auto;
  transform: none;
}
.flix-dropdown--right .flix-dropdown__items {
  right: auto;
  left: calc(-1 * var(--flix-spacing-1));
  transform: none;
}
.flix-fieldset {
  margin: 0;
  padding: 0;
  border: 0;
}
.flix-fieldset:where(:not(:last-child)) {
  margin-bottom: var(--flix-spacing-4);
}
.flix-fieldset__item:where(:not(:first-of-type)) {
  margin-top: var(--flix-spacing-2);
}
.flix-fieldset__item--space-half {
  margin-top: var(--flix-spacing-half);
}
.flix-fieldset__item--space-1 {
  margin-top: var(--flix-spacing-1);
}
.flix-fieldset__item--space-2 {
  margin-top: var(--flix-spacing-2);
}
.flix-fieldset__item--space-3 {
  margin-top: var(--flix-spacing-3);
}
.flix-fieldset__item--space-4 {
  margin-top: var(--flix-spacing-4);
}
.flix-fieldset__item--space-5 {
  margin-top: var(--flix-spacing-5);
}
.flix-fieldset__item--space-6 {
  margin-top: var(--flix-spacing-6);
}
.flix-fieldset__item--space-7 {
  margin-top: var(--flix-spacing-7);
}
.flix-fieldset__item--space-8 {
  margin-top: var(--flix-spacing-8);
}
.flix-fieldset__item--space-9 {
  margin-top: var(--flix-spacing-9);
}
.flix-fieldset__item--space-10 {
  margin-top: var(--flix-spacing-10);
}
.flix-fieldset__item--space-11 {
  margin-top: var(--flix-spacing-11);
}
.flix-fieldset__item--space-12 {
  margin-top: var(--flix-spacing-12);
}
.flix-fieldset__item--space-flush {
  margin-top: 0;
}
.flix-fieldset--horizontal::after {
  content: "";
  display: table;
  clear: both;
}
.flix-fieldset--horizontal .flix-fieldset__item {
  margin-top: 0;
  float: left;
}
.flix-fieldset--horizontal .flix-fieldset__item:where(:not(:first-of-type)) {
  margin-left: var(--flix-spacing-4);
}
.flix-fieldset--horizontal .flix-fieldset__item--space-half {
  margin-left: var(--flix-spacing-half);
}
.flix-fieldset--horizontal .flix-fieldset__item--space-1 {
  margin-left: var(--flix-spacing-1);
}
.flix-fieldset--horizontal .flix-fieldset__item--space-2 {
  margin-left: var(--flix-spacing-2);
}
.flix-fieldset--horizontal .flix-fieldset__item--space-3 {
  margin-left: var(--flix-spacing-3);
}
.flix-fieldset--horizontal .flix-fieldset__item--space-4 {
  margin-left: var(--flix-spacing-4);
}
.flix-fieldset--horizontal .flix-fieldset__item--space-5 {
  margin-left: var(--flix-spacing-5);
}
.flix-fieldset--horizontal .flix-fieldset__item--space-6 {
  margin-left: var(--flix-spacing-6);
}
.flix-fieldset--horizontal .flix-fieldset__item--space-7 {
  margin-left: var(--flix-spacing-7);
}
.flix-fieldset--horizontal .flix-fieldset__item--space-8 {
  margin-left: var(--flix-spacing-8);
}
.flix-fieldset--horizontal .flix-fieldset__item--space-9 {
  margin-left: var(--flix-spacing-9);
}
.flix-fieldset--horizontal .flix-fieldset__item--space-10 {
  margin-left: var(--flix-spacing-10);
}
.flix-fieldset--horizontal .flix-fieldset__item--space-11 {
  margin-left: var(--flix-spacing-11);
}
.flix-fieldset--horizontal .flix-fieldset__item--space-12 {
  margin-left: var(--flix-spacing-12);
}
.flix-fieldset--horizontal .flix-fieldset__item--space-flush {
  margin-left: 0;
}
.flix-fieldset__info:not(:empty) {
  color: var(--flix-container-content-color, var(--flix-content-primary-color));
  font-size: var(--flix-font-size-small);
  font-weight: var(--flix-font-weight-normal);
  line-height: var(--flix-line-height-small);
  display: block;
  margin-top: var(--flix-spacing-1);
}
.flix-fieldset__info:not(:empty) ~ .flix-fieldset__info {
  margin-top: var(--flix-spacing-half);
}
.flix-fieldset--error .flix-fieldset__info {
  color: var(--flix-danger-dark-color);
}
.flix-fieldset__info ~ .flix-fieldset__info {
  color: var(--flix-content-primary-color);
}
.flix-fieldset__info {
  width: 100%;
  float: left;
  clear: both;
}

small.flix-fineprint {
  color: var(--flix-container-content-color, var(--flix-content-primary-color));
  font-size: var(--flix-font-size-fineprint);
  font-weight: var(--flix-font-weight-normal);
  line-height: var(--flix-line-height-fineprint);
  display: inline-block;
  margin: 0 0 var(--flix-spacing-2);
}
small.flix-fineprint--space-flush {
  margin-bottom: 0;
}

.flix-footer {
  position: -webkit-sticky;
  position: sticky;
  top: 100%;
  max-width: var(--flix-page-max-width);
  margin: 0 auto;
  padding: 0 var(--flix-spacing-2);
  overflow: hidden;
}
.flix-footer--fullwidth {
  max-width: none;
}
.flix-footer__illustration {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto;
  place-items: end stretch;
  margin-top: var(--flix-spacing-2);
}
.flix-footer__img {
  display: block;
  object-fit: cover;
  height: 100%;
  min-height: 150px;
}
.flix-footer__img--desktop {
  display: none;
  min-height: 207px;
}
@media (min-width: 600px) {
  .flix-footer__img {
    display: none;
  }
  .flix-footer__img--desktop {
    display: block;
  }
}

.flix-footer-nav {
  position: relative;
  border-bottom: 1px solid var(--flix-line-primary-color);
  font-family: inherit;
}
@media (min-width: 600px) {
  .flix-footer-nav {
    border-bottom: 0;
  }
}
.flix-footer-nav--first {
  border-top: 1px solid var(--flix-line-primary-color);
}
@media (min-width: 600px) {
  .flix-footer-nav--first {
    border-top: 0;
  }
}
.flix-footer-nav__items {
  display: flex;
  flex-direction: column;
  padding: 0 var(--flix-spacing-2) var(--flix-spacing-1);
  list-style: none;
  gap: var(--flix-spacing-1);
}
.flix-footer-nav__title ~ .flix-footer-nav__items:not(.flix-footer-nav__items--open) {
  display: none;
}
@media (min-width: 600px) {
  .flix-footer-nav__title ~ .flix-footer-nav__items:not(.flix-footer-nav__items--open) {
    display: flex;
  }
}

@media (min-width: 600px) {
  .flix-footer-nav__items {
    padding-right: 0;
    padding-left: 0;
  }
}
.flix-footer-nav--horizontal .flix-footer-nav__items {
  flex-direction: row;
  flex-wrap: wrap;
  gap: var(--flix-spacing-2);
}

.flix-footer-nav__items:first-child {
  padding-top: var(--flix-spacing-2);
}
.flix-footer-nav__items--open {
  display: flex;
}
.flix-footer-nav__link:is(button) {
  border: 0;
  background: transparent;
  font-family: var(--flix-font-family-primary);
  cursor: pointer;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}
.flix-footer-nav__link {
  color: var(--flix-content-primary-color);
  font-size: var(--flix-font-size-primary);
  font-weight: var(--flix-font-weight-semibold);
  line-height: var(--flix-line-height-primary);
  width: -webkit-fill-available;
  width: -moz-available;
  width: stretch;
  margin-left: calc(-1 * var(--flix-spacing-half));
  padding: var(--flix-spacing-half);
  border-radius: var(--flix-primary-border-radius);
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: var(--flix-spacing-1);
}
.flix-footer-nav__link .flix-icon,
.flix-footer-nav__link [class^=hci-icon] {
  line-height: 1;
}
.flix-footer-nav__link .flix-icon::before,
.flix-footer-nav__link [class^=hci-icon]::before {
  margin: 0;
}
.flix-footer-nav__link:enabled:hover, .flix-footer-nav__link[href]:hover {
  background-image: var(--flix-hover-layer-color);
  text-decoration: none;
}
.flix-footer-nav__link:enabled:active {
  background-image: var(--flix-pressed-layer-color);
  text-decoration: none;
}
.flix-footer-nav__link:focus {
  outline: 2px solid var(--flix-focus-outline-color);
  outline-offset: 1px;
  box-shadow: 0 0 0 calc(var(--flix-spacing-half) + 1px) var(--flix-grayscale-0-color);
}
.flix-footer-nav__item:first-child .flix-footer-nav__link {
  margin-top: calc(-1 * var(--flix-spacing-half));
}
.flix-footer-nav--horizontal .flix-footer-nav__item:first-child .flix-footer-nav__link {
  margin-top: 0;
}
.flix-footer-nav__title {
  color: var(--flix-container-content-color, var(--flix-heading-font-color));
  font-size: var(--flix-font-size-h4);
  font-weight: var(--flix-font-weight-bold);
  line-height: var(--flix-line-height-h4);
  position: relative;
  padding: var(--flix-spacing-2);
  display: flex;
  align-items: center;
  gap: var(--flix-spacing-1);
}
.flix-footer-nav__title .flix-icon,
.flix-footer-nav__title [class^=hci-icon] {
  line-height: 1;
}
.flix-footer-nav__title .flix-icon::before,
.flix-footer-nav__title [class^=hci-icon]::before {
  margin: 0;
}
.flix-footer-nav__title:enabled:hover, .flix-footer-nav__title[href]:hover {
  background-image: var(--flix-hover-layer-color);
  text-decoration: none;
}
.flix-footer-nav__title::after {
  content: "";
  -webkit-mask: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M18.68 8.3a1 1 0 00-1.41 0l-5.28 5.28-5.26-5.27a1 1 0 00-1.4 0 1 1 0 000 1.41l5.96 5.99a1 1 0 001.41 0l5.98-6a1 1 0 000-1.42z' /%3E%3C/svg%3E") no-repeat center/var(--flix-spacing-4);
          mask: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M18.68 8.3a1 1 0 00-1.41 0l-5.28 5.28-5.26-5.27a1 1 0 00-1.4 0 1 1 0 000 1.41l5.96 5.99a1 1 0 001.41 0l5.98-6a1 1 0 000-1.42z' /%3E%3C/svg%3E") no-repeat center/var(--flix-spacing-4);
  width: var(--flix-spacing-4);
  height: var(--flix-spacing-4);
  background-color: var(--flix-icon-primary-color);
  position: absolute;
  top: 50%;
  right: var(--flix-spacing-2);
  margin-top: -11px;
}
@media (min-width: 600px) {
  .flix-footer-nav__title {
    padding-right: 0;
    padding-left: 0;
  }
  .flix-footer-nav__title::after {
    display: none;
  }
  .flix-footer-nav__title:hover {
    background: transparent;
  }
}
.flix-footer-nav__title--open::after {
  transform: rotate(180deg);
}

.flix-form-row {
  display: block;
  position: relative;
  width: 100%;
  margin-bottom: var(--flix-spacing-2);
}
.flix-form-row--space-half {
  margin-bottom: var(--flix-spacing-half);
}
.flix-form-row--space-1 {
  margin-bottom: var(--flix-spacing-1);
}
.flix-form-row--space-2 {
  margin-bottom: var(--flix-spacing-2);
}
.flix-form-row--space-3 {
  margin-bottom: var(--flix-spacing-3);
}
.flix-form-row--space-4 {
  margin-bottom: var(--flix-spacing-4);
}
.flix-form-row--space-5 {
  margin-bottom: var(--flix-spacing-5);
}
.flix-form-row--space-6 {
  margin-bottom: var(--flix-spacing-6);
}
.flix-form-row--space-7 {
  margin-bottom: var(--flix-spacing-7);
}
.flix-form-row--space-8 {
  margin-bottom: var(--flix-spacing-8);
}
.flix-form-row--space-9 {
  margin-bottom: var(--flix-spacing-9);
}
.flix-form-row--space-10 {
  margin-bottom: var(--flix-spacing-10);
}
.flix-form-row--space-11 {
  margin-bottom: var(--flix-spacing-11);
}
.flix-form-row--space-12 {
  margin-bottom: var(--flix-spacing-12);
}
.flix-form-row--space-flush, .flix-form-row:last-child:not(:only-child) {
  margin-bottom: 0;
}
.flix-form-row--small {
  width: 50%;
}

.flix-grid-container {
  padding-right: calc(var(--flix-grid-gutter-2) * 0.5);
  padding-left: calc(var(--flix-grid-gutter-2) * 0.5);
}
.flix-grid-container--gutter-4 {
  padding-right: calc(var(--flix-grid-gutter-4) * 0.5);
  padding-left: calc(var(--flix-grid-gutter-4) * 0.5);
}
.flix-grid {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + var(--flix-grid-gutter-2));
  margin-right: calc(-1 * var(--flix-grid-gutter-2) * 0.5);
  margin-left: calc(-1 * var(--flix-grid-gutter-2) * 0.5);
}
.flix-grid--align-bottom {
  align-items: flex-end;
}
.flix-grid--align-top {
  align-items: flex-start;
}
.flix-grid--align-center {
  align-items: center;
}
.flix-grid--justify-center {
  justify-content: center;
}
.flix-grid--justify-left {
  justify-content: flex-start;
}
.flix-grid--justify-right {
  justify-content: flex-end;
}
.flix-grid--justify-space-between {
  justify-content: space-between;
}
.flix-grid--gutter-4 {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + var(--flix-grid-gutter-4));
  margin-right: calc(-1 * var(--flix-grid-gutter-4) * 0.5);
  margin-left: calc(-1 * var(--flix-grid-gutter-4) * 0.5);
}
.flix-col-1 {
  width: 8.3333333333%;
  padding-right: calc(var(--flix-grid-gutter-2) * 0.5);
  padding-left: calc(var(--flix-grid-gutter-2) * 0.5);
}
.flix-grid--gutter-4 .flix-col-1 {
  width: 8.3333333333%;
  padding-right: calc(var(--flix-grid-gutter-4) * 0.5);
  padding-left: calc(var(--flix-grid-gutter-4) * 0.5);
}
.flix-push-1 {
  margin-left: 8.3333333333%;
}
.flix-col-2 {
  width: 16.6666666667%;
  padding-right: calc(var(--flix-grid-gutter-2) * 0.5);
  padding-left: calc(var(--flix-grid-gutter-2) * 0.5);
}
.flix-grid--gutter-4 .flix-col-2 {
  width: 16.6666666667%;
  padding-right: calc(var(--flix-grid-gutter-4) * 0.5);
  padding-left: calc(var(--flix-grid-gutter-4) * 0.5);
}
.flix-push-2 {
  margin-left: 16.6666666667%;
}
.flix-col-3 {
  width: 25%;
  padding-right: calc(var(--flix-grid-gutter-2) * 0.5);
  padding-left: calc(var(--flix-grid-gutter-2) * 0.5);
}
.flix-grid--gutter-4 .flix-col-3 {
  width: 25%;
  padding-right: calc(var(--flix-grid-gutter-4) * 0.5);
  padding-left: calc(var(--flix-grid-gutter-4) * 0.5);
}
.flix-push-3 {
  margin-left: 25%;
}
.flix-col-4 {
  width: 33.3333333333%;
  padding-right: calc(var(--flix-grid-gutter-2) * 0.5);
  padding-left: calc(var(--flix-grid-gutter-2) * 0.5);
}
.flix-grid--gutter-4 .flix-col-4 {
  width: 33.3333333333%;
  padding-right: calc(var(--flix-grid-gutter-4) * 0.5);
  padding-left: calc(var(--flix-grid-gutter-4) * 0.5);
}
.flix-push-4 {
  margin-left: 33.3333333333%;
}
.flix-col-5 {
  width: 41.6666666667%;
  padding-right: calc(var(--flix-grid-gutter-2) * 0.5);
  padding-left: calc(var(--flix-grid-gutter-2) * 0.5);
}
.flix-grid--gutter-4 .flix-col-5 {
  width: 41.6666666667%;
  padding-right: calc(var(--flix-grid-gutter-4) * 0.5);
  padding-left: calc(var(--flix-grid-gutter-4) * 0.5);
}
.flix-push-5 {
  margin-left: 41.6666666667%;
}
.flix-col-6 {
  width: 50%;
  padding-right: calc(var(--flix-grid-gutter-2) * 0.5);
  padding-left: calc(var(--flix-grid-gutter-2) * 0.5);
}
.flix-grid--gutter-4 .flix-col-6 {
  width: 50%;
  padding-right: calc(var(--flix-grid-gutter-4) * 0.5);
  padding-left: calc(var(--flix-grid-gutter-4) * 0.5);
}
.flix-push-6 {
  margin-left: 50%;
}
.flix-col-7 {
  width: 58.3333333333%;
  padding-right: calc(var(--flix-grid-gutter-2) * 0.5);
  padding-left: calc(var(--flix-grid-gutter-2) * 0.5);
}
.flix-grid--gutter-4 .flix-col-7 {
  width: 58.3333333333%;
  padding-right: calc(var(--flix-grid-gutter-4) * 0.5);
  padding-left: calc(var(--flix-grid-gutter-4) * 0.5);
}
.flix-push-7 {
  margin-left: 58.3333333333%;
}
.flix-col-8 {
  width: 66.6666666667%;
  padding-right: calc(var(--flix-grid-gutter-2) * 0.5);
  padding-left: calc(var(--flix-grid-gutter-2) * 0.5);
}
.flix-grid--gutter-4 .flix-col-8 {
  width: 66.6666666667%;
  padding-right: calc(var(--flix-grid-gutter-4) * 0.5);
  padding-left: calc(var(--flix-grid-gutter-4) * 0.5);
}
.flix-push-8 {
  margin-left: 66.6666666667%;
}
.flix-col-9 {
  width: 75%;
  padding-right: calc(var(--flix-grid-gutter-2) * 0.5);
  padding-left: calc(var(--flix-grid-gutter-2) * 0.5);
}
.flix-grid--gutter-4 .flix-col-9 {
  width: 75%;
  padding-right: calc(var(--flix-grid-gutter-4) * 0.5);
  padding-left: calc(var(--flix-grid-gutter-4) * 0.5);
}
.flix-push-9 {
  margin-left: 75%;
}
.flix-col-10 {
  width: 83.3333333333%;
  padding-right: calc(var(--flix-grid-gutter-2) * 0.5);
  padding-left: calc(var(--flix-grid-gutter-2) * 0.5);
}
.flix-grid--gutter-4 .flix-col-10 {
  width: 83.3333333333%;
  padding-right: calc(var(--flix-grid-gutter-4) * 0.5);
  padding-left: calc(var(--flix-grid-gutter-4) * 0.5);
}
.flix-push-10 {
  margin-left: 83.3333333333%;
}
.flix-col-11 {
  width: 91.6666666667%;
  padding-right: calc(var(--flix-grid-gutter-2) * 0.5);
  padding-left: calc(var(--flix-grid-gutter-2) * 0.5);
}
.flix-grid--gutter-4 .flix-col-11 {
  width: 91.6666666667%;
  padding-right: calc(var(--flix-grid-gutter-4) * 0.5);
  padding-left: calc(var(--flix-grid-gutter-4) * 0.5);
}
.flix-push-11 {
  margin-left: 91.6666666667%;
}
.flix-col-12 {
  width: 100%;
  padding-right: calc(var(--flix-grid-gutter-2) * 0.5);
  padding-left: calc(var(--flix-grid-gutter-2) * 0.5);
}
.flix-grid--gutter-4 .flix-col-12 {
  width: 100%;
  padding-right: calc(var(--flix-grid-gutter-4) * 0.5);
  padding-left: calc(var(--flix-grid-gutter-4) * 0.5);
}
.flix-push-12 {
  margin-left: 100%;
}
@media (min-width: 390px) {
  .flix-col-1-xs {
    width: 8.3333333333%;
    padding-right: calc(var(--flix-grid-gutter-2) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-2) * 0.5);
  }
  .flix-grid--gutter-4 .flix-col-1-xs {
    width: 8.3333333333%;
    padding-right: calc(var(--flix-grid-gutter-4) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-4) * 0.5);
  }
  .flix-push-1-xs {
    margin-left: 8.3333333333%;
  }
  .flix-col-2-xs {
    width: 16.6666666667%;
    padding-right: calc(var(--flix-grid-gutter-2) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-2) * 0.5);
  }
  .flix-grid--gutter-4 .flix-col-2-xs {
    width: 16.6666666667%;
    padding-right: calc(var(--flix-grid-gutter-4) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-4) * 0.5);
  }
  .flix-push-2-xs {
    margin-left: 16.6666666667%;
  }
  .flix-col-3-xs {
    width: 25%;
    padding-right: calc(var(--flix-grid-gutter-2) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-2) * 0.5);
  }
  .flix-grid--gutter-4 .flix-col-3-xs {
    width: 25%;
    padding-right: calc(var(--flix-grid-gutter-4) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-4) * 0.5);
  }
  .flix-push-3-xs {
    margin-left: 25%;
  }
  .flix-col-4-xs {
    width: 33.3333333333%;
    padding-right: calc(var(--flix-grid-gutter-2) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-2) * 0.5);
  }
  .flix-grid--gutter-4 .flix-col-4-xs {
    width: 33.3333333333%;
    padding-right: calc(var(--flix-grid-gutter-4) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-4) * 0.5);
  }
  .flix-push-4-xs {
    margin-left: 33.3333333333%;
  }
  .flix-col-5-xs {
    width: 41.6666666667%;
    padding-right: calc(var(--flix-grid-gutter-2) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-2) * 0.5);
  }
  .flix-grid--gutter-4 .flix-col-5-xs {
    width: 41.6666666667%;
    padding-right: calc(var(--flix-grid-gutter-4) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-4) * 0.5);
  }
  .flix-push-5-xs {
    margin-left: 41.6666666667%;
  }
  .flix-col-6-xs {
    width: 50%;
    padding-right: calc(var(--flix-grid-gutter-2) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-2) * 0.5);
  }
  .flix-grid--gutter-4 .flix-col-6-xs {
    width: 50%;
    padding-right: calc(var(--flix-grid-gutter-4) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-4) * 0.5);
  }
  .flix-push-6-xs {
    margin-left: 50%;
  }
  .flix-col-7-xs {
    width: 58.3333333333%;
    padding-right: calc(var(--flix-grid-gutter-2) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-2) * 0.5);
  }
  .flix-grid--gutter-4 .flix-col-7-xs {
    width: 58.3333333333%;
    padding-right: calc(var(--flix-grid-gutter-4) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-4) * 0.5);
  }
  .flix-push-7-xs {
    margin-left: 58.3333333333%;
  }
  .flix-col-8-xs {
    width: 66.6666666667%;
    padding-right: calc(var(--flix-grid-gutter-2) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-2) * 0.5);
  }
  .flix-grid--gutter-4 .flix-col-8-xs {
    width: 66.6666666667%;
    padding-right: calc(var(--flix-grid-gutter-4) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-4) * 0.5);
  }
  .flix-push-8-xs {
    margin-left: 66.6666666667%;
  }
  .flix-col-9-xs {
    width: 75%;
    padding-right: calc(var(--flix-grid-gutter-2) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-2) * 0.5);
  }
  .flix-grid--gutter-4 .flix-col-9-xs {
    width: 75%;
    padding-right: calc(var(--flix-grid-gutter-4) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-4) * 0.5);
  }
  .flix-push-9-xs {
    margin-left: 75%;
  }
  .flix-col-10-xs {
    width: 83.3333333333%;
    padding-right: calc(var(--flix-grid-gutter-2) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-2) * 0.5);
  }
  .flix-grid--gutter-4 .flix-col-10-xs {
    width: 83.3333333333%;
    padding-right: calc(var(--flix-grid-gutter-4) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-4) * 0.5);
  }
  .flix-push-10-xs {
    margin-left: 83.3333333333%;
  }
  .flix-col-11-xs {
    width: 91.6666666667%;
    padding-right: calc(var(--flix-grid-gutter-2) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-2) * 0.5);
  }
  .flix-grid--gutter-4 .flix-col-11-xs {
    width: 91.6666666667%;
    padding-right: calc(var(--flix-grid-gutter-4) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-4) * 0.5);
  }
  .flix-push-11-xs {
    margin-left: 91.6666666667%;
  }
  .flix-col-12-xs {
    width: 100%;
    padding-right: calc(var(--flix-grid-gutter-2) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-2) * 0.5);
  }
  .flix-grid--gutter-4 .flix-col-12-xs {
    width: 100%;
    padding-right: calc(var(--flix-grid-gutter-4) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-4) * 0.5);
  }
  .flix-push-12-xs {
    margin-left: 100%;
  }
}
@media (min-width: 600px) {
  .flix-col-1-sm {
    width: 8.3333333333%;
    padding-right: calc(var(--flix-grid-gutter-2) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-2) * 0.5);
  }
  .flix-grid--gutter-4 .flix-col-1-sm {
    width: 8.3333333333%;
    padding-right: calc(var(--flix-grid-gutter-4) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-4) * 0.5);
  }
  .flix-push-1-sm {
    margin-left: 8.3333333333%;
  }
  .flix-col-2-sm {
    width: 16.6666666667%;
    padding-right: calc(var(--flix-grid-gutter-2) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-2) * 0.5);
  }
  .flix-grid--gutter-4 .flix-col-2-sm {
    width: 16.6666666667%;
    padding-right: calc(var(--flix-grid-gutter-4) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-4) * 0.5);
  }
  .flix-push-2-sm {
    margin-left: 16.6666666667%;
  }
  .flix-col-3-sm {
    width: 25%;
    padding-right: calc(var(--flix-grid-gutter-2) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-2) * 0.5);
  }
  .flix-grid--gutter-4 .flix-col-3-sm {
    width: 25%;
    padding-right: calc(var(--flix-grid-gutter-4) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-4) * 0.5);
  }
  .flix-push-3-sm {
    margin-left: 25%;
  }
  .flix-col-4-sm {
    width: 33.3333333333%;
    padding-right: calc(var(--flix-grid-gutter-2) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-2) * 0.5);
  }
  .flix-grid--gutter-4 .flix-col-4-sm {
    width: 33.3333333333%;
    padding-right: calc(var(--flix-grid-gutter-4) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-4) * 0.5);
  }
  .flix-push-4-sm {
    margin-left: 33.3333333333%;
  }
  .flix-col-5-sm {
    width: 41.6666666667%;
    padding-right: calc(var(--flix-grid-gutter-2) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-2) * 0.5);
  }
  .flix-grid--gutter-4 .flix-col-5-sm {
    width: 41.6666666667%;
    padding-right: calc(var(--flix-grid-gutter-4) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-4) * 0.5);
  }
  .flix-push-5-sm {
    margin-left: 41.6666666667%;
  }
  .flix-col-6-sm {
    width: 50%;
    padding-right: calc(var(--flix-grid-gutter-2) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-2) * 0.5);
  }
  .flix-grid--gutter-4 .flix-col-6-sm {
    width: 50%;
    padding-right: calc(var(--flix-grid-gutter-4) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-4) * 0.5);
  }
  .flix-push-6-sm {
    margin-left: 50%;
  }
  .flix-col-7-sm {
    width: 58.3333333333%;
    padding-right: calc(var(--flix-grid-gutter-2) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-2) * 0.5);
  }
  .flix-grid--gutter-4 .flix-col-7-sm {
    width: 58.3333333333%;
    padding-right: calc(var(--flix-grid-gutter-4) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-4) * 0.5);
  }
  .flix-push-7-sm {
    margin-left: 58.3333333333%;
  }
  .flix-col-8-sm {
    width: 66.6666666667%;
    padding-right: calc(var(--flix-grid-gutter-2) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-2) * 0.5);
  }
  .flix-grid--gutter-4 .flix-col-8-sm {
    width: 66.6666666667%;
    padding-right: calc(var(--flix-grid-gutter-4) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-4) * 0.5);
  }
  .flix-push-8-sm {
    margin-left: 66.6666666667%;
  }
  .flix-col-9-sm {
    width: 75%;
    padding-right: calc(var(--flix-grid-gutter-2) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-2) * 0.5);
  }
  .flix-grid--gutter-4 .flix-col-9-sm {
    width: 75%;
    padding-right: calc(var(--flix-grid-gutter-4) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-4) * 0.5);
  }
  .flix-push-9-sm {
    margin-left: 75%;
  }
  .flix-col-10-sm {
    width: 83.3333333333%;
    padding-right: calc(var(--flix-grid-gutter-2) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-2) * 0.5);
  }
  .flix-grid--gutter-4 .flix-col-10-sm {
    width: 83.3333333333%;
    padding-right: calc(var(--flix-grid-gutter-4) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-4) * 0.5);
  }
  .flix-push-10-sm {
    margin-left: 83.3333333333%;
  }
  .flix-col-11-sm {
    width: 91.6666666667%;
    padding-right: calc(var(--flix-grid-gutter-2) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-2) * 0.5);
  }
  .flix-grid--gutter-4 .flix-col-11-sm {
    width: 91.6666666667%;
    padding-right: calc(var(--flix-grid-gutter-4) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-4) * 0.5);
  }
  .flix-push-11-sm {
    margin-left: 91.6666666667%;
  }
  .flix-col-12-sm {
    width: 100%;
    padding-right: calc(var(--flix-grid-gutter-2) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-2) * 0.5);
  }
  .flix-grid--gutter-4 .flix-col-12-sm {
    width: 100%;
    padding-right: calc(var(--flix-grid-gutter-4) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-4) * 0.5);
  }
  .flix-push-12-sm {
    margin-left: 100%;
  }
}
@media (min-width: 768px) {
  .flix-col-1-md {
    width: 8.3333333333%;
    padding-right: calc(var(--flix-grid-gutter-2) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-2) * 0.5);
  }
  .flix-grid--gutter-4 .flix-col-1-md {
    width: 8.3333333333%;
    padding-right: calc(var(--flix-grid-gutter-4) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-4) * 0.5);
  }
  .flix-push-1-md {
    margin-left: 8.3333333333%;
  }
  .flix-col-2-md {
    width: 16.6666666667%;
    padding-right: calc(var(--flix-grid-gutter-2) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-2) * 0.5);
  }
  .flix-grid--gutter-4 .flix-col-2-md {
    width: 16.6666666667%;
    padding-right: calc(var(--flix-grid-gutter-4) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-4) * 0.5);
  }
  .flix-push-2-md {
    margin-left: 16.6666666667%;
  }
  .flix-col-3-md {
    width: 25%;
    padding-right: calc(var(--flix-grid-gutter-2) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-2) * 0.5);
  }
  .flix-grid--gutter-4 .flix-col-3-md {
    width: 25%;
    padding-right: calc(var(--flix-grid-gutter-4) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-4) * 0.5);
  }
  .flix-push-3-md {
    margin-left: 25%;
  }
  .flix-col-4-md {
    width: 33.3333333333%;
    padding-right: calc(var(--flix-grid-gutter-2) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-2) * 0.5);
  }
  .flix-grid--gutter-4 .flix-col-4-md {
    width: 33.3333333333%;
    padding-right: calc(var(--flix-grid-gutter-4) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-4) * 0.5);
  }
  .flix-push-4-md {
    margin-left: 33.3333333333%;
  }
  .flix-col-5-md {
    width: 41.6666666667%;
    padding-right: calc(var(--flix-grid-gutter-2) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-2) * 0.5);
  }
  .flix-grid--gutter-4 .flix-col-5-md {
    width: 41.6666666667%;
    padding-right: calc(var(--flix-grid-gutter-4) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-4) * 0.5);
  }
  .flix-push-5-md {
    margin-left: 41.6666666667%;
  }
  .flix-col-6-md {
    width: 50%;
    padding-right: calc(var(--flix-grid-gutter-2) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-2) * 0.5);
  }
  .flix-grid--gutter-4 .flix-col-6-md {
    width: 50%;
    padding-right: calc(var(--flix-grid-gutter-4) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-4) * 0.5);
  }
  .flix-push-6-md {
    margin-left: 50%;
  }
  .flix-col-7-md {
    width: 58.3333333333%;
    padding-right: calc(var(--flix-grid-gutter-2) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-2) * 0.5);
  }
  .flix-grid--gutter-4 .flix-col-7-md {
    width: 58.3333333333%;
    padding-right: calc(var(--flix-grid-gutter-4) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-4) * 0.5);
  }
  .flix-push-7-md {
    margin-left: 58.3333333333%;
  }
  .flix-col-8-md {
    width: 66.6666666667%;
    padding-right: calc(var(--flix-grid-gutter-2) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-2) * 0.5);
  }
  .flix-grid--gutter-4 .flix-col-8-md {
    width: 66.6666666667%;
    padding-right: calc(var(--flix-grid-gutter-4) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-4) * 0.5);
  }
  .flix-push-8-md {
    margin-left: 66.6666666667%;
  }
  .flix-col-9-md {
    width: 75%;
    padding-right: calc(var(--flix-grid-gutter-2) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-2) * 0.5);
  }
  .flix-grid--gutter-4 .flix-col-9-md {
    width: 75%;
    padding-right: calc(var(--flix-grid-gutter-4) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-4) * 0.5);
  }
  .flix-push-9-md {
    margin-left: 75%;
  }
  .flix-col-10-md {
    width: 83.3333333333%;
    padding-right: calc(var(--flix-grid-gutter-2) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-2) * 0.5);
  }
  .flix-grid--gutter-4 .flix-col-10-md {
    width: 83.3333333333%;
    padding-right: calc(var(--flix-grid-gutter-4) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-4) * 0.5);
  }
  .flix-push-10-md {
    margin-left: 83.3333333333%;
  }
  .flix-col-11-md {
    width: 91.6666666667%;
    padding-right: calc(var(--flix-grid-gutter-2) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-2) * 0.5);
  }
  .flix-grid--gutter-4 .flix-col-11-md {
    width: 91.6666666667%;
    padding-right: calc(var(--flix-grid-gutter-4) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-4) * 0.5);
  }
  .flix-push-11-md {
    margin-left: 91.6666666667%;
  }
  .flix-col-12-md {
    width: 100%;
    padding-right: calc(var(--flix-grid-gutter-2) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-2) * 0.5);
  }
  .flix-grid--gutter-4 .flix-col-12-md {
    width: 100%;
    padding-right: calc(var(--flix-grid-gutter-4) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-4) * 0.5);
  }
  .flix-push-12-md {
    margin-left: 100%;
  }
}
@media (min-width: 1024px) {
  .flix-col-1-lg {
    width: 8.3333333333%;
    padding-right: calc(var(--flix-grid-gutter-2) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-2) * 0.5);
  }
  .flix-grid--gutter-4 .flix-col-1-lg {
    width: 8.3333333333%;
    padding-right: calc(var(--flix-grid-gutter-4) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-4) * 0.5);
  }
  .flix-push-1-lg {
    margin-left: 8.3333333333%;
  }
  .flix-col-2-lg {
    width: 16.6666666667%;
    padding-right: calc(var(--flix-grid-gutter-2) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-2) * 0.5);
  }
  .flix-grid--gutter-4 .flix-col-2-lg {
    width: 16.6666666667%;
    padding-right: calc(var(--flix-grid-gutter-4) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-4) * 0.5);
  }
  .flix-push-2-lg {
    margin-left: 16.6666666667%;
  }
  .flix-col-3-lg {
    width: 25%;
    padding-right: calc(var(--flix-grid-gutter-2) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-2) * 0.5);
  }
  .flix-grid--gutter-4 .flix-col-3-lg {
    width: 25%;
    padding-right: calc(var(--flix-grid-gutter-4) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-4) * 0.5);
  }
  .flix-push-3-lg {
    margin-left: 25%;
  }
  .flix-col-4-lg {
    width: 33.3333333333%;
    padding-right: calc(var(--flix-grid-gutter-2) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-2) * 0.5);
  }
  .flix-grid--gutter-4 .flix-col-4-lg {
    width: 33.3333333333%;
    padding-right: calc(var(--flix-grid-gutter-4) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-4) * 0.5);
  }
  .flix-push-4-lg {
    margin-left: 33.3333333333%;
  }
  .flix-col-5-lg {
    width: 41.6666666667%;
    padding-right: calc(var(--flix-grid-gutter-2) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-2) * 0.5);
  }
  .flix-grid--gutter-4 .flix-col-5-lg {
    width: 41.6666666667%;
    padding-right: calc(var(--flix-grid-gutter-4) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-4) * 0.5);
  }
  .flix-push-5-lg {
    margin-left: 41.6666666667%;
  }
  .flix-col-6-lg {
    width: 50%;
    padding-right: calc(var(--flix-grid-gutter-2) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-2) * 0.5);
  }
  .flix-grid--gutter-4 .flix-col-6-lg {
    width: 50%;
    padding-right: calc(var(--flix-grid-gutter-4) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-4) * 0.5);
  }
  .flix-push-6-lg {
    margin-left: 50%;
  }
  .flix-col-7-lg {
    width: 58.3333333333%;
    padding-right: calc(var(--flix-grid-gutter-2) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-2) * 0.5);
  }
  .flix-grid--gutter-4 .flix-col-7-lg {
    width: 58.3333333333%;
    padding-right: calc(var(--flix-grid-gutter-4) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-4) * 0.5);
  }
  .flix-push-7-lg {
    margin-left: 58.3333333333%;
  }
  .flix-col-8-lg {
    width: 66.6666666667%;
    padding-right: calc(var(--flix-grid-gutter-2) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-2) * 0.5);
  }
  .flix-grid--gutter-4 .flix-col-8-lg {
    width: 66.6666666667%;
    padding-right: calc(var(--flix-grid-gutter-4) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-4) * 0.5);
  }
  .flix-push-8-lg {
    margin-left: 66.6666666667%;
  }
  .flix-col-9-lg {
    width: 75%;
    padding-right: calc(var(--flix-grid-gutter-2) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-2) * 0.5);
  }
  .flix-grid--gutter-4 .flix-col-9-lg {
    width: 75%;
    padding-right: calc(var(--flix-grid-gutter-4) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-4) * 0.5);
  }
  .flix-push-9-lg {
    margin-left: 75%;
  }
  .flix-col-10-lg {
    width: 83.3333333333%;
    padding-right: calc(var(--flix-grid-gutter-2) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-2) * 0.5);
  }
  .flix-grid--gutter-4 .flix-col-10-lg {
    width: 83.3333333333%;
    padding-right: calc(var(--flix-grid-gutter-4) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-4) * 0.5);
  }
  .flix-push-10-lg {
    margin-left: 83.3333333333%;
  }
  .flix-col-11-lg {
    width: 91.6666666667%;
    padding-right: calc(var(--flix-grid-gutter-2) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-2) * 0.5);
  }
  .flix-grid--gutter-4 .flix-col-11-lg {
    width: 91.6666666667%;
    padding-right: calc(var(--flix-grid-gutter-4) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-4) * 0.5);
  }
  .flix-push-11-lg {
    margin-left: 91.6666666667%;
  }
  .flix-col-12-lg {
    width: 100%;
    padding-right: calc(var(--flix-grid-gutter-2) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-2) * 0.5);
  }
  .flix-grid--gutter-4 .flix-col-12-lg {
    width: 100%;
    padding-right: calc(var(--flix-grid-gutter-4) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-4) * 0.5);
  }
  .flix-push-12-lg {
    margin-left: 100%;
  }
}
@media (min-width: 1200px) {
  .flix-col-1-xl {
    width: 8.3333333333%;
    padding-right: calc(var(--flix-grid-gutter-2) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-2) * 0.5);
  }
  .flix-grid--gutter-4 .flix-col-1-xl {
    width: 8.3333333333%;
    padding-right: calc(var(--flix-grid-gutter-4) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-4) * 0.5);
  }
  .flix-push-1-xl {
    margin-left: 8.3333333333%;
  }
  .flix-col-2-xl {
    width: 16.6666666667%;
    padding-right: calc(var(--flix-grid-gutter-2) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-2) * 0.5);
  }
  .flix-grid--gutter-4 .flix-col-2-xl {
    width: 16.6666666667%;
    padding-right: calc(var(--flix-grid-gutter-4) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-4) * 0.5);
  }
  .flix-push-2-xl {
    margin-left: 16.6666666667%;
  }
  .flix-col-3-xl {
    width: 25%;
    padding-right: calc(var(--flix-grid-gutter-2) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-2) * 0.5);
  }
  .flix-grid--gutter-4 .flix-col-3-xl {
    width: 25%;
    padding-right: calc(var(--flix-grid-gutter-4) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-4) * 0.5);
  }
  .flix-push-3-xl {
    margin-left: 25%;
  }
  .flix-col-4-xl {
    width: 33.3333333333%;
    padding-right: calc(var(--flix-grid-gutter-2) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-2) * 0.5);
  }
  .flix-grid--gutter-4 .flix-col-4-xl {
    width: 33.3333333333%;
    padding-right: calc(var(--flix-grid-gutter-4) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-4) * 0.5);
  }
  .flix-push-4-xl {
    margin-left: 33.3333333333%;
  }
  .flix-col-5-xl {
    width: 41.6666666667%;
    padding-right: calc(var(--flix-grid-gutter-2) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-2) * 0.5);
  }
  .flix-grid--gutter-4 .flix-col-5-xl {
    width: 41.6666666667%;
    padding-right: calc(var(--flix-grid-gutter-4) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-4) * 0.5);
  }
  .flix-push-5-xl {
    margin-left: 41.6666666667%;
  }
  .flix-col-6-xl {
    width: 50%;
    padding-right: calc(var(--flix-grid-gutter-2) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-2) * 0.5);
  }
  .flix-grid--gutter-4 .flix-col-6-xl {
    width: 50%;
    padding-right: calc(var(--flix-grid-gutter-4) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-4) * 0.5);
  }
  .flix-push-6-xl {
    margin-left: 50%;
  }
  .flix-col-7-xl {
    width: 58.3333333333%;
    padding-right: calc(var(--flix-grid-gutter-2) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-2) * 0.5);
  }
  .flix-grid--gutter-4 .flix-col-7-xl {
    width: 58.3333333333%;
    padding-right: calc(var(--flix-grid-gutter-4) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-4) * 0.5);
  }
  .flix-push-7-xl {
    margin-left: 58.3333333333%;
  }
  .flix-col-8-xl {
    width: 66.6666666667%;
    padding-right: calc(var(--flix-grid-gutter-2) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-2) * 0.5);
  }
  .flix-grid--gutter-4 .flix-col-8-xl {
    width: 66.6666666667%;
    padding-right: calc(var(--flix-grid-gutter-4) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-4) * 0.5);
  }
  .flix-push-8-xl {
    margin-left: 66.6666666667%;
  }
  .flix-col-9-xl {
    width: 75%;
    padding-right: calc(var(--flix-grid-gutter-2) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-2) * 0.5);
  }
  .flix-grid--gutter-4 .flix-col-9-xl {
    width: 75%;
    padding-right: calc(var(--flix-grid-gutter-4) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-4) * 0.5);
  }
  .flix-push-9-xl {
    margin-left: 75%;
  }
  .flix-col-10-xl {
    width: 83.3333333333%;
    padding-right: calc(var(--flix-grid-gutter-2) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-2) * 0.5);
  }
  .flix-grid--gutter-4 .flix-col-10-xl {
    width: 83.3333333333%;
    padding-right: calc(var(--flix-grid-gutter-4) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-4) * 0.5);
  }
  .flix-push-10-xl {
    margin-left: 83.3333333333%;
  }
  .flix-col-11-xl {
    width: 91.6666666667%;
    padding-right: calc(var(--flix-grid-gutter-2) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-2) * 0.5);
  }
  .flix-grid--gutter-4 .flix-col-11-xl {
    width: 91.6666666667%;
    padding-right: calc(var(--flix-grid-gutter-4) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-4) * 0.5);
  }
  .flix-push-11-xl {
    margin-left: 91.6666666667%;
  }
  .flix-col-12-xl {
    width: 100%;
    padding-right: calc(var(--flix-grid-gutter-2) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-2) * 0.5);
  }
  .flix-grid--gutter-4 .flix-col-12-xl {
    width: 100%;
    padding-right: calc(var(--flix-grid-gutter-4) * 0.5);
    padding-left: calc(var(--flix-grid-gutter-4) * 0.5);
  }
  .flix-push-12-xl {
    margin-left: 100%;
  }
}
.flix-col {
  flex: 1;
  margin: 0 calc(var(--flix-grid-gutter-2) * 0.5);
}
.flix-col--inline {
  flex-basis: auto;
  flex-grow: 0;
}
.flix-grid--gutter-4 .flix-col {
  margin: 0 calc(var(--flix-grid-gutter-4) * 0.5);
}

.flix-header-bar {
  --flix-container-content-color: var(--flix-header-color);
  z-index: 1000;
  background: var(--flix-header-bg-color);
}
.flix-header-bar--fixed {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
}
.flix-header-bar__content {
  display: flex;
  position: relative;
  align-items: center;
  width: 100%;
  min-width: var(--flix-page-min-width);
  max-width: var(--flix-page-max-width);
  min-height: var(--flix-spacing-10);
  margin: 0 auto;
  padding: 0 var(--flix-spacing-2);
}
.flix-header-bar--fullwidth .flix-header-bar__content {
  max-width: none;
}

.flix-header-brand {
  display: flex;
  grid-column: 1/span 12;
  grid-row: 1;
  align-items: center;
  align-self: center;
  justify-content: center;
  height: var(--flix-spacing-10);
}
@media (min-width: 1200px) {
  .flix-header-brand {
    grid-column: 1/span 2;
    justify-content: flex-start;
  }
}
.flix-header-brand__link {
  display: block;
  width: -moz-fit-content;
  width: fit-content;
  padding: var(--flix-spacing-1);
}
.flix-header-brand__link:focus {
  outline: 2px solid var(--flix-focus-outline-color);
  outline-offset: 1px;
  box-shadow: 0 0 0 calc(var(--flix-spacing-half) + 1px) var(--flix-grayscale-0-color);
  border-radius: var(--flix-primary-border-radius);
}
.flix-header-brand__img {
  display: block;
  width: auto;
  height: var(--flix-spacing-4);
}
.flix-header-brand--tall .flix-header-brand__img {
  height: var(--flix-spacing-6);
}
.flix-header-brand--square .flix-header-brand__img {
  width: var(--flix-spacing-6);
  height: var(--flix-spacing-6);
}

.flix-header-burger-menu {
  grid-column: 1/span 2;
  grid-row: 1;
  align-self: center;
}
@media (min-width: 1200px) {
  .flix-header-burger-menu {
    position: absolute;
    left: var(--flix-spacing-2);
  }
}
.flix-header-burger-menu__overlay {
  display: none;
}
.flix-header-burger-menu > .flix-header-burger-menu__toggle::after {
  -webkit-mask: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M2 5a1 1 0 011-1h18a1 1 0 010 2H3a1 1 0 01-1-1zm19 6H3a1 1 0 000 2h18a1 1 0 000-2zm0 7H3a1 1 0 000 2h18a1 1 0 000-2z' /%3E%3C/svg%3E") no-repeat center/var(--flix-spacing-4);
          mask: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M2 5a1 1 0 011-1h18a1 1 0 010 2H3a1 1 0 01-1-1zm19 6H3a1 1 0 000 2h18a1 1 0 000-2zm0 7H3a1 1 0 000 2h18a1 1 0 000-2z' /%3E%3C/svg%3E") no-repeat center/var(--flix-spacing-4);
  width: var(--flix-spacing-4);
  height: var(--flix-spacing-4);
  background-color: var(--flix-header-color);
  content: "";
}
.flix-header-burger-menu__panel {
  position: fixed;
  z-index: 1000;
  top: 0;
  bottom: 0;
  left: -23.75em;
  width: 85%;
  max-width: 23.75em;
  overflow-y: auto;
  border-radius: 0 var(--flix-primary-border-radius) var(--flix-primary-border-radius) 0;
  background: var(--flix-box-bg-color);
  color: var(--flix-content-secondary-color);
  -webkit-overflow-scrolling: touch;
  transition: left 250ms ease;
}
.flix-header-burger-menu__panel > .flix-header-burger-menu__toggle {
  margin: var(--flix-spacing-2);
}
.flix-header-burger-menu__panel > .flix-header-burger-menu__toggle::before {
  content: "";
  -webkit-mask: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M13.41 12l6.37-6.36a1 1 0 10-1.42-1.42L12 10.6 5.64 4.22a1 1 0 10-1.42 1.42L10.6 12l-6.37 6.36a1 1 0 101.42 1.42L12 13.4l6.36 6.37a1 1 0 101.42-1.42L13.4 12z' /%3E%3C/svg%3E") no-repeat center/1.5rem;
          mask: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M13.41 12l6.37-6.36a1 1 0 10-1.42-1.42L12 10.6 5.64 4.22a1 1 0 10-1.42 1.42L10.6 12l-6.37 6.36a1 1 0 101.42 1.42L12 13.4l6.36 6.37a1 1 0 101.42-1.42L13.4 12z' /%3E%3C/svg%3E") no-repeat center/1.5rem;
  width: 1.5rem;
  height: 1.5rem;
  background-color: var(--flix-icon-primary-color);
  min-width: var(--flix-spacing-4);
  max-width: calc(var(--flix-spacing-4) * 2);
  min-height: var(--flix-spacing-4);
  max-height: calc(var(--flix-spacing-4) * 2);
}
.flix-header-burger-menu__toggle[aria-expanded=true] + .flix-header-burger-menu__panel:not([hidden]) {
  left: 0;
}
.flix-header-burger-menu__toggle[aria-expanded=true] ~ .flix-header-burger-menu__overlay {
  display: block;
  z-index: 999;
}
.flix-header-burger-menu__nav {
  position: initial;
  width: 100%;
}
.flix-header-burger-menu__list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.flix-header-burger-menu__item {
  border-top: 1px solid var(--flix-line-primary-color);
}
.flix-header-burger-menu__link:is(button) {
  font-size: var(--flix-font-size-primary);
  border: 0;
  background: transparent;
  font-family: var(--flix-font-family-primary);
  cursor: pointer;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}
.flix-header-burger-menu__link {
  width: 100%;
  padding: var(--flix-spacing-3) var(--flix-spacing-4);
  color: var(--flix-content-secondary-color);
  font-size: var(--flix-font-size-primary);
  font-weight: var(--flix-font-weight-semibold);
  line-height: var(--flix-line-height-primary);
  text-align: left;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: var(--flix-spacing-1);
}
.flix-header-burger-menu__link .flix-icon,
.flix-header-burger-menu__link [class^=hci-icon] {
  line-height: 1;
}
.flix-header-burger-menu__link .flix-icon::before,
.flix-header-burger-menu__link [class^=hci-icon]::before {
  margin: 0;
}
.flix-header-burger-menu__link[aria-expanded]::after {
  content: "";
  -webkit-mask: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M18.68 8.3a1 1 0 00-1.41 0l-5.28 5.28-5.26-5.27a1 1 0 00-1.4 0 1 1 0 000 1.41l5.96 5.99a1 1 0 001.41 0l5.98-6a1 1 0 000-1.42z' /%3E%3C/svg%3E") no-repeat center/var(--flix-spacing-4);
          mask: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M18.68 8.3a1 1 0 00-1.41 0l-5.28 5.28-5.26-5.27a1 1 0 00-1.4 0 1 1 0 000 1.41l5.96 5.99a1 1 0 001.41 0l5.98-6a1 1 0 000-1.42z' /%3E%3C/svg%3E") no-repeat center/var(--flix-spacing-4);
  width: var(--flix-spacing-4);
  height: var(--flix-spacing-4);
  background-color: var(--flix-header-color);
}
.flix-header-burger-menu__link[aria-expanded=true]::after {
  transform: rotate(180deg);
}
.flix-header-burger-menu__link[aria-expanded=true] + .flix-header-burger-menu-subnav:not([hidden]),
.flix-header-burger-menu__link[aria-expanded=true] + .flix-header-nav-subnav:not([hidden]) {
  display: block;
}
.flix-header-burger-menu__link[aria-expanded]::after {
  background-color: var(--flix-icon-primary-color);
}
.flix-header-burger-menu__link[aria-current] {
  position: relative;
  background-color: var(--flix-highlight-color);
  color: var(--flix-content-primary-color);
}
.flix-header-burger-menu__link[aria-current]::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  border-left: var(--flix-spacing-half) solid var(--flix-ui-primary-color);
}
.flix-header-burger-menu__link:hover {
  background-image: var(--flix-hover-layer-color);
  text-decoration: none;
}
.flix-header-burger-menu__link:active {
  background-image: var(--flix-pressed-layer-color);
  text-decoration: none;
}
.flix-header-burger-menu__link:focus {
  outline: 2px solid var(--flix-focus-outline-color);
  outline-offset: -2px;
  box-shadow: 0 0 0 calc(var(--flix-spacing-half) - 2px) var(--flix-grayscale-0-color);
  border-radius: var(--flix-primary-border-radius);
}
.flix-header-burger-menu__link:focus::before {
  opacity: 0;
}
.flix-header-burger-menu__text {
  display: block;
  width: 100%;
}
.flix-header-burger-menu__widgets--has-container {
  padding: var(--flix-spacing-2) var(--flix-spacing-4);
  border-top: 1px solid var(--flix-line-primary-color);
}

.flix-header-burger-menu-subnav {
  display: none;
  padding: 0 var(--flix-spacing-4) var(--flix-spacing-2) var(--flix-spacing-7);
}
.flix-header-burger-menu-subnav__item {
  display: block;
}
.flix-header-burger-menu-subnav__link:is(button) {
  font-size: var(--flix-font-size-primary);
  border: 0;
  background: transparent;
  font-family: var(--flix-font-family-primary);
  cursor: pointer;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}
.flix-header-burger-menu-subnav__link {
  color: var(--flix-content-secondary-color);
  font-size: var(--flix-font-size-primary);
  font-weight: var(--flix-font-weight-semibold);
  line-height: var(--flix-line-height-primary);
  position: relative;
  width: -webkit-fill-available;
  width: -moz-available;
  width: stretch;
  margin: var(--flix-spacing-half) 0;
  padding: var(--flix-spacing-half);
  border-radius: var(--flix-primary-border-radius);
  text-decoration: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: var(--flix-spacing-1);
}
.flix-header-burger-menu-subnav__link .flix-icon,
.flix-header-burger-menu-subnav__link [class^=hci-icon] {
  line-height: 1;
}
.flix-header-burger-menu-subnav__link .flix-icon::before,
.flix-header-burger-menu-subnav__link [class^=hci-icon]::before {
  margin: 0;
}
.flix-header-burger-menu-subnav__link:hover {
  background-image: var(--flix-hover-layer-color);
  text-decoration: none;
}
.flix-header-burger-menu-subnav__link:enabled:active {
  background-image: var(--flix-pressed-layer-color);
  text-decoration: none;
}
.flix-header-burger-menu-subnav__link:focus {
  outline: 2px solid var(--flix-focus-outline-color);
  outline-offset: 0;
  box-shadow: 0 0 0 calc(var(--flix-spacing-half) + 0) var(--flix-grayscale-0-color);
}
.flix-header-burger-menu-subnav__link[aria-current] {
  padding-left: var(--flix-spacing-2);
  background-color: var(--flix-highlight-color);
  color: var(--flix-content-primary-color);
}
.flix-header-burger-menu-subnav__link[aria-current]::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  border-left: var(--flix-spacing-half) solid var(--flix-ui-primary-color);
}

.flix-header-nav {
  grid-column: 1/span 12;
  grid-row: 2;
  margin: 0 calc(var(--flix-spacing-2) * -1);
  background: var(--flix-header-nav-bg-color-tablet);
}
@media (min-width: 1200px) {
  .flix-header-nav {
    grid-column: 3/span 10;
    grid-row: 1;
    margin: 0;
    background: none;
  }
  .flix-header__inner:has(> .flix-header-widgets) .flix-header-nav {
    grid-column: 3/span 8;
  }
}
.flix-header-nav__list {
  margin: 0 auto;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  min-width: calc(var(--flix-page-min-width) - var(--flix-spacing-2));
  max-width: calc(var(--flix-page-max-width) - var(--flix-spacing-2));
}
@media (min-width: 1200px) {
  .flix-header-nav__list {
    justify-content: flex-start;
    max-width: none;
    margin: 0;
  }
}
.flix-header-nav__item {
  position: relative;
}
.flix-header-nav__link {
  min-height: var(--flix-spacing-8);
  color: var(--flix-header-color);
  font-size: var(--flix-font-size-primary);
  font-weight: var(--flix-font-weight-semibold);
  line-height: var(--flix-line-height-primary);
  padding: var(--flix-spacing-2);
  border-radius: var(--flix-primary-border-radius);
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: var(--flix-spacing-1);
}
.flix-header-nav__link .flix-icon,
.flix-header-nav__link [class^=hci-icon] {
  line-height: 1;
}
.flix-header-nav__link .flix-icon::before,
.flix-header-nav__link [class^=hci-icon]::before {
  margin: 0;
}
.flix-header-nav__link:is(button) {
  border: 0;
  background: transparent;
  font-family: var(--flix-font-family-primary);
  cursor: pointer;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}
.flix-header-nav__link[aria-expanded]::after {
  content: "";
  -webkit-mask: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M18.68 8.3a1 1 0 00-1.41 0l-5.28 5.28-5.26-5.27a1 1 0 00-1.4 0 1 1 0 000 1.41l5.96 5.99a1 1 0 001.41 0l5.98-6a1 1 0 000-1.42z' /%3E%3C/svg%3E") no-repeat center/var(--flix-spacing-4);
          mask: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M18.68 8.3a1 1 0 00-1.41 0l-5.28 5.28-5.26-5.27a1 1 0 00-1.4 0 1 1 0 000 1.41l5.96 5.99a1 1 0 001.41 0l5.98-6a1 1 0 000-1.42z' /%3E%3C/svg%3E") no-repeat center/var(--flix-spacing-4);
  width: var(--flix-spacing-4);
  height: var(--flix-spacing-4);
  background-color: var(--flix-header-color);
}
.flix-header-nav__link[aria-expanded=true]::after {
  transform: rotate(180deg);
}
.flix-header-nav__link[aria-expanded=true] + .flix-header-burger-menu-subnav:not([hidden]),
.flix-header-nav__link[aria-expanded=true] + .flix-header-nav-subnav:not([hidden]) {
  display: block;
}
.flix-header-nav__link:enabled:hover, .flix-header-nav__link[href]:hover {
  background-image: var(--flix-hover-layer-color);
  text-decoration: none;
}
.flix-header-nav__link:enabled:active {
  background-image: var(--flix-pressed-layer-color);
  text-decoration: none;
}
.flix-header-nav__link:focus {
  outline: 2px solid var(--flix-focus-outline-color);
  outline-offset: 1px;
  box-shadow: 0 0 0 calc(var(--flix-spacing-half) + 1px) var(--flix-grayscale-0-color);
}
@media (min-width: 1200px) {
  .flix-header-nav__link {
    min-height: var(--flix-spacing-10);
    padding: var(--flix-spacing-3) var(--flix-spacing-2);
  }
}
.flix-header-nav__link[aria-current]::before {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: var(--flix-spacing-half);
  background-color: var(--flix-header-color);
}
.flix-header-nav__link[aria-expanded]::after {
  align-self: stretch;
  height: auto;
}

.flix-header-nav-subnav {
  top: 75%;
  bottom: auto;
  left: 50%;
  transform: translateX(-50%);
  display: none;
  position: absolute;
  flex-direction: column;
  margin: calc(-1 * var(--flix-spacing-1)) 0 0;
  padding: var(--flix-spacing-4) 0 var(--flix-spacing-2);
}
.flix-header-nav-subnav--left {
  right: calc(-1 * var(--flix-spacing-1));
  left: auto;
  transform: none;
}
.flix-header-nav-subnav--right {
  right: auto;
  left: calc(-1 * var(--flix-spacing-1));
  transform: none;
}
.flix-header-nav-subnav__item {
  list-style: none;
  text-align: left;
}
.flix-header-nav-subnav__item:not(:last-of-type) {
  margin-bottom: var(--flix-spacing-1);
}
.flix-header-nav-subnav__link {
  text-align: left;
  color: var(--flix-content-secondary-color);
  font-size: var(--flix-font-size-primary);
  font-weight: var(--flix-font-weight-normal);
  line-height: var(--flix-line-height-primary);
  position: relative;
  width: -webkit-fill-available;
  width: -moz-available;
  width: stretch;
  padding: var(--flix-spacing-half) var(--flix-spacing-4);
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: var(--flix-spacing-1);
}
.flix-header-nav-subnav__link .flix-icon,
.flix-header-nav-subnav__link [class^=hci-icon] {
  line-height: 1;
}
.flix-header-nav-subnav__link .flix-icon::before,
.flix-header-nav-subnav__link [class^=hci-icon]::before {
  margin: 0;
}
.flix-header-nav-subnav__link[aria-current] {
  position: relative;
  background-color: var(--flix-highlight-color);
  color: var(--flix-content-primary-color);
}
.flix-header-nav-subnav__link[aria-current]::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  border-left: var(--flix-spacing-half) solid var(--flix-ui-primary-color);
}
.flix-header-nav-subnav__link:is(a, button) {
  border: 0;
  background: transparent;
  font-family: var(--flix-font-family-primary);
  cursor: pointer;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  font-weight: var(--flix-font-weight-semibold);
}
.flix-header-nav-subnav__link:is(a, button):enabled:hover, .flix-header-nav-subnav__link:is(a, button)[href]:hover {
  background-image: var(--flix-hover-layer-color);
  text-decoration: none;
}
.flix-header-nav-subnav__link:is(a, button):enabled:active {
  background-image: var(--flix-pressed-layer-color);
  text-decoration: none;
}
.flix-header-nav-subnav__link:is(a, button):focus {
  outline: 2px solid var(--flix-focus-outline-color);
  outline-offset: -2px;
  box-shadow: 0 0 0 calc(var(--flix-spacing-half) - 2px) var(--flix-grayscale-0-color);
  border-radius: var(--flix-primary-border-radius);
}
.flix-header-nav-subnav__link:is(a, button):focus::before {
  opacity: 0;
}

.flix-header-skip-links {
  position: absolute;
  z-index: 1000;
  grid-column: 1/span 12;
  grid-row: 1/span 1;
  align-self: end;
  transform: translateY(80%);
  justify-self: center;
}
@supports ((width: -moz-fit-content) or (width: fit-content)) {
  .flix-header-skip-links {
    width: 0;
    height: 0;
  }
  .flix-header-skip-links:focus-within {
    width: auto;
    height: auto;
  }
}
.flix-header-skip-links > .flix-skip-link {
  width: auto;
}
@media (min-width: 768px) and (max-width: 1199px) {
  .flix-header-skip-links {
    align-self: center;
    justify-self: start;
    transform: translateY(0);
  }
  @supports ((width: -moz-fit-content) or (width: fit-content)) {
    .flix-header-skip-links:focus-within {
      width: -moz-fit-content;
      width: fit-content;
    }
  }
}
@media (min-width: 1200px) {
  .flix-header-skip-links {
    align-self: end;
    justify-self: start;
  }
}

.flix-header-widgets {
  grid-column: 10/span 3;
  grid-row: 1;
  align-self: center;
  justify-self: end;
}
@media (min-width: 1200px) {
  .flix-header-widgets {
    grid-column: 11/span 2;
  }
}
.flix-header-widgets:not(.flix-header-widgets--no-responsive-rendering) {
  display: none;
}
@media (min-width: 768px) {
  .flix-header-widgets:not(.flix-header-widgets--no-responsive-rendering) {
    display: block;
  }
}

.flix-header-user-widget {
  display: flex;
  position: relative;
  align-items: center;
  gap: var(--flix-spacing-1);
}
.flix-header-user-widget__toggle {
  color: var(--flix-header-color);
  font-size: var(--flix-font-size-primary);
  font-weight: var(--flix-font-weight-semibold);
  line-height: var(--flix-line-height-primary);
  padding: var(--flix-spacing-1);
  border-radius: var(--flix-primary-border-radius);
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: var(--flix-spacing-1);
}
.flix-header-user-widget__toggle .flix-icon,
.flix-header-user-widget__toggle [class^=hci-icon] {
  line-height: 1;
}
.flix-header-user-widget__toggle .flix-icon::before,
.flix-header-user-widget__toggle [class^=hci-icon]::before {
  margin: 0;
}
.flix-header-user-widget__toggle:is(button) {
  border: 0;
  background: transparent;
  font-family: var(--flix-font-family-primary);
  cursor: pointer;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}
.flix-header-user-widget__toggle[aria-expanded]::after {
  content: "";
  -webkit-mask: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M18.68 8.3a1 1 0 00-1.41 0l-5.28 5.28-5.26-5.27a1 1 0 00-1.4 0 1 1 0 000 1.41l5.96 5.99a1 1 0 001.41 0l5.98-6a1 1 0 000-1.42z' /%3E%3C/svg%3E") no-repeat center/var(--flix-spacing-4);
          mask: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M18.68 8.3a1 1 0 00-1.41 0l-5.28 5.28-5.26-5.27a1 1 0 00-1.4 0 1 1 0 000 1.41l5.96 5.99a1 1 0 001.41 0l5.98-6a1 1 0 000-1.42z' /%3E%3C/svg%3E") no-repeat center/var(--flix-spacing-4);
  width: var(--flix-spacing-4);
  height: var(--flix-spacing-4);
  background-color: var(--flix-header-color);
}
.flix-header-user-widget__toggle[aria-expanded=true]::after {
  transform: rotate(180deg);
}
.flix-header-user-widget__toggle[aria-expanded=true] + .flix-header-burger-menu-subnav:not([hidden]),
.flix-header-user-widget__toggle[aria-expanded=true] + .flix-header-nav-subnav:not([hidden]) {
  display: block;
}
.flix-header-user-widget__toggle:enabled:hover, .flix-header-user-widget__toggle[href]:hover {
  background-image: var(--flix-hover-layer-color);
  text-decoration: none;
}
.flix-header-user-widget__toggle:enabled:active {
  background-image: var(--flix-pressed-layer-color);
  text-decoration: none;
}
.flix-header-user-widget__toggle:focus {
  outline: 2px solid var(--flix-focus-outline-color);
  outline-offset: 1px;
  box-shadow: 0 0 0 calc(var(--flix-spacing-half) + 1px) var(--flix-grayscale-0-color);
}

.flix-header {
  --flix-container-content-color: var(--flix-header-color);
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  left: 0;
  padding: 0 var(--flix-spacing-2);
  background: var(--flix-header-bg-color);
  color: var(--flix-header-color);
}
.flix-header__inner {
  display: grid;
  position: relative;
  grid-template-columns: repeat(12, 1fr);
  min-height: var(--flix-spacing-10);
  margin: 0 auto;
  column-gap: var(--flix-spacing-2);
}
@media (min-width: 1200px) {
  .flix-header__inner {
    min-width: calc(var(--flix-page-min-width) - var(--flix-spacing-2));
    max-width: calc(var(--flix-page-max-width) - var(--flix-spacing-2));
  }
}
.flix-header--fullwidth .flix-header__inner {
  max-width: none;
}
.flix-header--unfixed {
  position: static;
}
.flix-header-nav {
  display: none;
}
.flix-header-burger-menu {
  display: block;
}
@media (min-width: 768px) {
  .flix-header-nav {
    display: block;
  }
  .flix-header-burger-menu {
    display: none;
  }
}

.flix-h1, .flix-text h1, .flix-h2, .flix-text h2, .flix-h3, .flix-text h3, .flix-h4, .flix-text h4 {
  margin: 0 0 var(--flix-spacing-2);
  padding: var(--flix-spacing-2) 0 0;
}
.flix-h1:is(strong), .flix-text h1:is(strong), .flix-h2:is(strong), .flix-text h2:is(strong), .flix-h3:is(strong), .flix-text h3:is(strong), .flix-h4:is(strong), .flix-text h4:is(strong) {
  display: block;
}
.flix-h1, .flix-text h1 {
  color: var(--flix-container-content-color, var(--flix-heading-font-color));
  font-size: var(--flix-font-size-h1);
  font-weight: var(--flix-font-weight-bold);
  line-height: var(--flix-line-height-h1);
  margin: 0 0 var(--flix-spacing-4);
}
.flix-h2, .flix-text h2 {
  color: var(--flix-container-content-color, var(--flix-heading-font-color));
  font-size: var(--flix-font-size-h2);
  font-weight: var(--flix-font-weight-bold);
  line-height: var(--flix-line-height-h2);
}
.flix-h3, .flix-text h3 {
  color: var(--flix-container-content-color, var(--flix-heading-font-color));
  font-size: var(--flix-font-size-h3);
  font-weight: var(--flix-font-weight-bold);
  line-height: var(--flix-line-height-h3);
}
.flix-h4, .flix-text h4 {
  color: var(--flix-container-content-color, var(--flix-heading-font-color));
  font-size: var(--flix-font-size-h4);
  font-weight: var(--flix-font-weight-bold);
  line-height: var(--flix-line-height-h4);
}

.flix-h1--section-header, .flix-h1--space-flush-top, .flix-h2--section-header, .flix-h2--space-flush-top, .flix-h3--section-header, .flix-h3--space-flush-top, .flix-h4--section-header, .flix-h4--space-flush-top, .flix-h1, .flix-text h1 {
  padding-top: 0;
}

.flix-h1--space-flush-bottom, .flix-h2--space-flush-bottom, .flix-h3--space-flush-bottom, .flix-h4--space-flush-bottom {
  margin-bottom: 0;
}

.flix-h1--space-flush, .flix-h2--space-flush, .flix-h3--space-flush, .flix-h4--space-flush {
  margin-bottom: 0;
  padding-top: 0;
}

.flix-img-box {
  display: block;
  overflow: hidden;
  border-radius: var(--flix-primary-border-radius);
  font-family: inherit;
  text-decoration: none;
}
.flix-img-box:where(:not(:last-child)) {
  margin-bottom: var(--flix-spacing-2);
}
.flix-img-box:is(a):focus {
  outline: 2px solid var(--flix-focus-outline-color);
  outline-offset: 1px;
  box-shadow: 0 0 0 calc(var(--flix-spacing-half) + 1px) var(--flix-grayscale-0-color);
}
.flix-img-box__img-wrapper {
  position: relative;
  min-height: 198px;
  overflow: hidden;
  border-radius: var(--flix-primary-border-radius);
}
@media (min-width: 1200px) {
  .flix-img-box__img-wrapper {
    min-height: 168px;
  }
}
.flix-img-box__img {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
}
.flix-img-box--sm .flix-img-box__img {
  width: auto;
  height: 100%;
}
@supports (object-fit: cover) {
  .flix-img-box--sm .flix-img-box__img {
    width: 100%;
    object-fit: cover;
  }
}
@supports (object-fit: cover) {
  .flix-img-box__img {
    height: 100%;
    object-fit: cover;
  }
}
.flix-img-box__content {
  position: relative;
}
.flix-img-box--outlined .flix-img-box__content, a.flix-img-box .flix-img-box__content {
  padding: var(--flix-spacing-2) var(--flix-spacing-4);
  background: var(--flix-box-bg-color);
}
.flix-img-box--sm .flix-img-box__content, a.flix-img-box--sm .flix-img-box__content {
  padding-left: var(--flix-spacing-2);
}
a.flix-img-box .flix-img-box__content {
  padding-right: var(--flix-spacing-8);
}
a.flix-img-box .flix-img-box__content::after {
  content: "";
  -webkit-mask: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M15.7 11.3L9.72 5.32a1 1 0 00-1.42 0 1 1 0 000 1.42L13.58 12 8.3 17.27a1 1 0 000 1.41 1 1 0 001.41 0l5.99-5.97a1 1 0 000-1.41z' /%3E%3C/svg%3E") no-repeat center/var(--flix-spacing-4);
          mask: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M15.7 11.3L9.72 5.32a1 1 0 00-1.42 0 1 1 0 000 1.42L13.58 12 8.3 17.27a1 1 0 000 1.41 1 1 0 001.41 0l5.99-5.97a1 1 0 000-1.41z' /%3E%3C/svg%3E") no-repeat center/var(--flix-spacing-4);
  width: var(--flix-spacing-4);
  height: var(--flix-spacing-4);
  background-color: var(--flix-icon-primary-color);
  position: absolute;
  top: 50%;
  right: var(--flix-spacing-2);
  margin-top: -11px;
}
a.flix-img-box:hover .flix-img-box__content {
  background-image: var(--flix-hover-layer-color);
  text-decoration: none;
}
a.flix-img-box:active .flix-img-box__content {
  background-image: var(--flix-pressed-layer-color);
  text-decoration: none;
}
.flix-img-box--outlined,
a.flix-img-box {
  border: 1px solid var(--flix-line-primary-color);
}
.flix-img-box--outlined .flix-img-box__img-wrapper,
a.flix-img-box .flix-img-box__img-wrapper {
  border-radius: var(--flix-primary-border-radius) var(--flix-primary-border-radius) 0 0;
}

.flix-img-box--sm {
  position: relative;
  min-height: 98px;
}
.flix-img-box--sm .flix-img-box__img-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 96px;
  min-height: 96px;
}
a.flix-img-box--sm .flix-img-box__img-wrapper, .flix-img-box--sm.flix-img-box--outlined .flix-img-box__img-wrapper {
  border-radius: var(--flix-primary-border-radius) 0 0 var(--flix-primary-border-radius);
}

.flix-img-box--sm .flix-img-box__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: calc(100% - 96px);
  min-height: 96px;
  margin-left: 96px;
}

.flix-infobox {
  --flix-infobox-icon-size-rem: 3rem;
  --flix-infobox-icon-size-px: var(--flix-spacing-8);
  display: flex;
  align-items: center;
  gap: var(--flix-spacing-2);
}
.flix-infobox:where(:not(:last-child)) {
  margin-bottom: var(--flix-spacing-2);
}
.flix-infobox--small-icon {
  --flix-infobox-icon-size-rem: 1.5rem;
  --flix-infobox-icon-size-px: var(--flix-spacing-4);
}
.flix-infobox__content {
  flex-grow: 1;
  min-width: 0;
  overflow-wrap: anywhere;
}
.flix-infobox::before {
  content: "";
  -webkit-mask: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M12 2a10 10 0 100 20 10 10 0 000-20zm0 18a8 8 0 010-16 8 8 0 010 16zm1-8.5V17a1 1 0 01-2 0v-5.5a1 1 0 112 0zm.5-4a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z' /%3E%3C/svg%3E") no-repeat center/var(--flix-infobox-icon-size-rem);
          mask: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M12 2a10 10 0 100 20 10 10 0 000-20zm0 18a8 8 0 010-16 8 8 0 010 16zm1-8.5V17a1 1 0 01-2 0v-5.5a1 1 0 112 0zm.5-4a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z' /%3E%3C/svg%3E") no-repeat center/var(--flix-infobox-icon-size-rem);
  width: var(--flix-infobox-icon-size-rem);
  height: var(--flix-infobox-icon-size-rem);
  background-color: var(--flix-neutral-color);
  flex-shrink: 0;
  align-self: flex-start;
  min-width: var(--flix-infobox-icon-size-px);
  max-width: calc(var(--flix-infobox-icon-size-px) * 2);
  min-height: var(--flix-infobox-icon-size-px);
  max-height: calc(var(--flix-infobox-icon-size-px) * 2);
}
@supports (min-height: max(var(--flix-infobox-icon-size-px), var(--flix-line-height-primary))) {
  .flix-infobox::before {
    min-height: max(var(--flix-infobox-icon-size-px), var(--flix-line-height-primary));
  }
}
.flix-infobox--success::before {
  -webkit-mask: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M16.89 8.3a1 1 0 010 1.4l-6 6a1 1 0 01-1.42 0l-2.36-2.35a1 1 0 111.42-1.41l1.65 1.65 5.3-5.3a1 1 0 011.4 0zM22 12a10 10 0 11-20 0 10 10 0 0120 0zm-2 0a8 8 0 00-16 0 8 8 0 0016 0z' /%3E%3C/svg%3E") no-repeat center/var(--flix-infobox-icon-size-rem);
          mask: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M16.89 8.3a1 1 0 010 1.4l-6 6a1 1 0 01-1.42 0l-2.36-2.35a1 1 0 111.42-1.41l1.65 1.65 5.3-5.3a1 1 0 011.4 0zM22 12a10 10 0 11-20 0 10 10 0 0120 0zm-2 0a8 8 0 00-16 0 8 8 0 0016 0z' /%3E%3C/svg%3E") no-repeat center/var(--flix-infobox-icon-size-rem);
  width: var(--flix-infobox-icon-size-rem);
  height: var(--flix-infobox-icon-size-rem);
  background-color: var(--flix-success-color);
}
.flix-infobox--warning::before {
  -webkit-mask: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M12 2a10 10 0 100 20 10 10 0 000-20zm0 18a8 8 0 010-16 8 8 0 010 16zm-1-7.5V7a1 1 0 012 0v5.5a1 1 0 11-2 0zm2.5 4a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z' /%3E%3C/svg%3E") no-repeat center/var(--flix-infobox-icon-size-rem);
          mask: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M12 2a10 10 0 100 20 10 10 0 000-20zm0 18a8 8 0 010-16 8 8 0 010 16zm-1-7.5V7a1 1 0 012 0v5.5a1 1 0 11-2 0zm2.5 4a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z' /%3E%3C/svg%3E") no-repeat center/var(--flix-infobox-icon-size-rem);
  width: var(--flix-infobox-icon-size-rem);
  height: var(--flix-infobox-icon-size-rem);
  background-color: var(--flix-warning-color);
}
.flix-infobox--danger::before {
  -webkit-mask: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M12 2a10 10 0 100 20 10 10 0 000-20zm0 18a8 8 0 010-16 8 8 0 010 16zm4.95-3.05a1 1 0 01-1.41 0L12 13.41l-3.54 3.54a1 1 0 11-1.41-1.41L10.59 12 7.05 8.46a1 1 0 111.41-1.41L12 10.59l3.54-3.54a1 1 0 111.41 1.41L13.41 12l3.54 3.54a1 1 0 010 1.41z' /%3E%3C/svg%3E") no-repeat center/var(--flix-infobox-icon-size-rem);
          mask: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M12 2a10 10 0 100 20 10 10 0 000-20zm0 18a8 8 0 010-16 8 8 0 010 16zm4.95-3.05a1 1 0 01-1.41 0L12 13.41l-3.54 3.54a1 1 0 11-1.41-1.41L10.59 12 7.05 8.46a1 1 0 111.41-1.41L12 10.59l3.54-3.54a1 1 0 111.41 1.41L13.41 12l3.54 3.54a1 1 0 010 1.41z' /%3E%3C/svg%3E") no-repeat center/var(--flix-infobox-icon-size-rem);
  width: var(--flix-infobox-icon-size-rem);
  height: var(--flix-infobox-icon-size-rem);
  background-color: var(--flix-danger-color);
}
.flix-infobox::before, .flix-infobox--success::before, .flix-infobox--warning::before, .flix-infobox--danger::before {
  -webkit-mask-size: 100%;
          mask-size: 100%;
}

.flix-input {
  position: relative;
}
.flix-input__info:not(:empty) {
  color: var(--flix-container-content-color, var(--flix-content-primary-color));
  font-size: var(--flix-font-size-small);
  font-weight: var(--flix-font-weight-normal);
  line-height: var(--flix-line-height-small);
  display: block;
  margin-top: var(--flix-spacing-1);
}
.flix-input__info:not(:empty) ~ .flix-input__info {
  margin-top: var(--flix-spacing-half);
}
.flix-input--error .flix-input__info {
  color: var(--flix-danger-dark-color);
}
.flix-input__info ~ .flix-input__info {
  color: var(--flix-content-primary-color);
}
.flix-input--loading .flix-input__field, .flix-input--error .flix-input__field, .flix-input--valid .flix-input__field {
  padding-right: calc(min(max(var(--flix-spacing-4), 1.5rem), 2 * var(--flix-spacing-4)) + var(--flix-spacing-2) + var(--flix-spacing-1));
}
.flix-input--loading .flix-input__feedback-icon, .flix-input--error .flix-input__feedback-icon, .flix-input--valid .flix-input__feedback-icon {
  min-width: var(--flix-spacing-4);
  max-width: calc(var(--flix-spacing-4) * 2);
  min-height: var(--flix-spacing-4);
  max-height: calc(var(--flix-spacing-4) * 2);
  width: 1.5rem;
  height: 1.5rem;
}
.flix-input--loading .flix-input__feedback-icon {
  animation: spinner-rotate 1s infinite linear;
  border: var(--flix-spacing-half) solid var(--flix-ui-primary-light-color);
  border-radius: 50%;
  border-left-color: var(--flix-ui-primary-dark-color);
}
@keyframes spinner-rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.flix-input--error .flix-input__feedback-icon {
  -webkit-mask: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M12 2a10 10 0 100 20 10 10 0 000-20zm-1 5a1 1 0 012 0v5.5a1 1 0 11-2 0V7zm1 11a1.5 1.5 0 110-3 1.5 1.5 0 010 3z' /%3E%3C/svg%3E") no-repeat center/1.5rem;
          mask: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M12 2a10 10 0 100 20 10 10 0 000-20zm-1 5a1 1 0 012 0v5.5a1 1 0 11-2 0V7zm1 11a1.5 1.5 0 110-3 1.5 1.5 0 010 3z' /%3E%3C/svg%3E") no-repeat center/1.5rem;
  width: 1.5rem;
  height: 1.5rem;
  background-color: var(--flix-danger-color);
  -webkit-mask-size: 100%;
          mask-size: 100%;
}
.flix-input--valid .flix-input__feedback-icon {
  -webkit-mask: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M12 2a10 10 0 100 20 10 10 0 000-20zm4.89 7.7l-6 6a1 1 0 01-1.42 0l-2.36-2.35a1 1 0 111.42-1.41l1.65 1.65 5.3-5.3a1 1 0 111.4 1.42z' /%3E%3C/svg%3E") no-repeat center/1.5rem;
          mask: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M12 2a10 10 0 100 20 10 10 0 000-20zm4.89 7.7l-6 6a1 1 0 01-1.42 0l-2.36-2.35a1 1 0 111.42-1.41l1.65 1.65 5.3-5.3a1 1 0 111.4 1.42z' /%3E%3C/svg%3E") no-repeat center/1.5rem;
  width: 1.5rem;
  height: 1.5rem;
  background-color: var(--flix-success-color);
  -webkit-mask-size: 100%;
          mask-size: 100%;
}
.flix-input--error .flix-input__field {
  background-image: linear-gradient(var(--flix-bg-primary-transparent-color), var(--flix-bg-primary-transparent-color)), linear-gradient(var(--flix-danger-color), var(--flix-danger-color));
}
.flix-input--error .flix-input__field:enabled:hover {
  background-image: var(--flix-hover-layer-color), linear-gradient(var(--flix-bg-primary-transparent-color), var(--flix-bg-primary-transparent-color)), linear-gradient(var(--flix-danger-color), var(--flix-danger-color));
}
.flix-input--disabled {
  opacity: var(--flix-disabled-element-opacity);
}
.flix-input__container {
  display: flex;
  position: relative;
  z-index: 0;
  align-items: center;
  min-height: var(--flix-input-height-mobile);
  overflow: hidden;
  border: 1px solid var(--flix-input-border-color);
  border-radius: var(--flix-primary-border-radius);
  background-color: var(--flix-box-bg-color);
  color: var(--flix-content-primary-color);
}
@media (min-width: 1200px) {
  .flix-input__container {
    min-height: var(--flix-input-height-desktop);
  }
}
.flix-input--error .flix-input__container {
  border-color: var(--flix-danger-color);
  background-image: linear-gradient(var(--flix-bg-primary-transparent-color), var(--flix-bg-primary-transparent-color)), linear-gradient(var(--flix-danger-color), var(--flix-danger-color));
}
.flix-input--active .flix-input__container, .flix-input__container:focus-within {
  outline: 2px solid var(--flix-focus-outline-color);
  outline-offset: 1px;
  box-shadow: 0 0 0 calc(var(--flix-spacing-half) + 1px) var(--flix-grayscale-0-color);
}
.flix-input__field {
  order: 3;
  position: relative;
  width: 100%;
  min-height: 100%;
  padding: calc(var(--flix-spacing-2) - var(--flix-spacing-half) - 1px) var(--flix-spacing-2);
  border: 0;
  background: transparent;
  color: var(--flix-content-primary-color);
  font-family: inherit;
  font-size: var(--flix-font-size-primary);
  font-weight: var(--flix-font-weight-normal);
  line-height: var(--flix-line-height-primary);
  text-overflow: ellipsis;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}
@media (min-width: 1200px) {
  .flix-input__field {
    padding-top: calc(var(--flix-spacing-1) - 1px);
    padding-bottom: calc(var(--flix-spacing-1) - 1px);
  }
}
.flix-input__field::placeholder {
  opacity: 1;
  color: var(--flix-content-secondary-color);
  font-style: italic;
}
.flix-input__field[type=time], .flix-input__field[type=date], .flix-input__field[type=datetime-local], .flix-input__field[type=month], .flix-input__field[type=week] {
  padding-top: calc(var(--flix-spacing-2) - 4px);
  padding-bottom: calc(var(--flix-spacing-2) - 4px);
}
@media (min-width: 1200px) {
  .flix-input__field[type=time], .flix-input__field[type=date], .flix-input__field[type=datetime-local], .flix-input__field[type=month], .flix-input__field[type=week] {
    padding-top: calc(var(--flix-spacing-1) - 2px);
    padding-bottom: calc(var(--flix-spacing-1) - 2px);
  }
}
.flix-input__field[type=time]::-webkit-inner-spin-button, .flix-input__field[type=date]::-webkit-inner-spin-button, .flix-input__field[type=datetime-local]::-webkit-inner-spin-button, .flix-input__field[type=month]::-webkit-inner-spin-button, .flix-input__field[type=week]::-webkit-inner-spin-button {
  -webkit-appearance: none;
          appearance: none;
}
.flix-input__field[type=time]::-webkit-calendar-picker-indicator, .flix-input__field[type=date]::-webkit-calendar-picker-indicator, .flix-input__field[type=datetime-local]::-webkit-calendar-picker-indicator, .flix-input__field[type=month]::-webkit-calendar-picker-indicator, .flix-input__field[type=week]::-webkit-calendar-picker-indicator {
  min-width: var(--flix-spacing-4);
  max-width: calc(var(--flix-spacing-4) * 2);
  min-height: var(--flix-spacing-4);
  max-height: calc(var(--flix-spacing-4) * 2);
  display: block !important;
  order: -1;
  width: var(--flix-spacing-4);
  height: var(--flix-spacing-4);
  margin-right: var(--flix-spacing-1);
  margin-left: 0;
  padding: 0;
  opacity: 1;
  color: transparent;
}
.flix-input__field[type=time]::-webkit-clear-button, .flix-input__field[type=date]::-webkit-clear-button, .flix-input__field[type=datetime-local]::-webkit-clear-button, .flix-input__field[type=month]::-webkit-clear-button, .flix-input__field[type=week]::-webkit-clear-button {
  -webkit-mask: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M13.41 12l6.37-6.36a1 1 0 10-1.42-1.42L12 10.6 5.64 4.22a1 1 0 10-1.42 1.42L10.6 12l-6.37 6.36a1 1 0 101.42 1.42L12 13.4l6.36 6.37a1 1 0 101.42-1.42L13.4 12z' /%3E%3C/svg%3E") no-repeat center/1.5rem;
          mask: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M13.41 12l6.37-6.36a1 1 0 10-1.42-1.42L12 10.6 5.64 4.22a1 1 0 10-1.42 1.42L10.6 12l-6.37 6.36a1 1 0 101.42 1.42L12 13.4l6.36 6.37a1 1 0 101.42-1.42L13.4 12z' /%3E%3C/svg%3E") no-repeat center/1.5rem;
  width: 1.5rem;
  height: 1.5rem;
  background-color: var(--flix-icon-primary-color);
  min-width: var(--flix-spacing-4);
  max-width: calc(var(--flix-spacing-4) * 2);
  min-height: var(--flix-spacing-4);
  max-height: calc(var(--flix-spacing-4) * 2);
  -webkit-mask-size: 100%;
          mask-size: 100%;
  background-image: none;
  -webkit-appearance: none;
          appearance: none;
}
.flix-input__field[type=date]::-webkit-calendar-picker-indicator, .flix-input__field[type=datetime-local]::-webkit-calendar-picker-indicator, .flix-input__field[type=month]::-webkit-calendar-picker-indicator, .flix-input__field[type=week]::-webkit-calendar-picker-indicator {
  -webkit-mask: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M19 5h-1V4a1 1 0 00-2 0v1H8V4a1 1 0 00-2 0v1H5a3 3 0 00-3 3v10a3 3 0 003 3h14a3 3 0 003-3V8a3 3 0 00-3-3zm1 13c0 .54-.46 1-1 1H5c-.54 0-1-.46-1-1v-7h16v7zM4 9V8c0-.54.46-1 1-1h14c.54 0 1 .46 1 1v1H4zm2 4.5a1 1 0 112 0 1 1 0 01-2 0zm5 0a1 1 0 112 0 1 1 0 01-2 0zm5 0a1 1 0 112 0 1 1 0 01-2 0zm-10 3a1 1 0 112 0 1 1 0 01-2 0zm5 0a1 1 0 112 0 1 1 0 01-2 0z' /%3E%3C/svg%3E") no-repeat center/1.5rem;
          mask: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M19 5h-1V4a1 1 0 00-2 0v1H8V4a1 1 0 00-2 0v1H5a3 3 0 00-3 3v10a3 3 0 003 3h14a3 3 0 003-3V8a3 3 0 00-3-3zm1 13c0 .54-.46 1-1 1H5c-.54 0-1-.46-1-1v-7h16v7zM4 9V8c0-.54.46-1 1-1h14c.54 0 1 .46 1 1v1H4zm2 4.5a1 1 0 112 0 1 1 0 01-2 0zm5 0a1 1 0 112 0 1 1 0 01-2 0zm5 0a1 1 0 112 0 1 1 0 01-2 0zm-10 3a1 1 0 112 0 1 1 0 01-2 0zm5 0a1 1 0 112 0 1 1 0 01-2 0z' /%3E%3C/svg%3E") no-repeat center/1.5rem;
  width: 1.5rem;
  height: 1.5rem;
  background-color: var(--flix-icon-primary-color);
  -webkit-mask-size: 100%;
          mask-size: 100%;
  background-image: none;
}
.flix-input__field[type=time]::-webkit-calendar-picker-indicator {
  -webkit-mask: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M12 2a10 10 0 100 20 10 10 0 000-20zm0 18a8 8 0 010-16 8 8 0 010 16zm3.2-6.2a1 1 0 11-1.4 1.4l-2.5-2.5a1 1 0 01-.3-.7V7a1 1 0 012 0v4.59l2.2 2.2z' /%3E%3C/svg%3E") no-repeat center/1.5rem;
          mask: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M12 2a10 10 0 100 20 10 10 0 000-20zm0 18a8 8 0 010-16 8 8 0 010 16zm3.2-6.2a1 1 0 11-1.4 1.4l-2.5-2.5a1 1 0 01-.3-.7V7a1 1 0 012 0v4.59l2.2 2.2z' /%3E%3C/svg%3E") no-repeat center/1.5rem;
  width: 1.5rem;
  height: 1.5rem;
  background-color: var(--flix-icon-primary-color);
  -webkit-mask-size: 100%;
          mask-size: 100%;
  background-image: none;
}
.flix-input__field[type=search]::-webkit-search-cancel-button {
  -webkit-mask: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M13.41 12l6.37-6.36a1 1 0 10-1.42-1.42L12 10.6 5.64 4.22a1 1 0 10-1.42 1.42L10.6 12l-6.37 6.36a1 1 0 101.42 1.42L12 13.4l6.36 6.37a1 1 0 101.42-1.42L13.4 12z' /%3E%3C/svg%3E") no-repeat center/1.5rem;
          mask: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M13.41 12l6.37-6.36a1 1 0 10-1.42-1.42L12 10.6 5.64 4.22a1 1 0 10-1.42 1.42L10.6 12l-6.37 6.36a1 1 0 101.42 1.42L12 13.4l6.36 6.37a1 1 0 101.42-1.42L13.4 12z' /%3E%3C/svg%3E") no-repeat center/1.5rem;
  width: 1.5rem;
  height: 1.5rem;
  background-color: var(--flix-icon-primary-color);
  min-width: var(--flix-spacing-4);
  max-width: calc(var(--flix-spacing-4) * 2);
  min-height: var(--flix-spacing-4);
  max-height: calc(var(--flix-spacing-4) * 2);
  -webkit-mask-size: 100%;
          mask-size: 100%;
  opacity: 1;
  cursor: pointer;
  -webkit-appearance: none;
          appearance: none;
}
.flix-input__field:enabled:hover, .flix-input__field[href]:hover {
  background-image: var(--flix-hover-layer-color);
  text-decoration: none;
}
.flix-input__field:focus {
  outline: none;
}
.flix-input--disabled .flix-input__field {
  cursor: not-allowed;
}
.flix-input__icon ~ .flix-input__field {
  margin-left: calc(-1 * (min(max(var(--flix-spacing-4), 1.5rem), 2 * var(--flix-spacing-4)) + var(--flix-spacing-2) + var(--flix-spacing-1)));
  padding-left: calc(min(max(var(--flix-spacing-4), 1.5rem), 2 * var(--flix-spacing-4)) + var(--flix-spacing-2) + var(--flix-spacing-1));
}
.flix-input--has-icon-right .flix-input__field {
  margin-right: calc(-1 * (min(max(var(--flix-spacing-4), 1.5rem), 2 * var(--flix-spacing-4)) + var(--flix-spacing-2) + var(--flix-spacing-1)));
  padding-right: calc(min(max(var(--flix-spacing-4), 1.5rem), 2 * var(--flix-spacing-4)) + var(--flix-spacing-2) + var(--flix-spacing-1));
}
.flix-input--has-controllers .flix-input__field {
  margin-right: calc(-1 * (var(--flix-spacing-4) * 2 + var(--flix-spacing-2) + var(--flix-spacing-1)));
  padding-right: calc(var(--flix-spacing-4) * 2 + var(--flix-spacing-2) + var(--flix-spacing-1));
}
.flix-input--has-icon-right.flix-input--has-controllers .flix-input__field {
  margin-right: calc(-1 * (var(--flix-spacing-4) * 3 + var(--flix-spacing-4) + var(--flix-spacing-1)));
  padding-right: calc(var(--flix-spacing-4) * 3 + var(--flix-spacing-4) + var(--flix-spacing-1));
}
.flix-input--error.flix-input--has-icon-right .flix-input__field, .flix-input--valid.flix-input--has-icon-right .flix-input__field, .flix-input--loading.flix-input--has-icon-right .flix-input__field {
  padding-right: calc(2 * min(max(var(--flix-spacing-4), 1.5rem), 2 * var(--flix-spacing-4)) + var(--flix-spacing-6));
}
.flix-input--error.flix-input--has-controllers .flix-input__field, .flix-input--valid.flix-input--has-controllers .flix-input__field, .flix-input--loading.flix-input--has-controllers .flix-input__field {
  padding-right: calc(min(max(var(--flix-spacing-4), 1.5rem), 2 * var(--flix-spacing-4)) + var(--flix-spacing-4) * 2 + var(--flix-spacing-6));
}
.flix-input--error.flix-input--has-controllers.flix-input--has-icon-right .flix-input__field, .flix-input--valid.flix-input--has-controllers.flix-input--has-icon-right .flix-input__field, .flix-input--loading.flix-input--has-controllers.flix-input--has-icon-right .flix-input__field {
  padding-right: calc(2 * min(max(var(--flix-spacing-4), 1.5rem), 2 * var(--flix-spacing-4)) + var(--flix-spacing-4) * 2 + var(--flix-spacing-8));
}
.flix-input__feedback-icon {
  z-index: 0;
  flex-shrink: 0;
  order: 3;
  pointer-events: none;
}
.flix-input--loading .flix-input__feedback-icon, .flix-input--error .flix-input__feedback-icon, .flix-input--valid .flix-input__feedback-icon {
  margin: 0 var(--flix-spacing-2) 0 calc(-1 * (min(max(var(--flix-spacing-4), 1.5rem), 2 * var(--flix-spacing-4)) + var(--flix-spacing-2)));
}
.flix-input__inline-label {
  display: flex;
  position: relative;
  z-index: 1;
  align-items: center;
  align-self: stretch;
  padding: 0 var(--flix-spacing-2);
  border-right: 1px solid var(--flix-input-border-color);
  line-height: var(--flix-line-height-primary);
  order: 1;
}
.flix-input__field ~ .flix-input__inline-label {
  order: 6;
  border-right: 0;
  border-left: 1px solid var(--flix-input-border-color);
}
.flix-input__controllers {
  display: flex;
  order: 5;
  gap: var(--flix-spacing-1);
  margin: 0 var(--flix-spacing-1);
}
.flix-input__controllers .flix-input__icon {
  margin: 0;
}
.flix-input--has-icon-right .flix-input__controllers {
  margin-left: 0;
}
.flix-input__icon, .flix-input__icon.flix-icon {
  order: 2;
  position: relative;
  z-index: 1;
  flex-shrink: 0;
  width: 1.5rem;
  height: 1.5rem;
  margin-right: var(--flix-spacing-1);
  margin-left: var(--flix-spacing-2);
  pointer-events: none;
  min-width: var(--flix-spacing-4);
  max-width: calc(var(--flix-spacing-4) * 2);
  min-height: var(--flix-spacing-4);
  max-height: calc(var(--flix-spacing-4) * 2);
  font-size: 1.5rem;
  line-height: 1.5rem;
}
@supports (font-size: min(1.5rem, var(--flix-spacing-4))) {
  .flix-input__icon, .flix-input__icon.flix-icon {
    font-size: min(max(var(--flix-spacing-4), 1.5rem), 2 * var(--flix-spacing-4));
    line-height: min(max(var(--flix-spacing-4), 1.5rem), 2 * var(--flix-spacing-4));
  }
}
.flix-input__icon::before, .flix-input__icon.flix-icon::before {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0;
  margin-left: 0;
  min-width: var(--flix-spacing-4);
  max-width: calc(var(--flix-spacing-4) * 2);
  min-height: var(--flix-spacing-4);
  max-height: calc(var(--flix-spacing-4) * 2);
}
.flix-input__field ~ .flix-input__icon, .flix-input__field ~ .flix-input__icon.flix-icon {
  order: 4;
  margin-right: var(--flix-spacing-2);
  margin-left: var(--flix-spacing-1);
}
.flix-input button.flix-input__icon {
  pointer-events: auto;
}
.flix-input button.flix-input__icon .flix-icon {
  width: 1.5rem;
  height: 1.5rem;
  font-size: var(--flix-spacing-4);
  line-height: var(--flix-spacing-4);
  min-width: var(--flix-spacing-4);
  max-width: calc(var(--flix-spacing-4) * 2);
  min-height: var(--flix-spacing-4);
  max-height: calc(var(--flix-spacing-4) * 2);
}
.flix-input button.flix-input__icon .flix-icon::before {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0;
  margin-left: 0;
  min-width: var(--flix-spacing-4);
  max-width: calc(var(--flix-spacing-4) * 2);
  min-height: var(--flix-spacing-4);
  max-height: calc(var(--flix-spacing-4) * 2);
  font-size: 1.5rem;
  line-height: 1.5rem;
}
@supports (font-size: min(1.5rem, var(--flix-spacing-4))) {
  .flix-input button.flix-input__icon .flix-icon::before {
    font-size: min(max(var(--flix-spacing-4), 1.5rem), 2 * var(--flix-spacing-4));
    line-height: min(max(var(--flix-spacing-4), 1.5rem), 2 * var(--flix-spacing-4));
  }
}

.flix-label {
  display: block;
  position: absolute;
  z-index: 1;
  top: -0.5rem;
  left: var(--flix-spacing-1);
  padding: 1px var(--flix-spacing-1);
  border-radius: var(--flix-primary-border-radius);
  background: var(--flix-bg-primary-color);
  color: var(--flix-content-secondary-color);
  font-family: inherit;
  font-size: var(--flix-font-size-small);
  font-weight: var(--flix-font-weight-normal);
  line-height: 1rem;
  text-align: left;
}
@media (min-width: 1200px) {
  .flix-label {
    position: static;
    top: auto;
    left: auto;
    padding: 0 0 var(--flix-spacing-half);
    background: transparent;
    font-size: var(--flix-font-size-primary);
    line-height: var(--flix-line-height-primary);
  }
}

.flix-language-switcher {
  display: flex;
  align-items: center;
  height: 100%;
}
.flix-language-switcher__toggle {
  color: inherit;
  gap: var(--flix-spacing-1);
  font-size: var(--flix-font-size-primary);
  font-weight: var(--flix-font-weight-semibold);
  line-height: var(--flix-line-height-primary);
  padding: var(--flix-spacing-1);
  border-radius: var(--flix-primary-border-radius);
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: var(--flix-spacing-1);
}
.flix-language-switcher__toggle .flix-icon,
.flix-language-switcher__toggle [class^=hci-icon] {
  line-height: 1;
}
.flix-language-switcher__toggle .flix-icon::before,
.flix-language-switcher__toggle [class^=hci-icon]::before {
  margin: 0;
}
.flix-language-switcher__toggle:is(button) {
  border: 0;
  background: transparent;
  font-family: var(--flix-font-family-primary);
  cursor: pointer;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}
.flix-language-switcher__toggle[aria-expanded]::after {
  content: "";
  -webkit-mask: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M18.68 8.3a1 1 0 00-1.41 0l-5.28 5.28-5.26-5.27a1 1 0 00-1.4 0 1 1 0 000 1.41l5.96 5.99a1 1 0 001.41 0l5.98-6a1 1 0 000-1.42z' /%3E%3C/svg%3E") no-repeat center/var(--flix-spacing-4);
          mask: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M18.68 8.3a1 1 0 00-1.41 0l-5.28 5.28-5.26-5.27a1 1 0 00-1.4 0 1 1 0 000 1.41l5.96 5.99a1 1 0 001.41 0l5.98-6a1 1 0 000-1.42z' /%3E%3C/svg%3E") no-repeat center/var(--flix-spacing-4);
  width: var(--flix-spacing-4);
  height: var(--flix-spacing-4);
  background-color: var(--flix-header-color);
}
.flix-language-switcher__toggle[aria-expanded=true]::after {
  transform: rotate(180deg);
}
.flix-language-switcher__toggle[aria-expanded=true] + .flix-header-burger-menu-subnav:not([hidden]),
.flix-language-switcher__toggle[aria-expanded=true] + .flix-header-nav-subnav:not([hidden]) {
  display: block;
}
.flix-language-switcher__toggle:enabled:hover, .flix-language-switcher__toggle[href]:hover {
  background-image: var(--flix-hover-layer-color);
  text-decoration: none;
}
.flix-language-switcher__toggle:enabled:active {
  background-image: var(--flix-pressed-layer-color);
  text-decoration: none;
}
.flix-language-switcher__toggle:focus {
  outline: 2px solid var(--flix-focus-outline-color);
  outline-offset: 1px;
  box-shadow: 0 0 0 calc(var(--flix-spacing-half) + 1px) var(--flix-grayscale-0-color);
}
.flix-header-burger-menu__widgets .flix-language-switcher__toggle {
  width: 100%;
  padding: var(--flix-spacing-1);
}
.flix-language-switcher__flag {
  display: inline-block;
  width: var(--flix-spacing-4);
  height: var(--flix-spacing-4);
  border-radius: 100%;
}
.flix-language-switcher-popup {
  display: none;
  position: fixed;
  z-index: 1100;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  min-width: var(--flix-page-min-width);
  max-width: var(--flix-page-max-width);
  margin: 0 auto;
  padding: 0 var(--flix-spacing-2);
}
.flix-language-switcher-popup:not([hidden]) {
  display: grid;
  grid-column-gap: var(--flix-spacing-2);
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 100vh auto;
  align-items: center;
}
@media (min-width: 1024px) {
  .flix-language-switcher-popup:not([hidden]) {
    grid-template-columns: repeat(12, 1fr);
  }
}
.flix-language-switcher-popup__body {
  display: grid;
  position: relative;
  z-index: 1101;
  grid-column: 1/span 4;
  grid-template-columns: auto min-content;
  height: -moz-fit-content;
  height: fit-content;
  max-height: 80vh;
  padding: var(--flix-spacing-4);
  overflow-y: auto;
  border-radius: var(--flix-primary-border-radius);
  background: var(--flix-box-bg-color);
}
@media (min-width: 1024px) {
  .flix-language-switcher-popup__body {
    grid-column: 2/span 10;
  }
}
.flix-language-switcher-popup__region {
  grid-column: 1;
}
.flix-language-switcher-popup__region:not(:last-child) {
  margin-bottom: var(--flix-spacing-2);
  padding-bottom: var(--flix-spacing-2);
  border-bottom: 1px solid var(--flix-line-primary-color);
}
.flix-language-switcher-popup__list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: var(--flix-spacing-1);
}
.flix-language-switcher-popup__list:not(:last-child) {
  margin-bottom: var(--flix-spacing-2);
}
@media (min-width: 768px) {
  .flix-language-switcher-popup__list {
    grid-template-columns: repeat(4, 1fr);
  }
}
.flix-language-switcher-popup__link:is(button) {
  font-size: var(--flix-font-size-primary);
  border: 0;
  background: transparent;
  font-family: var(--flix-font-family-primary);
  cursor: pointer;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}
.flix-language-switcher-popup__link {
  color: var(--flix-content-secondary-color);
  font-size: var(--flix-font-size-primary);
  font-weight: var(--flix-font-weight-semibold);
  line-height: var(--flix-line-height-primary);
  position: relative;
  width: -webkit-fill-available;
  width: -moz-available;
  width: stretch;
  margin: var(--flix-spacing-half) 0;
  padding: var(--flix-spacing-half);
  border-radius: var(--flix-primary-border-radius);
  text-decoration: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: var(--flix-spacing-1);
}
.flix-language-switcher-popup__link .flix-icon,
.flix-language-switcher-popup__link [class^=hci-icon] {
  line-height: 1;
}
.flix-language-switcher-popup__link .flix-icon::before,
.flix-language-switcher-popup__link [class^=hci-icon]::before {
  margin: 0;
}
.flix-language-switcher-popup__link:hover {
  background-image: var(--flix-hover-layer-color);
  text-decoration: none;
}
.flix-language-switcher-popup__link:enabled:active {
  background-image: var(--flix-pressed-layer-color);
  text-decoration: none;
}
.flix-language-switcher-popup__link:focus {
  outline: 2px solid var(--flix-focus-outline-color);
  outline-offset: 0;
  box-shadow: 0 0 0 calc(var(--flix-spacing-half) + 0) var(--flix-grayscale-0-color);
}
.flix-language-switcher-popup__link[aria-current] {
  padding-left: var(--flix-spacing-2);
  background-color: var(--flix-highlight-color);
  color: var(--flix-content-primary-color);
}
.flix-language-switcher-popup__link[aria-current]::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  border-left: var(--flix-spacing-half) solid var(--flix-ui-primary-color);
}
.flix-language-switcher-popup__close {
  top: 0;
  right: 0;
  grid-column: 2;
  grid-row: 1;
  align-self: start;
}
.flix-language-switcher-popup__close.flix-language-switcher-popup__close {
  position: -webkit-sticky;
  position: sticky;
}
.flix-language-switcher-popup__close::before {
  content: "";
  -webkit-mask: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M13.41 12l6.37-6.36a1 1 0 10-1.42-1.42L12 10.6 5.64 4.22a1 1 0 10-1.42 1.42L10.6 12l-6.37 6.36a1 1 0 101.42 1.42L12 13.4l6.36 6.37a1 1 0 101.42-1.42L13.4 12z' /%3E%3C/svg%3E") no-repeat center/1.5rem;
          mask: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M13.41 12l6.37-6.36a1 1 0 10-1.42-1.42L12 10.6 5.64 4.22a1 1 0 10-1.42 1.42L10.6 12l-6.37 6.36a1 1 0 101.42 1.42L12 13.4l6.36 6.37a1 1 0 101.42-1.42L13.4 12z' /%3E%3C/svg%3E") no-repeat center/1.5rem;
  width: 1.5rem;
  height: 1.5rem;
  background-color: var(--flix-icon-primary-color);
  min-width: var(--flix-spacing-4);
  max-width: calc(var(--flix-spacing-4) * 2);
  min-height: var(--flix-spacing-4);
  max-height: calc(var(--flix-spacing-4) * 2);
}

.flix-legend {
  display: block;
  width: 100%;
  padding-bottom: var(--flix-spacing-half);
  color: var(--flix-content-secondary-color);
  font-family: inherit;
  font-size: var(--flix-font-size-primary);
  font-weight: var(--flix-font-weight-normal);
}

.flix-link, .flix-text a {
  background-color: transparent;
  color: var(--flix-link-color);
  font-weight: var(--flix-font-weight-semibold);
  text-decoration: underline;
}
.flix-link:hover, .flix-text a:hover {
  background-image: var(--flix-hover-layer-color);
  text-decoration: none;
  border-radius: var(--flix-primary-border-radius);
  color: var(--flix-link-color);
  text-decoration: underline;
}
.flix-link:active, .flix-text a:active {
  background-image: var(--flix-pressed-layer-color);
  text-decoration: none;
  color: var(--flix-link-color);
}
.flix-link:focus, .flix-text a:focus {
  outline: 2px solid var(--flix-focus-outline-color);
  outline-offset: 1px;
  box-shadow: 0 0 0 calc(var(--flix-spacing-half) + 1px) var(--flix-grayscale-0-color);
  border-radius: var(--flix-primary-border-radius);
  color: var(--flix-link-color);
}

.flix-list-description__details, .flix-text dl > dd {
  margin-left: var(--flix-spacing-4);
}
.flix-list-description__details + .flix-list-description__term, .flix-text dl > dd + .flix-list-description__term, .flix-text dl > .flix-list-description__details + dt, .flix-text dl > dd + dt {
  margin-top: var(--flix-spacing-2);
}

.flix-list-numbered, .flix-text ol {
  margin: 0 0 var(--flix-spacing-2);
  padding: 0;
  list-style-type: none;
  padding-left: var(--flix-spacing-2);
  counter-reset: numbered-list-counter;
}
.flix-list-numbered__item, .flix-text ol > li {
  display: table;
  position: relative;
  color: var(--flix-content-primary-color);
}
.flix-list-numbered__item::before, .flix-text ol > li::before {
  content: counters(numbered-list-counter, ".") ".";
  display: table-cell;
  padding-right: var(--flix-spacing-1);
  font-weight: var(--flix-font-weight-bold);
  counter-increment: numbered-list-counter;
}
.flix-list-numbered__item > .flix-list-numbered, .flix-text ol > li > .flix-list-numbered, .flix-text .flix-list-numbered__item > ol, .flix-text ol > li > ol {
  width: 100%;
  margin: 0;
  padding-left: 0;
}
.flix-list-numbered__item > .flix-list-numbered .flix-list-numbered__item::before, .flix-text ol > li > .flix-list-numbered .flix-list-numbered__item::before, .flix-list-numbered__item > .flix-list-numbered .flix-text ol > li::before, .flix-text .flix-list-numbered__item > .flix-list-numbered ol > li::before, .flix-text ol > li > .flix-list-numbered ol > li::before, .flix-text .flix-list-numbered__item > ol .flix-list-numbered__item::before, .flix-text ol > li > ol .flix-list-numbered__item::before, .flix-text .flix-list-numbered__item > ol > li::before, .flix-text ol > li > ol > li::before {
  content: counters(numbered-list-counter, ".");
  font-weight: var(--flix-font-weight-normal);
}

.flix-list-wrapper {
  --flix-list-wrapper-min-height: var(--flix-spacing-8);
  --flix-list-wrapper-content-padding: var(--flix-spacing-2);
  --flix-list-wrapper-interactive-content-padding: var(--flix-spacing-2) var(--flix-spacing-8) var(--flix-spacing-2) var(--flix-spacing-2);
  --flix-list-wrapper-font-weight: var(--flix-font-weight-semibold);
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.flix-list-wrapper__item {
  display: flex;
  position: relative;
  align-items: stretch;
  min-height: var(--flix-list-wrapper-min-height);
  border: solid var(--flix-line-primary-color);
  border-width: 0 1px 1px;
  background-color: var(--flix-box-bg-color);
  color: var(--flix-content-primary-color);
  font-size: var(--flix-font-size-primary);
  line-height: var(--flix-line-height-primary);
}
.flix-list-wrapper__item:first-of-type {
  border-top-width: 1px;
}
.flix-list-wrapper__item:first-of-type, .flix-list-wrapper__item:first-of-type .flix-list-wrapper__content {
  border-top-left-radius: var(--flix-primary-border-radius);
  border-top-right-radius: var(--flix-primary-border-radius);
}
.flix-list-wrapper__item:last-of-type, .flix-list-wrapper__item:last-of-type .flix-list-wrapper__content {
  border-bottom-right-radius: var(--flix-primary-border-radius);
  border-bottom-left-radius: var(--flix-primary-border-radius);
}
.flix-list-wrapper__item--error {
  border-width: 1px;
  border-color: var(--flix-danger-color);
}
.flix-list-wrapper__item--error + .flix-list-wrapper__item--error {
  border-width: 0 1px 1px;
}
.flix-list-wrapper__item--disabled {
  opacity: var(--flix-disabled-element-opacity);
}
.flix-list-wrapper__item--disabled .flix-list-wrapper__content {
  cursor: not-allowed;
}
.flix-list-wrapper__item:not(.flix-list-wrapper__item--disabled) .flix-list-wrapper__content:is(a, button):hover {
  background-image: var(--flix-hover-layer-color);
  text-decoration: none;
}
.flix-list-wrapper__item:not(.flix-list-wrapper__item--disabled) .flix-list-wrapper__content:is(a, button):active {
  background-image: var(--flix-pressed-layer-color);
  text-decoration: none;
}
.flix-list-wrapper__item:not(.flix-list-wrapper__item--disabled) .flix-list-wrapper__content:is(a, button):focus {
  outline: 2px solid var(--flix-focus-outline-color);
  outline-offset: 1px;
  box-shadow: 0 0 0 calc(var(--flix-spacing-half) + 1px) var(--flix-grayscale-0-color);
  border-radius: var(--flix-primary-border-radius);
  z-index: 1;
  text-decoration: none;
}
.flix-list-wrapper__content {
  display: block;
  width: 100%;
  padding: var(--flix-list-wrapper-content-padding);
  color: var(--flix-content-primary-color);
  font-weight: var(--flix-list-wrapper-font-weight);
}
.flix-list-wrapper__content:is(a, button) {
  padding: 0;
  font-size: var(--flix-font-size-primary);
  border: 0;
  background: transparent;
  font-family: var(--flix-font-family-primary);
  cursor: pointer;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  padding: var(--flix-list-wrapper-interactive-content-padding);
  text-align: left;
  text-decoration: none;
  cursor: pointer;
}
.flix-list-wrapper__content:is(a, button)::after {
  content: "";
  -webkit-mask: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M15.7 11.3L9.72 5.32a1 1 0 00-1.42 0 1 1 0 000 1.42L13.58 12 8.3 17.27a1 1 0 000 1.41 1 1 0 001.41 0l5.99-5.97a1 1 0 000-1.41z' /%3E%3C/svg%3E") no-repeat center/var(--flix-spacing-4);
          mask: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M15.7 11.3L9.72 5.32a1 1 0 00-1.42 0 1 1 0 000 1.42L13.58 12 8.3 17.27a1 1 0 000 1.41 1 1 0 001.41 0l5.99-5.97a1 1 0 000-1.41z' /%3E%3C/svg%3E") no-repeat center/var(--flix-spacing-4);
  width: var(--flix-spacing-4);
  height: var(--flix-spacing-4);
  background-color: var(--flix-icon-primary-color);
  position: absolute;
  top: 50%;
  right: var(--flix-spacing-2);
  margin-top: calc(-1 * var(--flix-spacing-2));
}
.flix-list-wrapper--small {
  --flix-list-wrapper-min-height: var(--flix-spacing-6);
  --flix-list-wrapper-content-padding: var(--flix-spacing-1);
  --flix-list-wrapper-interactive-content-padding: var(--flix-spacing-1) var(--flix-spacing-6) var(--flix-spacing-1) var(--flix-spacing-1);
  --flix-list-wrapper-font-weight: var(--flix-font-weight-normal);
}

.flix-list, .flix-text ul {
  margin: 0 0 var(--flix-spacing-2);
  padding: 0;
  list-style-type: none;
  padding-left: var(--flix-spacing-2);
}
.flix-list__item, .flix-text ul > li {
  position: relative;
  padding-left: calc(var(--flix-spacing-2) + var(--flix-spacing-half));
  color: var(--flix-content-primary-color);
  line-height: var(--flix-line-height-primary);
}
.flix-list__item::before, .flix-text ul > li::before {
  content: "";
  display: block;
  position: absolute;
  top: calc(var(--flix-line-height-primary) / 2);
  left: 0;
  width: var(--flix-spacing-half);
  height: var(--flix-spacing-half);
  margin-top: calc(-1 * var(--flix-spacing-half) / 2);
  border-radius: 50%;
  background: var(--flix-content-primary-color);
}
@supports (width: clamp(var(--flix-spacing-half), 0.188rem, var(--flix-spacing-2))) {
  .flix-list__item, .flix-text ul > li {
    padding-left: calc(var(--flix-spacing-2) + clamp(var(--flix-spacing-half), 0.188rem, var(--flix-spacing-2)));
  }
  .flix-list__item::before, .flix-text ul > li::before {
    width: clamp(var(--flix-spacing-half), 0.188rem, var(--flix-spacing-2));
    height: clamp(var(--flix-spacing-half), 0.188rem, var(--flix-spacing-2));
    margin-top: calc(-1 * clamp(var(--flix-spacing-half), 0.188rem, var(--flix-spacing-2)) / 2);
  }
}
.flix-list__item > .flix-list, .flix-text ul > li > .flix-list, .flix-text .flix-list__item > ul, .flix-text ul > li > ul {
  margin: 0;
  padding-left: 0;
}

.flix-main-wrapper {
  position: relative;
  min-height: 100%;
  margin-top: 0;
  padding-bottom: 0;
  background: var(--flix-bg-primary-color);
}
.flix-main-wrapper--full {
  min-height: 100vh;
}

.flix-nav-horizontal {
  margin: 0;
  margin-bottom: var(--flix-spacing-4);
  padding: 0;
}
.flix-nav-horizontal--stretch .flix-nav-horizontal__item {
  flex: 1 1 0;
}
.flix-nav-horizontal--stretch .flix-nav-horizontal__link {
  width: -webkit-fill-available;
  width: -moz-available;
  width: stretch;
}
.flix-nav-horizontal__items {
  display: flex;
  align-items: flex-end;
  margin: 0;
  padding: 0;
}
.flix-nav-horizontal__item {
  position: relative;
  max-width: 50%;
  list-style-type: none;
  white-space: nowrap;
}
.flix-nav-horizontal__item:not(:first-child)::before {
  content: "";
  display: inline-block;
  position: absolute;
  bottom: var(--flix-spacing-2);
  left: 0;
  height: var(--flix-spacing-3);
  border-left: 1px solid var(--flix-line-primary-color);
}
.flix-nav-horizontal__link {
  color: var(--flix-content-secondary-color);
  font-size: var(--flix-font-size-primary);
  font-weight: var(--flix-font-weight-semibold);
  line-height: var(--flix-line-height-primary);
  justify-content: center;
  margin: var(--flix-spacing-1) var(--flix-spacing-2);
  padding: var(--flix-spacing-half);
  border-radius: var(--flix-primary-border-radius);
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: var(--flix-spacing-half);
}
.flix-nav-horizontal__link .flix-icon,
.flix-nav-horizontal__link [class^=hci-icon] {
  line-height: 1;
}
.flix-nav-horizontal__link .flix-icon::before,
.flix-nav-horizontal__link [class^=hci-icon]::before {
  margin: 0;
}
.flix-nav-horizontal__link:is(button) {
  border: 0;
  background: transparent;
  font-family: var(--flix-font-family-primary);
  cursor: pointer;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}
.flix-nav-horizontal__link:enabled:hover, .flix-nav-horizontal__link[href]:hover {
  background-image: var(--flix-hover-layer-color);
  text-decoration: none;
}
.flix-nav-horizontal__link:enabled:active {
  background-image: var(--flix-pressed-layer-color);
  text-decoration: none;
}
.flix-nav-horizontal__link:focus {
  outline: 2px solid var(--flix-focus-outline-color);
  outline-offset: 1px;
  box-shadow: 0 0 0 calc(var(--flix-spacing-half) + 1px) var(--flix-grayscale-0-color);
  border-radius: var(--flix-primary-border-radius);
}
.flix-nav-horizontal__link[aria-current], .flix-nav-horizontal__link[aria-selected=true] {
  color: var(--flix-content-primary-color);
}
.flix-nav-horizontal__link[aria-current]::before, .flix-nav-horizontal__link[aria-selected=true]::before {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 0;
  border-bottom: var(--flix-spacing-half) solid var(--flix-ui-primary-color);
}
.flix-nav-horizontal__panel:focus {
  outline: 2px solid var(--flix-focus-outline-color);
  outline-offset: 1px;
  box-shadow: 0 0 0 calc(var(--flix-spacing-half) + 1px) var(--flix-grayscale-0-color);
  border-radius: var(--flix-primary-border-radius);
}

.flix-nav-side__item, .flix-nav-side-subnav__item {
  display: block;
}
.flix-nav-side__link:is(button), .flix-nav-side-subnav__link:is(button) {
  font-size: var(--flix-font-size-primary);
  border: 0;
  background: transparent;
  font-family: var(--flix-font-family-primary);
  cursor: pointer;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}
.flix-nav-side__link, .flix-nav-side-subnav__link {
  color: var(--flix-content-secondary-color);
  font-size: var(--flix-font-size-primary);
  font-weight: var(--flix-font-weight-semibold);
  line-height: var(--flix-line-height-primary);
  position: relative;
  width: -webkit-fill-available;
  width: -moz-available;
  width: stretch;
  margin: var(--flix-spacing-half) 0;
  padding: var(--flix-spacing-half);
  border-radius: var(--flix-primary-border-radius);
  text-decoration: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: var(--flix-spacing-1);
}
.flix-nav-side__link .flix-icon, .flix-nav-side-subnav__link .flix-icon,
.flix-nav-side__link [class^=hci-icon],
.flix-nav-side-subnav__link [class^=hci-icon] {
  line-height: 1;
}
.flix-nav-side__link .flix-icon::before, .flix-nav-side-subnav__link .flix-icon::before,
.flix-nav-side__link [class^=hci-icon]::before,
.flix-nav-side-subnav__link [class^=hci-icon]::before {
  margin: 0;
}
.flix-nav-side__link:hover, .flix-nav-side-subnav__link:hover {
  background-image: var(--flix-hover-layer-color);
  text-decoration: none;
}
.flix-nav-side__link:enabled:active, .flix-nav-side-subnav__link:enabled:active {
  background-image: var(--flix-pressed-layer-color);
  text-decoration: none;
}
.flix-nav-side__link:focus, .flix-nav-side-subnav__link:focus {
  outline: 2px solid var(--flix-focus-outline-color);
  outline-offset: 0;
  box-shadow: 0 0 0 calc(var(--flix-spacing-half) + 0) var(--flix-grayscale-0-color);
}
.flix-nav-side__link[aria-current], [aria-current].flix-nav-side-subnav__link {
  padding-left: var(--flix-spacing-2);
  background-color: var(--flix-highlight-color);
  color: var(--flix-content-primary-color);
}
.flix-nav-side__link[aria-current]::before, [aria-current].flix-nav-side-subnav__link::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  border-left: var(--flix-spacing-half) solid var(--flix-ui-primary-color);
}
.flix-nav-side__link--has-subnav::after, .flix-nav-side__link[aria-expanded]::after, [aria-expanded].flix-nav-side-subnav__link::after {
  content: "";
  -webkit-mask: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M18.68 8.3a1 1 0 00-1.41 0l-5.28 5.28-5.26-5.27a1 1 0 00-1.4 0 1 1 0 000 1.41l5.96 5.99a1 1 0 001.41 0l5.98-6a1 1 0 000-1.42z' /%3E%3C/svg%3E") no-repeat center/var(--flix-spacing-4);
          mask: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M18.68 8.3a1 1 0 00-1.41 0l-5.28 5.28-5.26-5.27a1 1 0 00-1.4 0 1 1 0 000 1.41l5.96 5.99a1 1 0 001.41 0l5.98-6a1 1 0 000-1.42z' /%3E%3C/svg%3E") no-repeat center/var(--flix-spacing-4);
  width: var(--flix-spacing-4);
  height: var(--flix-spacing-4);
  background-color: var(--flix-icon-primary-color);
}
.flix-nav-side__link--has-subnav-open::after, .flix-nav-side__link[aria-expanded=true]::after, [aria-expanded=true].flix-nav-side-subnav__link::after {
  transform: rotate(180deg);
}
.flix-nav-side-subnav {
  padding-left: var(--flix-spacing-3);
}
.flix-nav-tab-bar {
  display: flex;
  position: fixed;
  z-index: 1000;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 0 var(--flix-spacing-1);
  background-color: var(--flix-box-bg-color);
  box-shadow: var(--flix-primary-box-shadow);
}
.flix-nav-tab-bar__tab {
  flex: 1;
  color: var(--flix-content-secondary-color);
}
.flix-nav-tab-bar__tab[aria-current] {
  position: relative;
  color: var(--flix-content-primary-color);
}
.flix-nav-tab-bar__tab[aria-current]::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: var(--flix-spacing-half);
  left: var(--flix-spacing-half);
  border-top: var(--flix-spacing-half) solid var(--flix-ui-primary-color);
}
.flix-nav-tab-bar .flix-nav-tab-bar__tab {
  padding: var(--flix-spacing-1);
}
.flix-nav-tab-bar .flix-nav-tab-bar__tab:focus {
  outline: 2px solid var(--flix-focus-outline-color);
  outline-offset: 0;
  box-shadow: 0 0 0 calc(var(--flix-spacing-half) + 0) var(--flix-grayscale-0-color);
}
.flix-nav-tab-bar__icon {
  width: min(max(var(--flix-spacing-4), 1.5rem), 2 * var(--flix-spacing-4));
  height: min(max(var(--flix-spacing-4), 1.5rem), 2 * var(--flix-spacing-4));
  font-size: min(max(var(--flix-spacing-4), 1.5rem), 2 * var(--flix-spacing-4));
  line-height: min(max(var(--flix-spacing-4), 1.5rem), 2 * var(--flix-spacing-4));
}
.flix-nav-tab-bar__icon:is([class*=flix-icon-])::before {
  margin: 0;
}

.flix-notification {
  display: flex;
  position: relative;
  align-items: center;
  width: 100%;
  padding: var(--flix-spacing-2);
  border-radius: var(--flix-primary-border-radius);
  background: var(--flix-box-bg-color);
  box-shadow: var(--flix-primary-box-shadow);
  color: var(--flix-content-primary-color);
  font-family: inherit;
  font-size: var(--flix-font-size-primary);
  font-weight: var(--flix-font-weight-bold);
  gap: var(--flix-spacing-2);
}
.flix-notification:where(:not(:last-child)) {
  margin-bottom: var(--flix-spacing-2);
}
.flix-notification::before, .flix-notification__close {
  flex-shrink: 0;
  align-self: flex-start;
}
.flix-notification::before {
  content: "";
  -webkit-mask: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M12 2a10 10 0 100 20 10 10 0 000-20zm1 15a1 1 0 01-2 0v-5.5a1 1 0 112 0V17zm-1-8a1.5 1.5 0 110-3 1.5 1.5 0 010 3z' /%3E%3C/svg%3E") no-repeat center/1.5rem;
          mask: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M12 2a10 10 0 100 20 10 10 0 000-20zm1 15a1 1 0 01-2 0v-5.5a1 1 0 112 0V17zm-1-8a1.5 1.5 0 110-3 1.5 1.5 0 010 3z' /%3E%3C/svg%3E") no-repeat center/1.5rem;
  width: 1.5rem;
  height: 1.5rem;
  background-color: var(--flix-neutral-color);
  min-width: var(--flix-spacing-4);
  max-width: calc(var(--flix-spacing-4) * 2);
  min-height: var(--flix-spacing-4);
  max-height: calc(var(--flix-spacing-4) * 2);
}
@supports (min-height: max(var(--flix-spacing-4), var(--flix-line-height-primary))) {
  .flix-notification::before {
    min-height: max(var(--flix-spacing-4), var(--flix-line-height-primary));
  }
}
.flix-notification--success::before {
  -webkit-mask: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M12 2a10 10 0 100 20 10 10 0 000-20zm4.89 7.7l-6 6a1 1 0 01-1.42 0l-2.36-2.35a1 1 0 111.42-1.41l1.65 1.65 5.3-5.3a1 1 0 111.4 1.42z' /%3E%3C/svg%3E") no-repeat center/1.5rem;
          mask: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M12 2a10 10 0 100 20 10 10 0 000-20zm4.89 7.7l-6 6a1 1 0 01-1.42 0l-2.36-2.35a1 1 0 111.42-1.41l1.65 1.65 5.3-5.3a1 1 0 111.4 1.42z' /%3E%3C/svg%3E") no-repeat center/1.5rem;
  width: 1.5rem;
  height: 1.5rem;
  background-color: var(--flix-success-color);
}
.flix-notification--warning::before {
  -webkit-mask: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M12 2a10 10 0 100 20 10 10 0 000-20zm-1 5a1 1 0 012 0v5.5a1 1 0 11-2 0V7zm1 11a1.5 1.5 0 110-3 1.5 1.5 0 010 3z' /%3E%3C/svg%3E") no-repeat center/1.5rem;
          mask: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M12 2a10 10 0 100 20 10 10 0 000-20zm-1 5a1 1 0 012 0v5.5a1 1 0 11-2 0V7zm1 11a1.5 1.5 0 110-3 1.5 1.5 0 010 3z' /%3E%3C/svg%3E") no-repeat center/1.5rem;
  width: 1.5rem;
  height: 1.5rem;
  background-color: var(--flix-warning-color);
}
.flix-notification--danger::before {
  -webkit-mask: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M12 2a10 10 0 100 20 10 10 0 000-20zm4.95 13.54a1 1 0 11-1.41 1.41L12 13.41l-3.54 3.54a1 1 0 11-1.41-1.41L10.59 12 7.05 8.46a1 1 0 111.41-1.41L12 10.59l3.54-3.54a1 1 0 111.41 1.41L13.41 12l3.54 3.54z' /%3E%3C/svg%3E") no-repeat center/1.5rem;
          mask: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M12 2a10 10 0 100 20 10 10 0 000-20zm4.95 13.54a1 1 0 11-1.41 1.41L12 13.41l-3.54 3.54a1 1 0 11-1.41-1.41L10.59 12 7.05 8.46a1 1 0 111.41-1.41L12 10.59l3.54-3.54a1 1 0 111.41 1.41L13.41 12l3.54 3.54z' /%3E%3C/svg%3E") no-repeat center/1.5rem;
  width: 1.5rem;
  height: 1.5rem;
  background-color: var(--flix-danger-color);
}
.flix-notification::before, .flix-notification--success::before, .flix-notification--warning::before, .flix-notification--danger::before {
  -webkit-mask-size: 100%;
          mask-size: 100%;
}
.flix-notification--toast {
  z-index: 1;
  animation: 4s sneak-peek-notification-top;
  opacity: 0;
  background: var(--flix-grayscale-100-color);
  color: var(--flix-grayscale-0-color);
}
.flix-notification--toast .flix-notification__content * {
  color: var(--flix-grayscale-0-color);
}
.flix-notification--toast .flix-notification__close::before {
  background-color: var(--flix-grayscale-0-color);
}
.flix-notification-container--bottom-left .flix-notification--toast, .flix-notification-container--bottom-right .flix-notification--toast {
  animation: 4s sneak-peek-notification-bottom;
}
.flix-notification__content {
  flex-grow: 1;
  min-width: 0;
  overflow-wrap: anywhere;
}
.flix-notification__close {
  margin: calc(-1 * var(--flix-spacing-1)) calc(-1 * var(--flix-spacing-1)) calc(-1 * var(--flix-spacing-1)) 0;
}
.flix-notification__close::before {
  content: "";
  -webkit-mask: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M13.41 12l6.37-6.36a1 1 0 10-1.42-1.42L12 10.6 5.64 4.22a1 1 0 10-1.42 1.42L10.6 12l-6.37 6.36a1 1 0 101.42 1.42L12 13.4l6.36 6.37a1 1 0 101.42-1.42L13.4 12z' /%3E%3C/svg%3E") no-repeat center/1.125rem;
          mask: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M13.41 12l6.37-6.36a1 1 0 10-1.42-1.42L12 10.6 5.64 4.22a1 1 0 10-1.42 1.42L10.6 12l-6.37 6.36a1 1 0 101.42 1.42L12 13.4l6.36 6.37a1 1 0 101.42-1.42L13.4 12z' /%3E%3C/svg%3E") no-repeat center/1.125rem;
  width: 1.125rem;
  height: 1.125rem;
  background-color: var(--flix-grayscale-100-color);
  min-width: var(--flix-spacing-3);
  max-width: calc(var(--flix-spacing-3) * 2);
  min-height: var(--flix-spacing-3);
  max-height: calc(var(--flix-spacing-3) * 2);
}
.flix-notification-container {
  position: fixed;
  z-index: 1209;
  width: 100%;
}
.flix-notification-container:not(:empty) {
  padding: var(--flix-spacing-2);
}
@media (min-width: 600px) {
  .flix-notification-container {
    width: auto;
  }
  .flix-notification-container:not(:empty) {
    padding: var(--flix-spacing-6);
  }
}
.flix-notification-container, .flix-notification-container--top-right {
  top: 0;
  right: 0;
}
.flix-notification-container--top-left {
  top: 0;
  right: auto;
  left: 0;
}
.flix-notification-container--bottom-left {
  top: auto;
  right: auto;
  bottom: 0;
  left: 0;
}
.flix-notification-container--bottom-right {
  top: auto;
  right: 0;
  bottom: 0;
}

@keyframes sneak-peek-notification-top {
  0% {
    transform: translateY(-var(--flix-spacing-4));
    opacity: 0;
  }
  7.5% {
    transform: translateY(0);
    opacity: 1;
  }
  92.5% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-var(--flix-spacing-4));
    opacity: 0;
  }
}
@keyframes sneak-peek-notification-bottom {
  0% {
    transform: translateY(var(--flix-spacing-4));
    opacity: 0;
  }
  7.5% {
    transform: translateY(0);
    opacity: 1;
  }
  92.5% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(var(--flix-spacing-4));
    opacity: 0;
  }
}
.flix-overlay {
  position: fixed;
  z-index: 1100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--flix-overlay-bg-color);
}

.flix-page-container {
  min-width: var(--flix-page-min-width);
  max-width: var(--flix-page-max-width);
  margin: 0 auto;
  padding: 0 var(--flix-spacing-2);
}
.flix-page-container--has-fixed-header, .flix-page-container--has-fixed-header-no-nav {
  padding-top: var(--flix-spacing-10);
}
@media (min-width: 768px) and (max-width: 1199px) {
  .flix-page-container--has-fixed-header {
    padding-top: calc(var(--flix-spacing-10) + var(--flix-spacing-8));
  }
}

.flix-pager__list {
  display: inline-flex;
  flex-wrap: wrap;
  max-width: 100%;
  list-style: none;
}
.flix-pager__item {
  min-width: var(--flix-spacing-8);
  height: var(--flix-spacing-8);
  margin: -1px -1px 0 0;
  border: 1px solid var(--flix-input-border-color);
  text-align: center;
}
@media (min-width: 1200px) {
  .flix-pager__item {
    min-width: var(--flix-spacing-6);
    height: var(--flix-spacing-6);
  }
}
.flix-pager__item:first-child {
  border-radius: var(--flix-primary-border-radius) 0 0 var(--flix-primary-border-radius);
}
.flix-pager__item:last-child {
  margin-right: 0;
  border-radius: 0 var(--flix-primary-border-radius) var(--flix-primary-border-radius) 0;
}
.flix-pager__link {
  color: var(--flix-content-primary-color);
  font-size: var(--flix-font-size-primary);
  font-weight: var(--flix-font-weight-semibold);
  line-height: var(--flix-line-height-primary);
  padding: 0;
  font-size: var(--flix-font-size-primary);
  border: 0;
  background: transparent;
  font-family: var(--flix-font-family-primary);
  cursor: pointer;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: var(--flix-spacing-half) var(--flix-spacing-1);
  color: var(--flix-content-primary-color);
  text-decoration: none;
}
.flix-pager__link:focus {
  outline: 2px solid var(--flix-focus-outline-color);
  outline-offset: 1px;
  box-shadow: 0 0 0 calc(var(--flix-spacing-half) + 1px) var(--flix-grayscale-0-color);
  border-radius: var(--flix-primary-border-radius);
  text-decoration: none;
}
.flix-pager__link:active {
  background-image: var(--flix-pressed-layer-color);
  text-decoration: none;
  text-decoration: none;
}
.flix-pager__link:hover {
  background-image: var(--flix-hover-layer-color);
  text-decoration: none;
}
.flix-pager__link[aria-current] {
  padding-bottom: 0;
  border-bottom: var(--flix-spacing-half) solid var(--flix-ui-primary-color);
  background-color: var(--flix-highlight-color);
}
.flix-pager__link--disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.flix-pager__link--disabled:hover {
  background: transparent;
  color: var(--flix-content-primary-color);
}
.flix-pager__link--prev, .flix-pager__link--next {
  font-size: 0;
}
.flix-pager__link--prev::after,
.flix-pager__link--prev .flix-pager__link--disabled:hover::after, .flix-pager__link--next::after,
.flix-pager__link--next .flix-pager__link--disabled:hover::after {
  content: "";
  -webkit-mask: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M15.7 11.3L9.72 5.32a1 1 0 00-1.42 0 1 1 0 000 1.42L13.58 12 8.3 17.27a1 1 0 000 1.41 1 1 0 001.41 0l5.99-5.97a1 1 0 000-1.41z' /%3E%3C/svg%3E") no-repeat center/var(--flix-spacing-4);
          mask: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M15.7 11.3L9.72 5.32a1 1 0 00-1.42 0 1 1 0 000 1.42L13.58 12 8.3 17.27a1 1 0 000 1.41 1 1 0 001.41 0l5.99-5.97a1 1 0 000-1.41z' /%3E%3C/svg%3E") no-repeat center/var(--flix-spacing-4);
  width: var(--flix-spacing-4);
  height: var(--flix-spacing-4);
  background-color: var(--flix-icon-primary-color);
}
.flix-pager__link--prev::after,
.flix-pager__link--prev .flix-pager__link--disabled:hover::after {
  transform: rotate(180deg);
}
.flix-pager__link--prev:hover::after {
  transform: rotate(180deg);
}
.flix-pager--content-fit .flix-pager__item {
  height: auto;
}
.flix-pager--content-fit .flix-pager__link {
  padding: var(--flix-spacing-1) var(--flix-spacing-2);
}
.flix-pager--content-fit .flix-pager__link--prev, .flix-pager--content-fit .flix-pager__link--next {
  padding: var(--flix-spacing-1);
}

.flix-panel {
  position: fixed;
  z-index: 1209;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateY(100%);
  transition: transform 62.5ms 62.5ms, opacity 125ms;
  opacity: 0;
}
.flix-panel:not([aria-modal=true]) {
  pointer-events: none;
}
.flix-panel--active {
  transform: translateY(0);
  transition: transform 125ms, opacity 62.5ms 62.5ms;
  opacity: 1;
}
.flix-panel__body {
  display: flex;
  position: absolute;
  z-index: 1209;
  top: 0;
  right: 0;
  bottom: 0;
  flex-direction: column;
  justify-content: stretch;
  width: 85%;
  max-width: 23.75em;
  overflow: hidden;
  transform: translate(100%, 0);
  border-radius: var(--flix-primary-border-radius) 0 0 var(--flix-primary-border-radius);
  background: var(--flix-box-bg-color);
  box-shadow: var(--flix-primary-box-shadow);
  pointer-events: all;
  transition: transform 250ms ease;
}
.flix-panel--left .flix-panel__body {
  right: auto;
  left: 0;
  transform: translate(-100%, 0);
  border-radius: 0 var(--flix-primary-border-radius) var(--flix-primary-border-radius) 0;
}
.flix-panel--bottom .flix-panel__body {
  top: auto;
  width: 100%;
  max-width: 100%;
  max-height: 85vh;
  transform: translate(0, 100%);
  border-radius: var(--flix-primary-border-radius) var(--flix-primary-border-radius) 0 0;
}
.flix-panel--full .flix-panel__body {
  width: 100%;
  max-width: 100%;
  border-radius: 0;
}
.flix-panel--full.flix-panel--bottom .flix-panel__body {
  height: 100vh;
}
.flix-panel--active .flix-panel__body {
  transform: translate(0, 0);
  transition: transform 250ms 250ms ease;
}
.flix-panel__header {
  display: flex;
  z-index: 1000;
  align-items: center;
  padding: var(--flix-spacing-2);
  border-bottom: 1px solid var(--flix-line-primary-color);
  gap: var(--flix-spacing-2);
}
@media (min-width: 600px) {
  .flix-panel__header {
    padding: var(--flix-spacing-2) var(--flix-spacing-4);
  }
}
.flix-panel__title {
  flex: 1;
  align-self: center;
}
.flix-panel__back, .flix-panel__close {
  flex-grow: 0;
  flex-shrink: 0;
}
.flix-panel__back {
  margin-left: calc(-1 * var(--flix-spacing-1));
}
.flix-panel__close {
  margin-right: calc(-1 * var(--flix-spacing-1));
}
.flix-panel__close::before {
  content: "";
  -webkit-mask: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M13.41 12l6.37-6.36a1 1 0 10-1.42-1.42L12 10.6 5.64 4.22a1 1 0 10-1.42 1.42L10.6 12l-6.37 6.36a1 1 0 101.42 1.42L12 13.4l6.36 6.37a1 1 0 101.42-1.42L13.4 12z' /%3E%3C/svg%3E") no-repeat center/1.5rem;
          mask: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M13.41 12l6.37-6.36a1 1 0 10-1.42-1.42L12 10.6 5.64 4.22a1 1 0 10-1.42 1.42L10.6 12l-6.37 6.36a1 1 0 101.42 1.42L12 13.4l6.36 6.37a1 1 0 101.42-1.42L13.4 12z' /%3E%3C/svg%3E") no-repeat center/1.5rem;
  width: 1.5rem;
  height: 1.5rem;
  background-color: var(--flix-icon-primary-color);
  min-width: var(--flix-spacing-4);
  max-width: calc(var(--flix-spacing-4) * 2);
  min-height: var(--flix-spacing-4);
  max-height: calc(var(--flix-spacing-4) * 2);
}
.flix-panel__content {
  overflow-y: auto;
  background: linear-gradient(var(--flix-box-bg-color), rgba(255, 255, 255, 0)), linear-gradient(rgba(255, 255, 255, 0), var(--flix-box-bg-color)), linear-gradient(var(--flix-box-shadow-subtle-color), rgba(255, 255, 255, 0)), linear-gradient(rgba(255, 255, 255, 0), var(--flix-box-shadow-subtle-color));
  background-attachment: local, local, scroll, scroll;
  background-repeat: no-repeat;
  background-position: 0 0, 0 100%, 0 0, 0 100%;
  background-size: 100% var(--flix-spacing-4), 100% var(--flix-spacing-4), 100% var(--flix-spacing-1), 100% var(--flix-spacing-1);
  flex-basis: 100%;
  padding: var(--flix-spacing-2);
}
.flix-panel--bottom .flix-panel__content {
  flex-basis: auto;
}
.flix-panel--full.flix-panel--bottom .flix-panel__content {
  flex-basis: 100%;
}
@media (min-width: 600px) {
  .flix-panel__content {
    padding: var(--flix-spacing-4);
  }
}
.flix-panel__content:focus-visible {
  outline: 2px solid var(--flix-focus-outline-color);
  outline-offset: -2px;
  box-shadow: 0 0 0 calc(var(--flix-spacing-half) - 2px) var(--flix-grayscale-0-color);
}
.flix-panel__footer {
  display: flex;
  flex-shrink: 0;
  align-items: baseline;
  justify-content: space-between;
  min-height: var(--flix-input-height-mobile);
  padding: var(--flix-spacing-2);
  gap: var(--flix-spacing-2);
}
.flix-panel__footer--justify-start {
  justify-content: flex-start;
}
.flix-panel__footer--justify-end {
  justify-content: flex-end;
}
.flix-panel__footer--justify-center {
  justify-content: center;
}
@media (min-width: 600px) {
  .flix-panel__footer {
    min-height: var(--flix-input-height-desktop);
    padding: var(--flix-spacing-2) var(--flix-spacing-4);
  }
}
.flix-panel__footer .flix-btn:not(.flix-panel__footer .flix-btn--block), .flix-panel__footer .flix-skip-link:not(.flix-panel__footer .flix-btn--block) {
  width: auto;
}
.flix-panel__footer-column {
  flex-basis: 100%;
  text-align: center;
}
.flix-panel__footer-column--narrow {
  flex-basis: auto;
}

.flix-popup {
  display: flex;
  place-items: center;
  place-content: center;
  position: fixed;
  z-index: 1100;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  transition: opacity 250ms ease;
}
.flix-popup[hidden] {
  display: none;
}
.flix-popup--active {
  opacity: 1;
}
.flix-popup__body {
  display: flex;
  z-index: 1101;
  flex-direction: column;
  width: 95%;
  max-width: 600px;
  max-height: 80vh;
  padding: 0 var(--flix-spacing-4) var(--flix-spacing-4);
  overflow: hidden;
  border-radius: var(--flix-primary-border-radius);
  background: var(--flix-box-bg-color);
  text-align: center;
  scale: 0.95;
  transition: scale 250ms ease;
}
@media (min-width: 390px) {
  .flix-popup__body {
    width: 80%;
  }
}
.flix-popup--active .flix-popup__body {
  scale: 1;
}
.flix-popup__title {
  color: var(--flix-container-content-color, var(--flix-heading-font-color));
  font-size: var(--flix-font-size-h1);
  font-weight: var(--flix-font-weight-bold);
  line-height: var(--flix-line-height-h1);
}
.flix-popup__icon, .flix-popup__title, .flix-popup__content {
  margin: 0 0 var(--flix-spacing-2);
}
.flix-popup__icon:first-child, .flix-popup__title:first-child, .flix-popup__content:first-child {
  padding-top: var(--flix-spacing-4);
}
.flix-popup__content .flix-popup__icon:first-child, .flix-popup__content .flix-popup__title:first-child {
  padding-top: 0;
}
.flix-popup__content {
  color: var(--flix-container-content-color, var(--flix-content-primary-color));
  font-size: var(--flix-font-size-primary);
  font-weight: var(--flix-font-weight-normal);
  line-height: var(--flix-line-height-primary);
  overflow-y: auto;
  background: linear-gradient(var(--flix-box-bg-color), rgba(255, 255, 255, 0)), linear-gradient(rgba(255, 255, 255, 0), var(--flix-box-bg-color)), linear-gradient(var(--flix-box-shadow-subtle-color), rgba(255, 255, 255, 0)), linear-gradient(rgba(255, 255, 255, 0), var(--flix-box-shadow-subtle-color));
  background-attachment: local, local, scroll, scroll;
  background-repeat: no-repeat;
  background-position: 0 0, 0 100%, 0 0, 0 100%;
  background-size: 100% var(--flix-spacing-4), 100% var(--flix-spacing-4), 100% var(--flix-spacing-1), 100% var(--flix-spacing-1);
  margin-right: calc(var(--flix-spacing-4) * -1);
  margin-left: calc(var(--flix-spacing-4) * -1);
  padding-right: var(--flix-spacing-4);
  padding-left: var(--flix-spacing-4);
}
.flix-popup__content:focus-visible {
  outline: 2px solid var(--flix-focus-outline-color);
  outline-offset: -2px;
  box-shadow: 0 0 0 calc(var(--flix-spacing-half) - 2px) var(--flix-grayscale-0-color);
}
.flix-popup__actions {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  gap: var(--flix-spacing-2);
}

.flix-progress-tracker {
  display: flex;
  justify-content: center;
  list-style-type: none;
  counter-reset: hc-progress-tracker-counter;
}
.flix-progress-tracker__item {
  position: relative;
  height: var(--flix-spacing-6);
  border: solid var(--flix-ui-primary-color);
  border-width: 1px 0;
  background: var(--flix-highlight-color);
  counter-increment: hc-progress-tracker-counter;
}
.flix-progress-tracker__item:first-child {
  border-left-width: 1px;
  border-radius: var(--flix-primary-border-radius) 0 0 var(--flix-primary-border-radius);
}
.flix-progress-tracker__item:last-child {
  border-right-width: 1px;
  border-radius: 0 var(--flix-primary-border-radius) var(--flix-primary-border-radius) 0;
}
.flix-progress-tracker__item[aria-current]::before {
  content: "";
  position: absolute;
  z-index: 2;
  right: calc(-1 * var(--flix-spacing-half));
  bottom: 0;
  left: 0;
  height: var(--flix-spacing-half);
  background: var(--flix-ui-primary-color);
  -webkit-clip-path: polygon(var(--flix-spacing-half) 0, 100% 0, calc(100% - var(--flix-spacing-half)) 100%, 0 100%);
          clip-path: polygon(var(--flix-spacing-half) 0, 100% 0, calc(100% - var(--flix-spacing-half)) 100%, 0 100%);
}
.flix-progress-tracker__item[aria-current]:first-child::before {
  -webkit-clip-path: polygon(0 0, 100% 0, calc(100% - var(--flix-spacing-half)) 100%, var(--flix-spacing-half) 100%);
          clip-path: polygon(0 0, 100% 0, calc(100% - var(--flix-spacing-half)) 100%, var(--flix-spacing-half) 100%);
}
.flix-progress-tracker__item[aria-current]:last-child::before {
  right: 0;
}
.flix-progress-tracker__item[aria-current], .flix-progress-tracker__item[aria-current] ~ .flix-progress-tracker__item {
  background: var(--flix-bg-primary-color);
}
.flix-progress-tracker__item[aria-current] .flix-progress-tracker__link::after, .flix-progress-tracker__item[aria-current] ~ .flix-progress-tracker__item .flix-progress-tracker__link::after {
  background-color: var(--flix-bg-primary-color);
}
.flix-progress-tracker__item[aria-current] ~ .flix-progress-tracker__item {
  border-color: var(--flix-line-primary-color);
  color: var(--flix-content-secondary-color);
}
.flix-progress-tracker__item[aria-current] ~ .flix-progress-tracker__item .flix-progress-tracker__link::after {
  border-color: var(--flix-line-primary-color);
}
.flix-progress-tracker__link {
  display: flex;
  justify-content: center;
  min-width: calc(var(--flix-spacing-7) * 2);
  height: 100%;
  padding: 0 var(--flix-spacing-3);
  overflow: hidden;
  color: var(--flix-content-primary-color);
  font-weight: var(--flix-font-weight-semibold);
  line-height: var(--flix-spacing-6);
  text-align: center;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.flix-progress-tracker__link::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: 13%;
  right: calc(-1 * var(--flix-spacing-2) - 1px);
  width: calc((var(--flix-spacing-6) + 1px) * 0.7071067812);
  height: calc((var(--flix-spacing-6) + 1px) * 0.7071067812);
  transform: rotate(45deg);
  border: solid var(--flix-ui-primary-color);
  border-width: 1px 1px 0 0;
  background-color: var(--flix-highlight-color);
  -webkit-clip-path: polygon(100% 0, 0 0, 100% 100%);
          clip-path: polygon(100% 0, 0 0, 100% 100%);
}
@media (min-width: 600px) {
  .flix-progress-tracker__link {
    min-width: calc(var(--flix-spacing-12) * 2);
  }
}
.flix-progress-tracker__link:is(a, button) {
  padding: 0 var(--flix-spacing-3);
  font-size: var(--flix-font-size-primary);
  border: 0;
  background: transparent;
  font-family: var(--flix-font-family-primary);
  cursor: pointer;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}
.flix-progress-tracker__link:is(a, button):hover {
  background-image: var(--flix-hover-layer-color);
  text-decoration: none;
}
.flix-progress-tracker__link:is(a, button):enabled:active {
  background-image: var(--flix-pressed-layer-color);
  text-decoration: none;
}
.flix-progress-tracker__link:is(a, button):focus {
  outline: 2px solid var(--flix-focus-outline-color);
  outline-offset: 1px;
  box-shadow: 0 0 0 calc(var(--flix-spacing-half) + 1px) var(--flix-grayscale-0-color);
  border-radius: var(--flix-primary-border-radius);
}
.flix-progress-tracker__link:is(a, button):hover::after {
  background-image: var(--flix-hover-layer-color);
}
.flix-progress-tracker__link:is(a, button):active::after {
  background-image: var(--flix-pressed-layer-color);
}
.flix-progress-tracker__item:last-child .flix-progress-tracker__link::after {
  display: none;
}
.flix-progress-tracker__item:not(:first-child) .flix-progress-tracker__link {
  padding-left: var(--flix-spacing-5);
}
.flix-progress-tracker__text {
  display: flex;
  align-items: center;
  overflow: hidden;
  column-gap: var(--flix-spacing-half);
  font-size: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
}
@media (min-width: 600px) {
  .flix-progress-tracker__text {
    align-items: center;
    font-size: var(--flix-font-size-primary);
  }
}
.flix-progress-tracker__text::before {
  content: counter(hc-progress-tracker-counter);
  font-size: var(--flix-font-size-primary);
}
@media (min-width: 600px) {
  .flix-progress-tracker__text::before {
    content: counter(hc-progress-tracker-counter) ".";
  }
}
.flix-progress-tracker__item--completed .flix-progress-tracker__text::after {
  content: "";
  -webkit-mask: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M21.7 6.2l-13 13a1 1 0 01-1.4 0l-5-5a1 1 0 111.4-1.4L8 17.08l12.3-12.3a1 1 0 111.4 1.42z' /%3E%3C/svg%3E") no-repeat center/var(--flix-spacing-3);
          mask: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M21.7 6.2l-13 13a1 1 0 01-1.4 0l-5-5a1 1 0 111.4-1.4L8 17.08l12.3-12.3a1 1 0 111.4 1.42z' /%3E%3C/svg%3E") no-repeat center/var(--flix-spacing-3);
  width: var(--flix-spacing-3);
  height: var(--flix-spacing-3);
  background-color: var(--flix-ui-primary-color);
  display: inline-block;
  vertical-align: middle;
}

.flix-quantity__info:not(:empty) {
  color: var(--flix-container-content-color, var(--flix-content-primary-color));
  font-size: var(--flix-font-size-small);
  font-weight: var(--flix-font-weight-normal);
  line-height: var(--flix-line-height-small);
  display: block;
  margin-top: var(--flix-spacing-1);
}
.flix-quantity__info:not(:empty) ~ .flix-quantity__info {
  margin-top: var(--flix-spacing-half);
}
.flix-quantity--error .flix-quantity__info {
  color: var(--flix-danger-dark-color);
}
.flix-quantity__info ~ .flix-quantity__info {
  color: var(--flix-content-primary-color);
}
.flix-quantity__label {
  display: block;
  margin-bottom: var(--flix-spacing-half);
  color: var(--flix-content-primary-color);
}
@media (min-width: 1200px) {
  .flix-quantity__label {
    color: var(--flix-content-secondary-color);
  }
}
.flix-quantity--disabled {
  opacity: var(--flix-disabled-element-opacity);
}
.flix-quantity--disabled .flix-quantity__input,
.flix-quantity--disabled .flix-quantity__button {
  opacity: 1;
  cursor: not-allowed;
}
.flix-quantity--error .flix-quantity__input {
  border-color: var(--flix-danger-color);
  background-image: linear-gradient(var(--flix-bg-primary-transparent-color), var(--flix-bg-primary-transparent-color)), linear-gradient(var(--flix-danger-color), var(--flix-danger-color));
}
.flix-quantity--error .flix-quantity__input:enabled:hover {
  background-image: var(--flix-hover-layer-color), linear-gradient(var(--flix-bg-primary-transparent-color), var(--flix-bg-primary-transparent-color)), linear-gradient(var(--flix-danger-color), var(--flix-danger-color));
}
.flix-quantity__picker {
  display: flex;
  align-items: stretch;
}
.flix-quantity__picker + .flix-quantity__info {
  margin-top: var(--flix-spacing-1);
}
.flix-quantity__input {
  position: relative;
  width: 100%;
  min-height: 100%;
  padding: calc(var(--flix-spacing-2) - var(--flix-spacing-half) - 1px) var(--flix-spacing-2);
  border: 0;
  background: transparent;
  color: var(--flix-content-primary-color);
  font-family: inherit;
  font-size: var(--flix-font-size-primary);
  font-weight: var(--flix-font-weight-normal);
  line-height: var(--flix-line-height-primary);
  text-overflow: ellipsis;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  align-self: center;
  width: 60px;
  min-height: var(--flix-input-height-mobile);
  border: 1px solid var(--flix-input-border-color);
  border-radius: 0;
  background-color: var(--flix-box-bg-color);
  text-align: center;
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -moz-appearance: textfield;
}
@media (min-width: 1200px) {
  .flix-quantity__input {
    padding-top: calc(var(--flix-spacing-1) - 1px);
    padding-bottom: calc(var(--flix-spacing-1) - 1px);
    min-height: var(--flix-input-height-desktop);
  }
}
.flix-quantity__input::placeholder {
  opacity: 1;
  color: var(--flix-content-secondary-color);
  font-style: italic;
}
.flix-quantity__input:enabled:hover, .flix-quantity__input[href]:hover {
  background-image: var(--flix-hover-layer-color);
  text-decoration: none;
}
.flix-quantity__input::-webkit-inner-spin-button, .flix-quantity__input::-webkit-outer-spin-button {
  margin: 0;
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-appearance: none;
}
.flix-quantity--active .flix-quantity__input, .flix-quantity__input:focus {
  outline: 2px solid var(--flix-focus-outline-color);
  outline-offset: 1px;
  box-shadow: 0 0 0 calc(var(--flix-spacing-half) + 1px) var(--flix-grayscale-0-color);
  border-radius: var(--flix-primary-border-radius);
  z-index: 2;
}
.flix-quantity__input[readonly] {
  border-width: 0 1px;
  font-weight: var(--flix-font-weight-bold);
}
.flix-quantity__input[readonly]:focus {
  border-radius: 0;
  outline: none;
  box-shadow: none;
}
.flix-quantity .flix-quantity__button {
  z-index: 1;
  border-color: var(--flix-input-border-color);
}
.flix-quantity .flix-quantity__button::after {
  content: "";
}
.flix-quantity .flix-quantity__button:first-of-type::after, .flix-quantity .flix-quantity__button--minus::after {
  -webkit-mask: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M21 11H3a1 1 0 000 2h18a1 1 0 000-2z' /%3E%3C/svg%3E") no-repeat center/var(--flix-spacing-4);
          mask: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M21 11H3a1 1 0 000 2h18a1 1 0 000-2z' /%3E%3C/svg%3E") no-repeat center/var(--flix-spacing-4);
  width: var(--flix-spacing-4);
  height: var(--flix-spacing-4);
  background-color: var(--flix-icon-primary-color);
}
.flix-quantity .flix-quantity__button:first-of-type {
  border-right: 0;
  border-radius: var(--flix-primary-border-radius) 0 0 var(--flix-primary-border-radius);
}
.flix-quantity .flix-quantity__button:last-of-type::after, .flix-quantity .flix-quantity__button--plus::after {
  -webkit-mask: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M21 11h-8V3a1 1 0 00-2 0v8H3a1 1 0 000 2h8v8a1 1 0 002 0v-8h8a1 1 0 000-2z' /%3E%3C/svg%3E") no-repeat center/var(--flix-spacing-4);
          mask: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M21 11h-8V3a1 1 0 00-2 0v8H3a1 1 0 000 2h8v8a1 1 0 002 0v-8h8a1 1 0 000-2z' /%3E%3C/svg%3E") no-repeat center/var(--flix-spacing-4);
  width: var(--flix-spacing-4);
  height: var(--flix-spacing-4);
  background-color: var(--flix-icon-primary-color);
}
.flix-quantity .flix-quantity__button:last-of-type {
  border-left: 0;
  border-radius: 0 var(--flix-primary-border-radius) var(--flix-primary-border-radius) 0;
}
.flix-quantity--inline {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: var(--flix-spacing-half) var(--flix-spacing-1);
}
.flix-quantity--inline .flix-quantity__label {
  flex: 1;
  align-self: center;
  margin-bottom: 0;
  color: var(--flix-content-primary-color);
  font-size: var(--flix-font-size-primary);
  font-weight: var(--flix-font-weight-bold);
  line-height: var(--flix-line-height-primary);
}
.flix-quantity--inline .flix-quantity__label small {
  display: block;
  font-size: var(--flix-font-size-small);
  font-weight: var(--flix-font-weight-normal);
  line-height: var(--flix-line-height-small);
}
.flix-quantity--inline .flix-quantity__picker {
  justify-content: end;
}
.flix-quantity--inline .flix-quantity__picker + .flix-quantity__info:not(:empty) {
  margin-top: var(--flix-spacing-half);
}
.flix-quantity--inline .flix-quantity__info {
  width: 100%;
  text-align: right;
}
.flix-quantity--inline .flix-quantity__info:not(:empty) {
  margin-top: 0;
}

.flix-radio {
  position: relative;
}
.flix-radio--sm .flix-radio__label {
  min-height: var(--flix-spacing-3);
  border-radius: var(--flix-primary-border-radius);
}
.flix-radio--sm .flix-radio__label:hover {
  background-image: var(--flix-hover-layer-color);
  text-decoration: none;
}
.flix-radio--sm .flix-radio__label::after {
  -webkit-mask-size: 56%;
          mask-size: 56%;
}
.flix-radio--sm .flix-radio__input, .flix-radio--sm .flix-radio__label::before, .flix-radio--sm .flix-radio__label::after {
  width: var(--flix-spacing-3);
  height: var(--flix-spacing-3);
  margin-top: calc((var(--flix-line-height-primary) - var(--flix-spacing-3)) / 2);
}
@supports (margin-top: max(0px, calc((var(--flix-line-height-primary) - var(--flix-spacing-3)) / 2))) {
  .flix-radio--sm .flix-radio__input, .flix-radio--sm .flix-radio__label::before, .flix-radio--sm .flix-radio__label::after {
    margin-top: max(0px, (var(--flix-line-height-primary) - var(--flix-spacing-3)) / 2);
  }
}
.flix-radio__label {
  display: inline-flex;
  position: relative;
  align-items: center;
  min-height: var(--flix-spacing-5);
  margin: calc(-1 * var(--flix-spacing-half));
  padding: var(--flix-spacing-half);
  border-radius: var(--flix-primary-border-radius);
  color: var(--flix-content-primary-color);
  font-family: inherit;
  vertical-align: middle;
  cursor: pointer;
  gap: var(--flix-spacing-2);
}
.flix-radio__label::before, .flix-radio__label::after {
  content: "";
  display: block;
  flex-shrink: 0;
  align-self: flex-start;
}
.flix-radio__label::before {
  width: var(--flix-spacing-5);
  height: var(--flix-spacing-5);
  border: 2px solid var(--flix-input-border-color);
  border-radius: 100%;
  transition: border-color 250ms ease;
  margin-top: calc((var(--flix-line-height-primary) - var(--flix-spacing-5)) / 2);
}
@supports (margin-top: max(0px, calc((var(--flix-line-height-primary) - var(--flix-spacing-5)) / 2))) {
  .flix-radio__label::before {
    margin-top: max(0px, (var(--flix-line-height-primary) - var(--flix-spacing-5)) / 2);
  }
}
.flix-radio__label::after {
  -webkit-mask: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M12 2a10.01 10.01 0 000 20 10.01 10.01 0 000-20z' /%3E%3C/svg%3E") no-repeat center/var(--flix-spacing-5);
          mask: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M12 2a10.01 10.01 0 000 20 10.01 10.01 0 000-20z' /%3E%3C/svg%3E") no-repeat center/var(--flix-spacing-5);
  width: var(--flix-spacing-5);
  height: var(--flix-spacing-5);
  background-color: var(--flix-input-border-color);
  position: absolute;
  top: var(--flix-spacing-half);
  left: var(--flix-spacing-half);
  -webkit-mask-size: 66%;
          mask-size: 66%;
  transform: scale(0);
  pointer-events: none;
  transition: transform 250ms ease, background-color 250ms ease;
  margin-top: calc((var(--flix-line-height-primary) - var(--flix-spacing-5)) / 2);
}
@supports (margin-top: max(0px, calc((var(--flix-line-height-primary) - var(--flix-spacing-5)) / 2))) {
  .flix-radio__label::after {
    margin-top: max(0px, (var(--flix-line-height-primary) - var(--flix-spacing-5)) / 2);
  }
}
.flix-radio__input {
  width: var(--flix-spacing-5);
  height: var(--flix-spacing-5);
  margin-top: calc((var(--flix-line-height-primary) - var(--flix-spacing-5)) / 2);
}
@supports (margin-top: max(0px, calc((var(--flix-line-height-primary) - var(--flix-spacing-5)) / 2))) {
  .flix-radio__input {
    margin-top: max(0px, (var(--flix-line-height-primary) - var(--flix-spacing-5)) / 2);
  }
}
.flix-radio__input:checked + .flix-radio__label::after {
  transform: scale(1);
}
.flix-radio__input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.flix-radio__input:focus + .flix-radio__label::before {
  outline: 2px solid var(--flix-focus-outline-color);
  outline-offset: 1px;
  box-shadow: 0 0 0 calc(var(--flix-spacing-half) + 1px) var(--flix-grayscale-0-color);
}
.flix-radio__input:enabled + .flix-radio__label:hover, .flix-radio__input:enabled:hover + .flix-radio__label {
  background-image: var(--flix-hover-layer-color);
  text-decoration: none;
}
.flix-radio__input:checked + .flix-radio__label::before {
  border-color: var(--flix-ui-primary-color);
}
.flix-radio__input:checked + .flix-radio__label::after {
  background-color: var(--flix-ui-primary-color);
}
.flix-radio--disabled .flix-radio__label {
  opacity: var(--flix-disabled-element-opacity);
  cursor: not-allowed;
}
.flix-radio--error .flix-radio__label::before {
  border-color: var(--flix-danger-color);
}
.flix-radio--error .flix-radio__input:not(:checked) + .flix-radio__label::before {
  box-shadow: inset 0 0 0 100vh var(--flix-bg-primary-transparent-color), inset 0 0 0 100vh var(--flix-danger-color);
  transition: box-shadow 250ms ease;
}
.flix-radio--error .flix-radio__input:focus:not(:checked) + .flix-radio__label::before {
  box-shadow: inset 0 0 0 100vh var(--flix-bg-primary-transparent-color), inset 0 0 0 100vh var(--flix-danger-color), 0 0 0 4px var(--flix-grayscale-0-color);
}
.flix-radio__info:not(:empty) {
  color: var(--flix-container-content-color, var(--flix-content-primary-color));
  font-size: var(--flix-font-size-small);
  font-weight: var(--flix-font-weight-normal);
  line-height: var(--flix-line-height-small);
  display: block;
  margin-top: var(--flix-spacing-1);
}
.flix-radio__info:not(:empty) ~ .flix-radio__info {
  margin-top: var(--flix-spacing-half);
}
.flix-radio--error .flix-radio__info {
  color: var(--flix-danger-dark-color);
}
.flix-radio__info ~ .flix-radio__info {
  color: var(--flix-content-primary-color);
}

.flix-sr-only, .flix-range__label--hidden, .flix-sr-only-focusable:not(:focus), .flix-skip-link:not(:focus) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  margin: -1px !important;
  padding: 0 !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  border-width: 0 !important;
  opacity: 0 !important;
  white-space: nowrap !important;
  -webkit-clip-path: inset(50%) !important;
          clip-path: inset(50%) !important;
}

.flix-sr-only-focusable, .flix-skip-link {
  transition: opacity 250ms ease;
  opacity: 1 !important;
}
.flix-range {
  position: relative;
  font-size: 0;
}
.flix-range__info:not(:empty) {
  color: var(--flix-container-content-color, var(--flix-content-primary-color));
  font-size: var(--flix-font-size-small);
  font-weight: var(--flix-font-weight-normal);
  line-height: var(--flix-line-height-small);
  display: block;
  margin-top: var(--flix-spacing-1);
}
.flix-range__info:not(:empty) ~ .flix-range__info {
  margin-top: var(--flix-spacing-half);
}
.flix-range--error .flix-range__info {
  color: var(--flix-danger-dark-color);
}
.flix-range__info ~ .flix-range__info {
  color: var(--flix-content-primary-color);
}
.flix-range__label {
  display: block;
  color: var(--flix-content-primary-color);
  font-size: var(--flix-font-size-primary);
  word-break: break-all;
}
@media (min-width: 1200px) {
  .flix-range__label {
    color: var(--flix-content-secondary-color);
  }
}
.flix-range__value {
  color: var(--flix-container-content-color, var(--flix-content-primary-color));
  font-size: var(--flix-font-size-small);
  font-weight: var(--flix-font-weight-normal);
  line-height: var(--flix-line-height-small);
  display: inline-block;
  width: 50%;
  margin-bottom: var(--flix-spacing-half);
  word-break: break-all;
}
.flix-range__value--max {
  text-align: right;
}
.flix-range__field {
  position: relative;
  z-index: 1;
  height: var(--flix-spacing-5);
  overflow: visible;
}
.flix-range__field::before {
  content: "";
  display: block;
  position: absolute;
  z-index: 0;
  top: 50%;
  right: calc(var(--flix-spacing-5) / 2);
  left: calc(var(--flix-spacing-5) / 2);
  height: 2px;
  margin-top: -1px;
  border-radius: var(--flix-primary-border-radius);
  background: var(--flix-input-border-color);
}
.flix-range [type=range] {
  position: absolute;
  z-index: 5;
  bottom: 0;
  left: 0;
  width: 100%;
  height: var(--flix-spacing-5);
  margin: 0;
  padding: 0;
  overflow: visible;
  outline: none;
  background: transparent;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}
.flix-range [type=range]::-webkit-slider-runnable-track {
  border: 0;
  background: transparent;
  border: 0 solid var(--flix-ui-primary-color);
}
.flix-range [type=range]::-webkit-slider-thumb {
  box-sizing: border-box;
  width: var(--flix-spacing-5);
  height: var(--flix-spacing-5);
  -webkit-transition: border 250ms ease, background 250ms ease;
  transition: border 250ms ease, background 250ms ease;
  border: var(--flix-spacing-half) solid var(--flix-ui-primary-color);
  border-radius: 100%;
  background: var(--flix-box-bg-color);
  cursor: pointer;
  position: relative;
  z-index: 5;
  outline: 0;
  pointer-events: all;
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-appearance: none;
}
.flix-range [type=range]::-moz-range-track {
  border: 0;
  background: transparent;
}
.flix-range [type=range]::-moz-range-thumb {
  box-sizing: border-box;
  width: var(--flix-spacing-5);
  height: var(--flix-spacing-5);
  -moz-transition: border 250ms ease, background 250ms ease;
  transition: border 250ms ease, background 250ms ease;
  border: var(--flix-spacing-half) solid var(--flix-ui-primary-color);
  border-radius: 100%;
  background: var(--flix-box-bg-color);
  cursor: pointer;
  pointer-events: all;
}
.flix-range [type=range]::-moz-focus-outer {
  border: 0;
}
.flix-range [type=range]::-ms-track {
  border: 0;
  background: transparent;
  color: transparent;
}
.flix-range [type=range]::-ms-fill-lower {
  border: 0;
  background: transparent;
}
.flix-range [type=range]::-ms-fill-upper {
  border: 0;
  background: transparent;
}
.flix-range [type=range]::-ms-thumb {
  box-sizing: border-box;
  width: var(--flix-spacing-5);
  height: var(--flix-spacing-5);
  -ms-transition: border 250ms ease, background 250ms ease;
  transition: border 250ms ease, background 250ms ease;
  border: var(--flix-spacing-half) solid var(--flix-ui-primary-color);
  border-radius: 100%;
  background: var(--flix-box-bg-color);
  cursor: pointer;
  margin-top: 0;
}
.flix-range [type=range]:enabled:focus::-webkit-slider-thumb {
  outline: 2px solid var(--flix-focus-outline-color);
  outline-offset: 1px;
  box-shadow: 0 0 0 calc(var(--flix-spacing-half) + 1px) var(--flix-grayscale-0-color);
}
.flix-range [type=range]:enabled:focus::-moz-range-thumb {
  outline: 2px solid var(--flix-focus-outline-color);
  outline-offset: 1px;
  box-shadow: 0 0 0 calc(var(--flix-spacing-half) + 1px) var(--flix-grayscale-0-color);
}
.flix-range [type=range]:enabled:focus::-ms-thumb {
  outline: 2px solid var(--flix-focus-outline-color);
  outline-offset: 1px;
  box-shadow: 0 0 0 calc(var(--flix-spacing-half) + 1px) var(--flix-grayscale-0-color);
}
.flix-range [type=range]:enabled:focus ~ .flix-range__steps {
  opacity: 1;
}
.flix-range [type=range]:enabled:active::-webkit-slider-thumb {
  background-image: var(--flix-pressed-layer-color);
  text-decoration: none;
}
.flix-range [type=range]:enabled:active::-moz-range-thumb {
  background-image: var(--flix-pressed-layer-color);
  text-decoration: none;
}
.flix-range [type=range]:enabled:active::-ms-thumb {
  background-image: var(--flix-pressed-layer-color);
  text-decoration: none;
}
.flix-range [type=range]:enabled:active ~ .flix-range__steps {
  opacity: 1;
}
.flix-range [type=range]:enabled:hover::-webkit-slider-thumb {
  background-image: var(--flix-hover-layer-color);
  text-decoration: none;
}
.flix-range [type=range]:enabled:hover::-moz-range-thumb {
  background-image: var(--flix-hover-layer-color);
  text-decoration: none;
}
.flix-range [type=range]:enabled:hover::-ms-thumb {
  background-image: var(--flix-hover-layer-color);
  text-decoration: none;
}
.flix-range [type=range]:enabled:hover ~ .flix-range__steps {
  opacity: 1;
}
.flix-range__steps {
  display: flex;
  position: absolute;
  z-index: 2;
  top: 0;
  right: calc(var(--flix-spacing-5) / 2);
  bottom: 0;
  left: calc(var(--flix-spacing-5) / 2);
  align-items: center;
  justify-content: space-between;
  opacity: 0;
  transition: opacity 250ms ease;
}
.flix-range__step {
  width: var(--flix-spacing-1);
  height: var(--flix-spacing-1);
  border-radius: 100%;
  background-color: var(--flix-input-border-color);
}
.flix-range__step--active {
  background-color: var(--flix-ui-primary-color);
}
.flix-range__track-range {
  position: absolute;
  z-index: 1;
  top: calc(var(--flix-spacing-5) / 2);
  height: 2px;
  margin-left: calc(var(--flix-spacing-5) / 2);
  transform: translateY(-1px);
  background: var(--flix-ui-primary-color);
}
.flix-range--disabled {
  opacity: var(--flix-disabled-element-opacity);
}
.flix-range--disabled [type=range]::-moz-range-thumb,
.flix-range--disabled [type=range]:disabled::-moz-range-thumb {
  cursor: not-allowed;
}
.flix-range--disabled [type=range]::-ms-thumb,
.flix-range--disabled [type=range]:disabled::-ms-thumb {
  cursor: not-allowed;
}
.flix-range--disabled [type=range]::-webkit-slider-thumb,
.flix-range--disabled [type=range]:disabled::-webkit-slider-thumb {
  cursor: not-allowed;
}
.flix-range--double {
  pointer-events: none;
}
.flix-range--small .flix-range__field {
  height: var(--flix-spacing-3);
}
.flix-range--small .flix-range__field::before {
  right: calc(var(--flix-spacing-3) / 2);
  left: calc(var(--flix-spacing-3) / 2);
}
.flix-range--small [type=range] {
  height: var(--flix-spacing-3);
}
.flix-range--small [type=range]::-webkit-slider-thumb {
  width: var(--flix-spacing-3);
  height: var(--flix-spacing-3);
}
.flix-range--small [type=range]::-moz-range-thumb {
  width: var(--flix-spacing-3);
  height: var(--flix-spacing-3);
}
.flix-range--small [type=range]::-ms-thumb {
  width: var(--flix-spacing-3);
  height: var(--flix-spacing-3);
}
.flix-range--small .flix-range__track-range {
  top: calc(var(--flix-spacing-3) / 2);
  margin-left: calc(var(--flix-spacing-3) / 2);
  transform: translateY(-1px);
}
.flix-range--small .flix-range__steps {
  right: calc(var(--flix-spacing-3) / 2);
  left: calc(var(--flix-spacing-3) / 2);
  height: var(--flix-spacing-3);
}

.flix-select-group {
  position: relative;
  border: 0;
}
.flix-select-group__info:not(:empty) {
  color: var(--flix-container-content-color, var(--flix-content-primary-color));
  font-size: var(--flix-font-size-small);
  font-weight: var(--flix-font-weight-normal);
  line-height: var(--flix-line-height-small);
  display: block;
  margin-top: var(--flix-spacing-1);
}
.flix-select-group__info:not(:empty) ~ .flix-select-group__info {
  margin-top: var(--flix-spacing-half);
}
.flix-select-group--error .flix-select-group__info {
  color: var(--flix-danger-dark-color);
}
.flix-select-group__info ~ .flix-select-group__info {
  color: var(--flix-content-primary-color);
}
.flix-select-group--error .flix-select-group__item {
  border-color: var(--flix-danger-color);
}
.flix-select-group--error .flix-select-group__item label {
  background-image: linear-gradient(var(--flix-bg-primary-transparent-color), var(--flix-bg-primary-transparent-color)), linear-gradient(var(--flix-danger-color), var(--flix-danger-color));
}
.flix-select-group--error .flix-select-group__item label:hover {
  background-image: var(--flix-hover-layer-color), linear-gradient(var(--flix-bg-primary-transparent-color), var(--flix-bg-primary-transparent-color)), linear-gradient(var(--flix-danger-color), var(--flix-danger-color));
}
.flix-select-group--disabled {
  opacity: var(--flix-disabled-element-opacity);
}
.flix-select-group--disabled .flix-select-group__item label,
.flix-select-group--disabled .flix-select-group__item input {
  cursor: not-allowed;
}
.flix-select-group__items {
  display: inline-flex;
  position: relative;
  flex-wrap: wrap;
  padding-top: 1px;
  list-style-type: none;
}
.flix-select-group__label {
  z-index: 2;
}
.flix-select-group__item {
  min-width: var(--flix-input-height-mobile);
  margin-top: -1px;
  margin-left: -1px;
  border: 1px solid var(--flix-input-border-color);
  text-align: center;
}
@media (min-width: 1200px) {
  .flix-select-group__item {
    min-width: var(--flix-input-height-desktop);
  }
}
.flix-select-group__item:first-of-type, .flix-select-group__item:first-of-type label {
  border-radius: var(--flix-primary-border-radius) 0 0 var(--flix-primary-border-radius);
}
.flix-select-group__item:last-of-type, .flix-select-group__item:last-of-type label {
  border-radius: 0 var(--flix-primary-border-radius) var(--flix-primary-border-radius) 0;
}
.flix-select-group__item input {
  position: absolute;
  width: 0;
  height: 0;
  margin: 0;
  opacity: 0;
  cursor: pointer;
}
.flix-select-group__item label {
  color: var(--flix-content-primary-color);
  font-size: var(--flix-font-size-primary);
  font-weight: var(--flix-font-weight-semibold);
  line-height: var(--flix-line-height-primary);
  display: flex;
  align-items: center;
  padding: calc(var(--flix-spacing-2) - var(--flix-spacing-half)) var(--flix-spacing-3) var(--flix-spacing-1);
  column-gap: var(--flix-spacing-half);
  border-bottom: var(--flix-spacing-half) solid transparent;
  background: var(--flix-box-bg-color);
  cursor: pointer;
}
.flix-select-group__item label:hover {
  background-image: var(--flix-hover-layer-color);
  text-decoration: none;
}
@media (min-width: 1200px) {
  .flix-select-group__item label {
    min-width: var(--flix-input-height-desktop);
    padding-top: calc(var(--flix-spacing-1) - 1px);
    padding-bottom: calc(var(--flix-spacing-half) - 1px);
  }
}
.flix-select-group__item input:focus + label {
  outline: 2px solid var(--flix-focus-outline-color);
  outline-offset: 1px;
  box-shadow: 0 0 0 calc(var(--flix-spacing-half) + 1px) var(--flix-grayscale-0-color);
  border-radius: var(--flix-primary-border-radius);
  position: relative;
  z-index: 1;
}
.flix-select-group__item input:active + label {
  background-image: var(--flix-pressed-layer-color);
  text-decoration: none;
}
.flix-select-group__item input:checked + label {
  padding-right: var(--flix-spacing-2);
  padding-left: var(--flix-spacing-2);
  border-bottom-color: var(--flix-ui-primary-color);
  background-color: var(--flix-highlight-color);
}
.flix-select-group__item input:checked + label::before {
  content: "";
  -webkit-mask-size: 100%;
          mask-size: 100%;
  -webkit-mask: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M21.7 6.2l-13 13a1 1 0 01-1.4 0l-5-5a1 1 0 111.4-1.4L8 17.08l12.3-12.3a1 1 0 111.4 1.42z' /%3E%3C/svg%3E") no-repeat center/1.125rem;
          mask: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M21.7 6.2l-13 13a1 1 0 01-1.4 0l-5-5a1 1 0 111.4-1.4L8 17.08l12.3-12.3a1 1 0 111.4 1.42z' /%3E%3C/svg%3E") no-repeat center/1.125rem;
  width: 1.125rem;
  height: 1.125rem;
  background-color: var(--flix-ui-primary-color);
  min-width: var(--flix-spacing-3);
  max-width: calc(var(--flix-spacing-3) * 2);
  min-height: var(--flix-spacing-3);
  max-height: calc(var(--flix-spacing-3) * 2);
}
.flix-select-group__item--disabled {
  opacity: var(--flix-disabled-element-opacity);
}
.flix-select-group__item--disabled label,
.flix-select-group__item--disabled input {
  cursor: not-allowed;
}

.flix-select {
  position: relative;
}
.flix-select__info:not(:empty) {
  color: var(--flix-container-content-color, var(--flix-content-primary-color));
  font-size: var(--flix-font-size-small);
  font-weight: var(--flix-font-weight-normal);
  line-height: var(--flix-line-height-small);
  display: block;
  margin-top: var(--flix-spacing-1);
}
.flix-select__info:not(:empty) ~ .flix-select__info {
  margin-top: var(--flix-spacing-half);
}
.flix-select--error .flix-select__info {
  color: var(--flix-danger-dark-color);
}
.flix-select__info ~ .flix-select__info {
  color: var(--flix-content-primary-color);
}
.flix-select::after, .flix-select::before {
  top: calc(calc(var(--flix-line-height-primary) / 2 + var(--flix-spacing-2) - var(--flix-spacing-half)) - min(max(var(--flix-spacing-4), 1.5rem), 2 * var(--flix-spacing-4)) / 2);
  right: var(--flix-spacing-2);
  pointer-events: none;
}
@media (min-width: 1200px) {
  .flix-select::after, .flix-select::before {
    top: calc(calc(var(--flix-line-height-primary) + var(--flix-spacing-half)) + calc(var(--flix-line-height-primary) / 2 + var(--flix-spacing-1) - 1px) - min(max(var(--flix-spacing-4), 1.5rem), 2 * var(--flix-spacing-4)) / 2);
  }
}
.flix-select::after {
  -webkit-mask: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M18.68 8.3a1 1 0 00-1.41 0l-5.28 5.28-5.26-5.27a1 1 0 00-1.4 0 1 1 0 000 1.41l5.96 5.99a1 1 0 001.41 0l5.98-6a1 1 0 000-1.42z' /%3E%3C/svg%3E") no-repeat center/1.5rem;
          mask: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M18.68 8.3a1 1 0 00-1.41 0l-5.28 5.28-5.26-5.27a1 1 0 00-1.4 0 1 1 0 000 1.41l5.96 5.99a1 1 0 001.41 0l5.98-6a1 1 0 000-1.42z' /%3E%3C/svg%3E") no-repeat center/1.5rem;
  width: 1.5rem;
  height: 1.5rem;
  background-color: var(--flix-icon-primary-color);
  min-width: var(--flix-spacing-4);
  max-width: calc(var(--flix-spacing-4) * 2);
  min-height: var(--flix-spacing-4);
  max-height: calc(var(--flix-spacing-4) * 2);
  -webkit-mask-size: 100%;
          mask-size: 100%;
  content: "";
  position: absolute;
}
.flix-select--error::after, .flix-select--valid::after {
  right: calc(min(max(var(--flix-spacing-4), 1.5rem), 2 * var(--flix-spacing-4)) + var(--flix-spacing-3));
}
.flix-select--no-label::after, .flix-select--no-label::before {
  top: calc(calc(var(--flix-line-height-primary) / 2 + var(--flix-spacing-2) - var(--flix-spacing-half)) - min(max(var(--flix-spacing-4), 1.5rem), 2 * var(--flix-spacing-4)) / 2);
}
@media (min-width: 1200px) {
  .flix-select--no-label::after, .flix-select--no-label::before {
    top: calc(calc(var(--flix-line-height-primary) / 2 + var(--flix-spacing-1) - 1px) - min(max(var(--flix-spacing-4), 1.5rem), 2 * var(--flix-spacing-4)) / 2);
  }
}
.flix-select__container {
  display: flex;
  position: relative;
  z-index: 0;
  align-items: center;
  min-height: var(--flix-input-height-mobile);
  overflow: hidden;
  border: 1px solid var(--flix-input-border-color);
  border-radius: var(--flix-primary-border-radius);
  background-color: var(--flix-box-bg-color);
  color: var(--flix-content-primary-color);
}
@media (min-width: 1200px) {
  .flix-select__container {
    min-height: var(--flix-input-height-desktop);
  }
}
.flix-select--error .flix-select__container {
  border-color: var(--flix-danger-color);
  background-image: linear-gradient(var(--flix-bg-primary-transparent-color), var(--flix-bg-primary-transparent-color)), linear-gradient(var(--flix-danger-color), var(--flix-danger-color));
}
.flix-select--active .flix-select__container, .flix-select__container:focus-within {
  outline: 2px solid var(--flix-focus-outline-color);
  outline-offset: 1px;
  box-shadow: 0 0 0 calc(var(--flix-spacing-half) + 1px) var(--flix-grayscale-0-color);
}
.flix-select__icon, .flix-select__icon.flix-icon {
  position: relative;
  z-index: 1;
  flex-shrink: 0;
  width: 1.5rem;
  height: 1.5rem;
  margin-right: var(--flix-spacing-1);
  margin-left: var(--flix-spacing-2);
  pointer-events: none;
  min-width: var(--flix-spacing-4);
  max-width: calc(var(--flix-spacing-4) * 2);
  min-height: var(--flix-spacing-4);
  max-height: calc(var(--flix-spacing-4) * 2);
  font-size: 1.5rem;
  line-height: 1.5rem;
}
@supports (font-size: min(1.5rem, var(--flix-spacing-4))) {
  .flix-select__icon, .flix-select__icon.flix-icon {
    font-size: min(max(var(--flix-spacing-4), 1.5rem), 2 * var(--flix-spacing-4));
    line-height: min(max(var(--flix-spacing-4), 1.5rem), 2 * var(--flix-spacing-4));
  }
}
.flix-select__icon::before, .flix-select__icon.flix-icon::before {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0;
  margin-left: 0;
  min-width: var(--flix-spacing-4);
  max-width: calc(var(--flix-spacing-4) * 2);
  min-height: var(--flix-spacing-4);
  max-height: calc(var(--flix-spacing-4) * 2);
}
.flix-select__inline-label {
  display: flex;
  position: relative;
  z-index: 1;
  align-items: center;
  align-self: stretch;
  padding: 0 var(--flix-spacing-2);
  border-right: 1px solid var(--flix-input-border-color);
  line-height: var(--flix-line-height-primary);
}
.flix-select__field {
  position: relative;
  width: 100%;
  min-height: 100%;
  padding: calc(var(--flix-spacing-2) - var(--flix-spacing-half) - 1px) var(--flix-spacing-2);
  border: 0;
  background: transparent;
  color: var(--flix-content-primary-color);
  font-family: inherit;
  font-size: var(--flix-font-size-primary);
  font-weight: var(--flix-font-weight-normal);
  line-height: var(--flix-line-height-primary);
  text-overflow: ellipsis;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  padding-right: var(--flix-spacing-6);
}
@media (min-width: 1200px) {
  .flix-select__field {
    padding-top: calc(var(--flix-spacing-1) - 1px);
    padding-bottom: calc(var(--flix-spacing-1) - 1px);
  }
}
.flix-select__field::placeholder {
  opacity: 1;
  color: var(--flix-content-secondary-color);
  font-style: italic;
}
.flix-select__field:enabled:hover {
  background-image: var(--flix-hover-layer-color);
  text-decoration: none;
}
.flix-select__field:enabled:focus {
  outline: none;
}
.flix-select__field::-ms-expand {
  display: none;
}
.flix-select__field[disabled] {
  cursor: not-allowed;
}
.flix-select__field:not([multiple]) option, .flix-select__field:not([multiple]) optgroup {
  background: var(--flix-box-bg-color);
  color: var(--flix-content-primary-color);
}
.flix-select__icon ~ .flix-select__field {
  margin-left: calc(-1 * (min(max(var(--flix-spacing-4), 1.5rem), 2 * var(--flix-spacing-4)) + var(--flix-spacing-2) + var(--flix-spacing-1)));
  padding-left: calc(min(max(var(--flix-spacing-4), 1.5rem), 2 * var(--flix-spacing-4)) + var(--flix-spacing-2) + var(--flix-spacing-1));
}
.flix-select--error::before, .flix-select--valid::before {
  min-width: var(--flix-spacing-4);
  max-width: calc(var(--flix-spacing-4) * 2);
  min-height: var(--flix-spacing-4);
  max-height: calc(var(--flix-spacing-4) * 2);
  content: "";
  position: absolute;
  z-index: 1;
}
.flix-select--error::before {
  -webkit-mask: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M12 2a10 10 0 100 20 10 10 0 000-20zm-1 5a1 1 0 012 0v5.5a1 1 0 11-2 0V7zm1 11a1.5 1.5 0 110-3 1.5 1.5 0 010 3z' /%3E%3C/svg%3E") no-repeat center/1.5rem;
          mask: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M12 2a10 10 0 100 20 10 10 0 000-20zm-1 5a1 1 0 012 0v5.5a1 1 0 11-2 0V7zm1 11a1.5 1.5 0 110-3 1.5 1.5 0 010 3z' /%3E%3C/svg%3E") no-repeat center/1.5rem;
  width: 1.5rem;
  height: 1.5rem;
  background-color: var(--flix-danger-color);
  -webkit-mask-size: 100%;
          mask-size: 100%;
}
.flix-select--valid::before {
  -webkit-mask: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M12 2a10 10 0 100 20 10 10 0 000-20zm4.89 7.7l-6 6a1 1 0 01-1.42 0l-2.36-2.35a1 1 0 111.42-1.41l1.65 1.65 5.3-5.3a1 1 0 111.4 1.42z' /%3E%3C/svg%3E") no-repeat center/1.5rem;
          mask: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M12 2a10 10 0 100 20 10 10 0 000-20zm4.89 7.7l-6 6a1 1 0 01-1.42 0l-2.36-2.35a1 1 0 111.42-1.41l1.65 1.65 5.3-5.3a1 1 0 111.4 1.42z' /%3E%3C/svg%3E") no-repeat center/1.5rem;
  width: 1.5rem;
  height: 1.5rem;
  background-color: var(--flix-success-color);
  -webkit-mask-size: 100%;
          mask-size: 100%;
}
.flix-select--error .flix-select__field {
  background-image: linear-gradient(var(--flix-bg-primary-transparent-color), var(--flix-bg-primary-transparent-color)), linear-gradient(var(--flix-danger-color), var(--flix-danger-color));
}
.flix-select--error .flix-select__field:enabled:hover {
  background-image: var(--flix-hover-layer-color), linear-gradient(var(--flix-bg-primary-transparent-color), var(--flix-bg-primary-transparent-color)), linear-gradient(var(--flix-danger-color), var(--flix-danger-color));
}
.flix-select--disabled {
  opacity: var(--flix-disabled-element-opacity);
}
.flix-select--disabled .flix-select__field {
  cursor: not-allowed;
}
.flix-select--multiple.flix-select::after {
  display: none;
}
.flix-select--multiple.flix-select .flix-select__field {
  height: auto;
  padding-right: var(--flix-spacing-2);
}
.flix-select--multiple.flix-select .flix-select__field option {
  margin: var(--flix-spacing-1) 0;
  padding: var(--flix-spacing-half);
  border-radius: var(--flix-primary-border-radius);
}
.flix-select--multiple.flix-select .flix-select__field:enabled option:enabled:hover, .flix-select--multiple.flix-select .flix-select__field:enabled option[href]:hover {
  background-image: var(--flix-hover-layer-color);
  text-decoration: none;
}
.flix-select--multiple.flix-select .flix-select__field:enabled option:enabled:active {
  background-image: var(--flix-pressed-layer-color);
  text-decoration: none;
}
.flix-select--multiple.flix-select .flix-select__field:disabled {
  overflow: hidden;
}
.flix-select--multiple.flix-select--valid .flix-select__field, .flix-select--multiple.flix-select--error .flix-select__field {
  padding-right: var(--flix-spacing-8);
}
.flix-select--multiple.flix-select--valid::before, .flix-select--multiple.flix-select--error::before {
  right: var(--flix-spacing-4);
}

.flix-skeleton {
  content: "";
  display: block;
  width: 100%;
  height: calc(var(--flix-font-size-primary) + 2px);
  margin-bottom: var(--flix-spacing-1);
  animation: skeleton-blink 1.6s infinite linear;
  border-radius: var(--flix-primary-border-radius);
  background: var(--flix-grayscale-30-color);
}
.flix-skeleton--w-xs {
  width: 25%;
}
.flix-skeleton--w-sm {
  width: 33%;
}
.flix-skeleton--w-md {
  width: 50%;
}
.flix-skeleton--w-lg {
  width: 75%;
}
.flix-skeleton--h-xs {
  height: 1px;
  margin-bottom: var(--flix-spacing-2);
}
.flix-skeleton--h-md {
  height: calc(var(--flix-line-height-primary) + var(--flix-spacing-1));
  margin-bottom: var(--flix-spacing-2);
}
.flix-skeleton--h-lg {
  height: var(--flix-input-height-mobile);
  margin-bottom: var(--flix-spacing-2);
}
@media (min-width: 1200px) {
  .flix-skeleton--h-lg {
    height: var(--flix-input-height-desktop);
  }
}
.flix-skeleton--inline {
  display: inline-flex;
  vertical-align: middle;
}
.flix-skeleton--round {
  border-radius: var(--flix-spacing-5);
}
.flix-skeleton--flush-bottom {
  margin-bottom: 0;
}
@keyframes skeleton-blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.flix-skip-link {
  white-space: normal;
}
.flix-skip-link + .flix-skip-link {
  margin-top: 0;
  margin-left: 0;
}

.flix-spinner {
  display: inline-block;
  width: calc(var(--flix-spacing-12) + var(--flix-spacing-3));
  height: calc(var(--flix-spacing-12) + var(--flix-spacing-3));
  animation: spinner-rotate 1s infinite linear;
  border: var(--flix-spacing-half) solid var(--flix-ui-primary-light-color);
  border-radius: 50%;
  border-left-color: var(--flix-ui-primary-dark-color);
}
@keyframes spinner-rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.flix-spinner--sm {
  width: var(--flix-spacing-4);
  height: var(--flix-spacing-4);
}
.flix-spinner--md {
  width: var(--flix-spacing-6);
  height: var(--flix-spacing-6);
}
.flix-spinner--lg {
  width: var(--flix-spacing-8);
  height: var(--flix-spacing-8);
}
.flix-spinner--xl {
  width: var(--flix-spacing-12);
  height: var(--flix-spacing-12);
}
.flix-spinner--xxl {
  width: calc(var(--flix-spacing-12) + var(--flix-spacing-4));
  height: calc(var(--flix-spacing-12) + var(--flix-spacing-4));
}

.flix-switch {
  display: block;
  line-height: var(--flix-spacing-5);
}
.flix-switch__info:not(:empty) {
  color: var(--flix-container-content-color, var(--flix-content-primary-color));
  font-size: var(--flix-font-size-small);
  font-weight: var(--flix-font-weight-normal);
  line-height: var(--flix-line-height-small);
  display: block;
  margin-top: var(--flix-spacing-1);
}
.flix-switch__info:not(:empty) ~ .flix-switch__info {
  margin-top: var(--flix-spacing-half);
}
.flix-switch--error .flix-switch__info {
  color: var(--flix-danger-dark-color);
}
.flix-switch__info ~ .flix-switch__info {
  color: var(--flix-content-primary-color);
}
@media (min-width: 600px) {
  .flix-switch {
    display: inline-block;
  }
}
.flix-switch__input {
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
}
.flix-switch__input:enabled + .flix-switch__label:hover {
  background-image: var(--flix-hover-layer-color);
  text-decoration: none;
}
.flix-switch__label {
  color: var(--flix-container-content-color, var(--flix-content-primary-color));
  font-size: var(--flix-font-size-primary);
  font-weight: var(--flix-font-weight-normal);
  line-height: var(--flix-line-height-primary);
  display: grid;
  grid-template-columns: auto var(--flix-spacing-8);
  align-items: center;
  min-width: 100%;
  min-height: calc(var(--flix-spacing-5) + var(--flix-spacing-half) * 2);
  margin: calc(-1 * var(--flix-spacing-half));
  padding: var(--flix-spacing-half);
  column-gap: var(--flix-spacing-2);
  border-radius: var(--flix-primary-border-radius);
  cursor: pointer;
}
@media (min-width: 600px) {
  .flix-switch__label {
    grid-template-columns: var(--flix-spacing-8) auto;
  }
}
.flix-switch__label::before, .flix-switch__label::after {
  content: "";
  grid-column: 2;
  grid-row: 1;
  align-self: center;
  justify-self: end;
}
@media (min-width: 600px) {
  .flix-switch__label::before, .flix-switch__label::after {
    grid-column: 1;
  }
}
.flix-switch__label::before {
  width: var(--flix-spacing-8);
  height: var(--flix-spacing-5);
  border-radius: var(--flix-spacing-5);
  background-color: var(--flix-input-border-color);
  transition: background-color 250ms ease;
}
.flix-switch__label::after {
  -webkit-mask: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M12 2a10.01 10.01 0 000 20 10.01 10.01 0 000-20z' /%3E%3C/svg%3E") no-repeat center/var(--flix-spacing-5);
          mask: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M12 2a10.01 10.01 0 000 20 10.01 10.01 0 000-20z' /%3E%3C/svg%3E") no-repeat center/var(--flix-spacing-5);
  width: var(--flix-spacing-5);
  height: var(--flix-spacing-5);
  background-color: var(--flix-grayscale-0-color);
  transform: translateX(calc((var(--flix-spacing-8) - var(--flix-spacing-5)) * -1));
  transition: transform 250ms ease, -webkit-mask-image 250ms ease;
  transition: transform 250ms ease, mask-image 250ms ease;
  transition: transform 250ms ease, mask-image 250ms ease, -webkit-mask-image 250ms ease;
}
.flix-switch__input:focus + .flix-switch__label {
  outline: 2px solid var(--flix-focus-outline-color);
  outline-offset: 1px;
  box-shadow: 0 0 0 calc(var(--flix-spacing-half) + 1px) var(--flix-grayscale-0-color);
  border-radius: var(--flix-primary-border-radius);
}
.flix-switch__input:checked + .flix-switch__label::after {
  -webkit-mask: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M12 2a10 10 0 100 20 10 10 0 000-20zm4.89 7.7l-6 6a1 1 0 01-1.42 0l-2.36-2.35a1 1 0 111.42-1.41l1.65 1.65 5.3-5.3a1 1 0 111.4 1.42z' /%3E%3C/svg%3E") no-repeat center/var(--flix-spacing-5);
          mask: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M12 2a10 10 0 100 20 10 10 0 000-20zm4.89 7.7l-6 6a1 1 0 01-1.42 0l-2.36-2.35a1 1 0 111.42-1.41l1.65 1.65 5.3-5.3a1 1 0 111.4 1.42z' /%3E%3C/svg%3E") no-repeat center/var(--flix-spacing-5);
  width: var(--flix-spacing-5);
  height: var(--flix-spacing-5);
  background-color: var(--flix-grayscale-0-color);
  transform: translateX(0);
}
.flix-switch__input:checked + .flix-switch__label::before {
  background-color: var(--flix-ui-primary-color);
}
.flix-switch--sm {
  min-height: var(--flix-spacing-3);
  line-height: var(--flix-spacing-3);
}
@media (min-width: 600px) {
  .flix-switch--sm .flix-switch__label {
    grid-template-columns: var(--flix-spacing-5) auto;
  }
}
.flix-switch--sm .flix-switch__label::before {
  width: var(--flix-spacing-5);
  height: var(--flix-spacing-3);
  border-radius: var(--flix-spacing-3);
}
.flix-switch--sm .flix-switch__label::after {
  width: var(--flix-spacing-3);
  height: var(--flix-spacing-3);
  -webkit-mask-size: var(--flix-spacing-3);
          mask-size: var(--flix-spacing-3);
  transform: translateX(calc((var(--flix-spacing-5) - var(--flix-spacing-3)) * -1));
}
.flix-switch--sm .flix-switch__input:checked + .flix-switch__label::after {
  width: var(--flix-spacing-3);
  height: var(--flix-spacing-3);
  -webkit-mask-size: var(--flix-spacing-3);
          mask-size: var(--flix-spacing-3);
}
.flix-switch--stacked .flix-switch__label {
  grid-template-columns: auto;
}
.flix-switch--stacked .flix-switch__label::before, .flix-switch--stacked .flix-switch__label::after {
  grid-column: 1;
  grid-row: 2;
  justify-self: start;
}
.flix-switch--stacked .flix-switch__label::after {
  transform: translateX(0);
}
.flix-switch--stacked .flix-switch__input:checked + .flix-switch__label::after {
  transform: translateX(calc(var(--flix-spacing-8) - var(--flix-spacing-5)));
}
@media (min-width: 1200px) {
  .flix-switch--stacked {
    color: var(--flix-content-secondary-color);
  }
}
.flix-switch--disabled {
  opacity: var(--flix-disabled-element-opacity);
}
.flix-switch--disabled .flix-switch__label {
  cursor: not-allowed;
}

.flix-table {
  border-spacing: var(--flix-spacing-4) 0;
  border-collapse: separate;
}
.flix-table caption {
  padding: var(--flix-spacing-2);
  caption-side: bottom;
  border-top: 1px solid var(--flix-line-primary-color);
  color: var(--flix-secondary-content-color);
  text-align: center;
}
.flix-table__wrap {
  display: inline-block;
  margin-bottom: var(--flix-spacing-2);
  padding: var(--flix-spacing-2) 0;
  border: 1px solid var(--flix-line-primary-color);
  border-radius: var(--flix-primary-border-radius);
}
.flix-table caption {
  padding-bottom: 0;
}
.flix-table th,
.flix-table td {
  padding: var(--flix-spacing-2) 0;
  border: solid var(--flix-line-primary-color);
  border-width: 0 0 1px;
  text-align: left;
  color: var(--flix-container-content-color, var(--flix-content-primary-color));
  font-size: var(--flix-font-size-primary);
  font-weight: var(--flix-font-weight-normal);
  line-height: var(--flix-line-height-primary);
}
.flix-table__header th,
.flix-table__header td, .flix-table__footer th,
.flix-table__footer td {
  padding: var(--flix-spacing-2) 0;
  border: solid var(--flix-line-primary-color);
  border-width: 0 0 1px;
  text-align: left;
  color: var(--flix-container-content-color, var(--flix-heading-font-color));
  font-size: var(--flix-font-size-h4);
  font-weight: var(--flix-font-weight-bold);
  line-height: var(--flix-line-height-h4);
}
.flix-table tr:last-child th,
.flix-table tr:last-child td {
  border-width: 0;
}
.flix-table .flix-table__header tr th, .flix-table .flix-table__header tr td {
  border-width: 0 0 1px;
}
.flix-table .flix-table__footer tr th, .flix-table .flix-table__footer tr td {
  border-width: 1px 0 0;
}

.flix-tag {
  --flix-tag-color: var(--flix-grayscale-50-color);
  --flix-tag-text-color: var(--flix-grayscale-100-color);
  color: var(--flix-tag-text-color);
  font-size: var(--flix-font-size-primary);
  font-weight: var(--flix-font-weight-semibold);
  line-height: var(--flix-line-height-primary);
  display: inline-flex;
  align-items: center;
  max-width: 100%;
  padding: calc(var(--flix-spacing-half) - 1px) var(--flix-spacing-2);
  column-gap: var(--flix-spacing-half);
  border: 1px solid var(--flix-tag-color);
  border-radius: var(--flix-spacing-5);
  background-color: var(--flix-tag-color);
  font-family: inherit;
  text-decoration: none;
  text-overflow: ellipsis;
  vertical-align: middle;
  white-space: nowrap;
}
.flix-tag--subtle, .flix-tag--outlined {
  background-color: var(--flix-grayscale-0-color);
}
.flix-tag--subtle {
  box-shadow: inset 0 0 0 100vh var(--flix-bg-primary-transparent-color), inset 0 0 0 100vh var(--flix-tag-color);
}
.flix-tag--success, .flix-tag--warning, .flix-tag--danger {
  --flix-tag-text-color: var(--flix-grayscale-0-color);
}
.flix-tag--success .flix-tag__icon, .flix-tag--warning .flix-tag__icon, .flix-tag--danger .flix-tag__icon {
  color: inherit;
}
.flix-tag--success {
  --flix-tag-color: var(--flix-success-dark-color);
}
.flix-tag--success.flix-tag--subtle, .flix-tag--success.flix-tag--outlined {
  --flix-tag-text-color: var(--flix-success-dark-color);
}
.flix-tag--warning {
  --flix-tag-color: var(--flix-warning-dark-color);
}
.flix-tag--warning.flix-tag--subtle, .flix-tag--warning.flix-tag--outlined {
  --flix-tag-text-color: var(--flix-warning-dark-color);
}
.flix-tag--warning.flix-tag--subtle {
  --flix-tag-color: var(--flix-warning-color);
  border-color: var(--flix-warning-dark-color);
}
.flix-tag--danger {
  --flix-tag-color: var(--flix-danger-dark-color);
}
.flix-tag--danger.flix-tag--subtle, .flix-tag--danger.flix-tag--outlined {
  --flix-tag-text-color: var(--flix-danger-dark-color);
}
.flix-tag__close {
  position: relative;
  z-index: 1;
}
.flix-tag__close::before {
  content: "";
  -webkit-mask: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M13.41 12l6.37-6.36a1 1 0 10-1.42-1.42L12 10.6 5.64 4.22a1 1 0 10-1.42 1.42L10.6 12l-6.37 6.36a1 1 0 101.42 1.42L12 13.4l6.36 6.37a1 1 0 101.42-1.42L13.4 12z' /%3E%3C/svg%3E") no-repeat center/1.125rem;
          mask: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M13.41 12l6.37-6.36a1 1 0 10-1.42-1.42L12 10.6 5.64 4.22a1 1 0 10-1.42 1.42L10.6 12l-6.37 6.36a1 1 0 101.42 1.42L12 13.4l6.36 6.37a1 1 0 101.42-1.42L13.4 12z' /%3E%3C/svg%3E") no-repeat center/1.125rem;
  width: 1.125rem;
  height: 1.125rem;
  background-color: var(--flix-tag-text-color);
  min-width: var(--flix-spacing-3);
  max-width: calc(var(--flix-spacing-3) * 2);
  min-height: var(--flix-spacing-3);
  max-height: calc(var(--flix-spacing-3) * 2);
  -webkit-mask-size: 100%;
          mask-size: 100%;
}
.flix-tag--small {
  padding: calc(var(--flix-spacing-half) - 1px) var(--flix-spacing-1);
  font-size: var(--flix-font-size-small);
  line-height: var(--flix-font-size-small);
}
.flix-tag--small .flix-tag__icon {
  font-size: 1em;
}
.flix-tag--small .flix-tag__close {
  width: 1.125rem;
  min-width: var(--flix-spacing-3);
  max-width: calc(var(--flix-spacing-3) * 2);
  height: 1.125rem;
  min-height: var(--flix-spacing-3);
  max-height: calc(var(--flix-spacing-3) * 2);
  margin: calc(-1 * var(--flix-spacing-half) - 1px) 0;
}
.flix-tag:is(a, button) {
  cursor: pointer;
}
.flix-tag:is(a, button):enabled:hover, .flix-tag:is(a, button)[href]:hover {
  background-image: var(--flix-hover-layer-color);
  text-decoration: none;
}
.flix-tag:is(a, button):enabled:active {
  background-image: var(--flix-pressed-layer-color);
  text-decoration: none;
}
.flix-tag:is(a, button):focus {
  outline: 2px solid var(--flix-focus-outline-color);
  outline-offset: 1px;
  box-shadow: 0 0 0 calc(var(--flix-spacing-half) + 1px) var(--flix-grayscale-0-color);
}
.flix-tag:disabled, .flix-tag[aria-disabled=true], .flix-tag--disabled {
  opacity: var(--flix-disabled-element-opacity);
  cursor: not-allowed;
}

.flix-text, .flix-text p {
  color: var(--flix-container-content-color, var(--flix-content-primary-color));
  font-size: var(--flix-font-size-primary);
  font-weight: var(--flix-font-weight-normal);
  line-height: var(--flix-line-height-primary);
  margin: 0;
}
.flix-text:where(:not(:last-child)), .flix-text p:where(:not(:last-child)) {
  margin-bottom: var(--flix-spacing-2);
}
.flix-text--small, .flix-text--small p {
  color: var(--flix-container-content-color, var(--flix-content-primary-color));
  font-size: var(--flix-font-size-small);
  font-weight: var(--flix-font-weight-normal);
  line-height: var(--flix-line-height-small);
  margin: 0;
}
.flix-text--small:where(:not(:last-child)), .flix-text--small p:where(:not(:last-child)) {
  margin-bottom: var(--flix-spacing-2);
}
.flix-textarea {
  position: relative;
}
.flix-textarea__info:not(:empty) {
  color: var(--flix-container-content-color, var(--flix-content-primary-color));
  font-size: var(--flix-font-size-small);
  font-weight: var(--flix-font-weight-normal);
  line-height: var(--flix-line-height-small);
  display: block;
  margin-top: var(--flix-spacing-1);
}
.flix-textarea__info:not(:empty) ~ .flix-textarea__info {
  margin-top: var(--flix-spacing-half);
}
.flix-textarea--error .flix-textarea__info {
  color: var(--flix-danger-dark-color);
}
.flix-textarea__info ~ .flix-textarea__info {
  color: var(--flix-content-primary-color);
}
.flix-textarea__field {
  position: relative;
  width: 100%;
  min-height: calc(var(--flix-input-height-mobile) * 2 + var(--flix-spacing-4));
  padding: var(--flix-spacing-1) var(--flix-spacing-2);
  overflow: auto;
  border: 1px solid var(--flix-input-border-color);
  border-radius: var(--flix-primary-border-radius);
  background-color: var(--flix-box-bg-color);
  color: var(--flix-content-primary-color);
  font-family: inherit;
  font-size: var(--flix-font-size-primary);
  font-weight: var(--flix-font-weight-normal);
  line-height: var(--flix-line-height-primary);
  vertical-align: top;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}
.flix-textarea__field::placeholder {
  opacity: 1;
  color: var(--flix-content-secondary-color);
  font-style: italic;
}
.flix-textarea__field:enabled:hover, .flix-textarea__field[href]:hover {
  background-image: var(--flix-hover-layer-color);
  text-decoration: none;
}
@media (min-width: 1200px) {
  .flix-textarea__field {
    min-height: calc(var(--flix-input-height-desktop) * 2 + var(--flix-spacing-4) + 24px);
  }
}
.flix-textarea--valid .flix-textarea__field {
  padding-right: calc(min(max(var(--flix-spacing-4), 1.5rem), 2 * var(--flix-spacing-4)) + var(--flix-spacing-3));
}
.flix-textarea--error .flix-textarea__field {
  padding-right: calc(min(max(var(--flix-spacing-4), 1.5rem), 2 * var(--flix-spacing-4)) + var(--flix-spacing-3));
  border: 1px solid var(--flix-danger-color);
}
.flix-textarea__field:focus, .flix-textarea--active .flix-textarea__field {
  outline: 2px solid var(--flix-focus-outline-color);
  outline-offset: 1px;
  box-shadow: 0 0 0 calc(var(--flix-spacing-half) + 1px) var(--flix-grayscale-0-color);
}
.flix-textarea__field[disabled], .flix-textarea--disabled .flix-textarea__field {
  cursor: not-allowed;
}
.flix-textarea--error::before, .flix-textarea--valid::before {
  min-width: var(--flix-spacing-4);
  max-width: calc(var(--flix-spacing-4) * 2);
  min-height: var(--flix-spacing-4);
  max-height: calc(var(--flix-spacing-4) * 2);
  content: "";
  position: absolute;
  z-index: 1;
}
.flix-textarea--error::before {
  -webkit-mask: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M12 2a10 10 0 100 20 10 10 0 000-20zm-1 5a1 1 0 012 0v5.5a1 1 0 11-2 0V7zm1 11a1.5 1.5 0 110-3 1.5 1.5 0 010 3z' /%3E%3C/svg%3E") no-repeat center/1.5rem;
          mask: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M12 2a10 10 0 100 20 10 10 0 000-20zm-1 5a1 1 0 012 0v5.5a1 1 0 11-2 0V7zm1 11a1.5 1.5 0 110-3 1.5 1.5 0 010 3z' /%3E%3C/svg%3E") no-repeat center/1.5rem;
  width: 1.5rem;
  height: 1.5rem;
  background-color: var(--flix-danger-color);
  -webkit-mask-size: 100%;
          mask-size: 100%;
}
.flix-textarea--valid::before {
  -webkit-mask: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M12 2a10 10 0 100 20 10 10 0 000-20zm4.89 7.7l-6 6a1 1 0 01-1.42 0l-2.36-2.35a1 1 0 111.42-1.41l1.65 1.65 5.3-5.3a1 1 0 111.4 1.42z' /%3E%3C/svg%3E") no-repeat center/1.5rem;
          mask: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M12 2a10 10 0 100 20 10 10 0 000-20zm4.89 7.7l-6 6a1 1 0 01-1.42 0l-2.36-2.35a1 1 0 111.42-1.41l1.65 1.65 5.3-5.3a1 1 0 111.4 1.42z' /%3E%3C/svg%3E") no-repeat center/1.5rem;
  width: 1.5rem;
  height: 1.5rem;
  background-color: var(--flix-success-color);
  -webkit-mask-size: 100%;
          mask-size: 100%;
}
.flix-textarea--error .flix-textarea__field {
  background-image: linear-gradient(var(--flix-bg-primary-transparent-color), var(--flix-bg-primary-transparent-color)), linear-gradient(var(--flix-danger-color), var(--flix-danger-color));
}
.flix-textarea--error .flix-textarea__field:enabled:hover {
  background-image: var(--flix-hover-layer-color), linear-gradient(var(--flix-bg-primary-transparent-color), var(--flix-bg-primary-transparent-color)), linear-gradient(var(--flix-danger-color), var(--flix-danger-color));
}
.flix-textarea--disabled {
  opacity: var(--flix-disabled-element-opacity);
}
.flix-textarea--disabled .flix-textarea__field {
  cursor: not-allowed;
}
.flix-textarea::before {
  top: calc(var(--flix-spacing-1) + var(--flix-line-height-primary) / 2 - min(max(var(--flix-spacing-4), 1.5rem), 2 * var(--flix-spacing-4)) / 2);
  right: var(--flix-spacing-2);
}
@media (min-width: 1200px) {
  .flix-textarea::before {
    top: calc(calc(var(--flix-line-height-primary) + var(--flix-spacing-half)) + var(--flix-spacing-1) + var(--flix-line-height-primary) / 2 - min(max(var(--flix-spacing-4), 1.5rem), 2 * var(--flix-spacing-4)) / 2);
  }
}
@media (min-width: 1200px) {
  .flix-textarea--no-label::before {
    top: calc(var(--flix-spacing-1) + (var(--flix-line-height-primary) - min(max(var(--flix-spacing-4), 1.5rem), 2 * var(--flix-spacing-4))) / 2);
  }
}

.flix-tooltip {
  left: 100%;
  top: 50%;
  bottom: auto;
  transform: translateY(-50%);
  transition: opacity 250ms ease;
  display: none;
  position: absolute;
  z-index: 10;
  opacity: 0;
}
.flix-tooltip--active:not([hidden]) {
  opacity: 1;
}
.flix-tooltip--start {
  top: calc(-1 * var(--flix-spacing-1));
  bottom: auto;
  transform: none;
}
.flix-tooltip--end {
  top: auto;
  bottom: calc(-1 * var(--flix-spacing-1));
  transform: none;
}
.flix-tooltip--left {
  right: 100%;
  left: auto;
  top: 50%;
  bottom: auto;
  transform: translateY(-50%);
}
.flix-tooltip--left.flix-tooltip--start {
  top: calc(-1 * var(--flix-spacing-1));
  bottom: auto;
  transform: none;
}
.flix-tooltip--left.flix-tooltip--end {
  top: auto;
  bottom: calc(-1 * var(--flix-spacing-1));
  transform: none;
}
.flix-tooltip--top {
  top: auto;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
}
.flix-tooltip--top.flix-tooltip--start {
  right: auto;
  left: calc(-1 * var(--flix-spacing-1));
  transform: none;
}
.flix-tooltip--top.flix-tooltip--end {
  right: calc(-1 * var(--flix-spacing-1));
  left: auto;
  transform: none;
}
.flix-tooltip--bottom {
  top: 100%;
  bottom: auto;
  left: 50%;
  transform: translateX(-50%);
}
.flix-tooltip--bottom.flix-tooltip--start {
  right: auto;
  left: calc(-1 * var(--flix-spacing-1));
  transform: none;
}
.flix-tooltip--bottom.flix-tooltip--end {
  right: calc(-1 * var(--flix-spacing-1));
  left: auto;
  transform: none;
}
.flix-tooltip-target {
  position: relative;
}
.flix-tooltip-target--inline {
  display: inline-block;
}

.flix-has-text-centered {
  text-align: center;
}
.flix-has-text-left {
  text-align: left;
}
.flix-has-text-right {
  text-align: right;
}

.flix-hidden {
  display: none;
}

@media (min-width: 0) and (max-width: 389px) {
  .flix-hidden-zero {
    display: none;
  }
}
@media (min-width: 390px) and (max-width: 599px) {
  .flix-hidden-xs {
    display: none;
  }
}
@media (min-width: 600px) and (max-width: 767px) {
  .flix-hidden-sm {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .flix-hidden-md {
    display: none;
  }
}
@media (min-width: 1024px) and (max-width: 1199px) {
  .flix-hidden-lg {
    display: none;
  }
}
@media (min-width: 1200px) {
  .flix-hidden-xl {
    display: none;
  }
}
.flix-reset-btn {
  padding: 0;
  font-size: var(--flix-font-size-primary);
  border: 0;
  background: transparent;
  font-family: var(--flix-font-family-primary);
  cursor: pointer;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}
.flix-reset-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.flix-reset-list--inline {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.flix-reset-list--inline > li {
  display: inline-block;
}

/* stylelint-disable declaration-no-important */
.flix-space-half-top {
  margin-top: var(--flix-spacing-half) !important;
}
.flix-space-half-bottom {
  margin-bottom: var(--flix-spacing-half) !important;
}
.flix-space-half-left {
  margin-left: var(--flix-spacing-half) !important;
}
.flix-space-half-right {
  margin-right: var(--flix-spacing-half) !important;
}
.flix-space-1-top {
  margin-top: var(--flix-spacing-1) !important;
}
.flix-space-1-bottom {
  margin-bottom: var(--flix-spacing-1) !important;
}
.flix-space-1-left {
  margin-left: var(--flix-spacing-1) !important;
}
.flix-space-1-right {
  margin-right: var(--flix-spacing-1) !important;
}
.flix-space-2-top {
  margin-top: var(--flix-spacing-2) !important;
}
.flix-space-2-bottom {
  margin-bottom: var(--flix-spacing-2) !important;
}
.flix-space-2-left {
  margin-left: var(--flix-spacing-2) !important;
}
.flix-space-2-right {
  margin-right: var(--flix-spacing-2) !important;
}
.flix-space-3-top {
  margin-top: var(--flix-spacing-3) !important;
}
.flix-space-3-bottom {
  margin-bottom: var(--flix-spacing-3) !important;
}
.flix-space-3-left {
  margin-left: var(--flix-spacing-3) !important;
}
.flix-space-3-right {
  margin-right: var(--flix-spacing-3) !important;
}
.flix-space-4-top {
  margin-top: var(--flix-spacing-4) !important;
}
.flix-space-4-bottom {
  margin-bottom: var(--flix-spacing-4) !important;
}
.flix-space-4-left {
  margin-left: var(--flix-spacing-4) !important;
}
.flix-space-4-right {
  margin-right: var(--flix-spacing-4) !important;
}
.flix-space-5-top {
  margin-top: var(--flix-spacing-5) !important;
}
.flix-space-5-bottom {
  margin-bottom: var(--flix-spacing-5) !important;
}
.flix-space-5-left {
  margin-left: var(--flix-spacing-5) !important;
}
.flix-space-5-right {
  margin-right: var(--flix-spacing-5) !important;
}
.flix-space-6-top {
  margin-top: var(--flix-spacing-6) !important;
}
.flix-space-6-bottom {
  margin-bottom: var(--flix-spacing-6) !important;
}
.flix-space-6-left {
  margin-left: var(--flix-spacing-6) !important;
}
.flix-space-6-right {
  margin-right: var(--flix-spacing-6) !important;
}
.flix-space-7-top {
  margin-top: var(--flix-spacing-7) !important;
}
.flix-space-7-bottom {
  margin-bottom: var(--flix-spacing-7) !important;
}
.flix-space-7-left {
  margin-left: var(--flix-spacing-7) !important;
}
.flix-space-7-right {
  margin-right: var(--flix-spacing-7) !important;
}
.flix-space-8-top {
  margin-top: var(--flix-spacing-8) !important;
}
.flix-space-8-bottom {
  margin-bottom: var(--flix-spacing-8) !important;
}
.flix-space-8-left {
  margin-left: var(--flix-spacing-8) !important;
}
.flix-space-8-right {
  margin-right: var(--flix-spacing-8) !important;
}
.flix-space-9-top {
  margin-top: var(--flix-spacing-9) !important;
}
.flix-space-9-bottom {
  margin-bottom: var(--flix-spacing-9) !important;
}
.flix-space-9-left {
  margin-left: var(--flix-spacing-9) !important;
}
.flix-space-9-right {
  margin-right: var(--flix-spacing-9) !important;
}
.flix-space-10-top {
  margin-top: var(--flix-spacing-10) !important;
}
.flix-space-10-bottom {
  margin-bottom: var(--flix-spacing-10) !important;
}
.flix-space-10-left {
  margin-left: var(--flix-spacing-10) !important;
}
.flix-space-10-right {
  margin-right: var(--flix-spacing-10) !important;
}
.flix-space-11-top {
  margin-top: var(--flix-spacing-11) !important;
}
.flix-space-11-bottom {
  margin-bottom: var(--flix-spacing-11) !important;
}
.flix-space-11-left {
  margin-left: var(--flix-spacing-11) !important;
}
.flix-space-11-right {
  margin-right: var(--flix-spacing-11) !important;
}
.flix-space-12-top {
  margin-top: var(--flix-spacing-12) !important;
}
.flix-space-12-bottom {
  margin-bottom: var(--flix-spacing-12) !important;
}
.flix-space-12-left {
  margin-left: var(--flix-spacing-12) !important;
}
.flix-space-12-right {
  margin-right: var(--flix-spacing-12) !important;
}
.flix-space-flush-top {
  margin-top: 0 !important;
}
.flix-space-flush-bottom {
  margin-bottom: 0 !important;
}
.flix-space-flush-left {
  margin-left: 0 !important;
}
.flix-space-flush-right {
  margin-right: 0 !important;
}

